import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
// import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// Material UI Components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  Button,
} from "@material-ui/core";

// Material Styles
import { lighten, makeStyles } from "@material-ui/core/styles";

// Material UI Icons
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";

// SweetAlert2
import Swal from 'sweetalert2';

function createData(user, deviceCount) {
  return {
    fname: user.fname,
    lname: user.lname,
    email: user.email,
    devicecount: deviceCount,
    role: user.role.name,
    subscription: user.subscription.name,
    lastactive: new Date(user.lastactive).toLocaleString(),
    dashboard: user.email,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "fname", numeric: false, disablePadding: true, label: "First Name" },
  { id: "lname", numeric: false, disablePadding: false, label: "Last Name" },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  { id: "devicecount", numeric: true, disablePadding: false, label: "Device Count", },
  { id: "role", numeric: false, disablePadding: false, label: "Role" },
  { id: "subscription", numeric: false, disablePadding: false, label: "Subscription", },
  { id: "lastactive", numeric: true, disablePadding: false, label: "Last Active", },
  { id: "dashboard", numeric: false, disablePadding: false, label: "", },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            classes={{
              checked: classes.checked,
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: "#f1c40f",
    backgroundColor: lighten("#f1c40f", 0.9),
  },
  title: {
    flex: "1 1 100%",
  },
  redButton: {
    backgroundColor: 'red',
    color: 'white',
  },
  yellowButton: {
    backgroundColor: 'yellow',
    color: 'black',
  },
  redText: {
    color: 'red',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  const handleConfirmDelete = () => {
    Swal.fire({
      // title: "Are you sure?",
      // text: `You want to delete ${numSelected} selected user(s)?`,
      html: `
        <div>
          <div><h2>Are you sure?</h2></div>
          <div>You want to delete  <span style="color: red;">${numSelected}</span> selected user(s)?</div>
        </div>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "User(s) have been deleted.",
          icon: "success",
          confirmButtonColor: "#f1c40f"
        });
      }
    });
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Manage User
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={handleConfirmDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column", // จัดเรียง Element ในรูปแบบคอลัมน์
    alignItems: "center", // จัด Element ให้อยู่ตรงกลางแนวนอน
    justifyContent: "center", // จัด Element ให้อยู่ตรงกลางแนวตั้ง
    marginTop: theme.spacing(4), // ระยะห่างด้านบน
  },
  paper: {
    width: "95%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  checked: {
    color: "#f1c40f !important",
  },
  testclass: {
    backgroundColor: `$lighten("#f1c40f", 0.9) !important`,
    // backgroundColor: "#f1c40f !important",
  },
}));

export default function ManageUser() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("fname");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const history = useNavigate();
  const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;
  const { currentUser } = useAuth();

  React.useEffect(() => {
    axios.post(ModelaRestURL + '/api/user/get_alluser', { email: currentUser.email })
      .then((userResponse) => {
        // console.log('userResponse => ', userResponse);
        const userData = userResponse.data;

        // สร้างอาร์เรย์ของ promises สำหรับการเรียก Axios สำหรับข้อมูลอุปกรณ์ของแต่ละผู้ใช้
        const devicePromises = userData.map((user) => {
          return axios.post(ModelaRestURL + '/api/socket/getDevice', { user: user.email })
            .then((deviceResponse) => {
              const deviceData = deviceResponse.data;
              const deviceCount = deviceData.length;
              // console.log('deviceData => ', deviceData);
              // console.log('deviceCount => ', deviceCount);
              // console.log('userId => ', user._id);

              return { userId: user._id, deviceCount };
            })
            .catch((error) => {
              console.error('Error fetching device data:', error);
              return { userId: user._id, deviceCount: 0 }; // หากเกิดข้อผิดพลาด คืนค่าเริ่มต้น
            });
        });

        // รอให้ทุก promises ใน devicePromises เสร็จสิ้นและคืนผลลัพธ์
        Promise.all(devicePromises)
          .then((deviceCounts) => {
            // console.log('deviceCounts => ', deviceCounts);

            const formattedData = userData.map((user) => {
              const deviceCount = deviceCounts.find((deviceCount) => deviceCount.userId === user._id)?.deviceCount || 0;
              return createData(user, deviceCount);
            });

            setRows(formattedData);
          })
          .catch((error) => {
            console.error('Error fetching device counts:', error);
          });
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  }, [currentUser]);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.fname);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleUserDashboardMenu = (email) => {
    history(`/admin/dashboard/${email}`);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.fname);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      //   hover
                      onClick={(event) => handleClick(event, row.fname)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.fname}
                      selected={isItemSelected}
                      style={{
                        backgroundColor: isItemSelected ? lighten("#f1c40f", 0.9) : "inherit",
                      }}
                      className={clsx({
                        [classes.testclass]: isItemSelected,
                      })}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          classes={{
                            checked: classes.checked,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.fname}
                      </TableCell>
                      <TableCell align="left">{row.lname}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="right">{row.devicecount}</TableCell>
                      <TableCell align="left">{row.role}</TableCell>
                      <TableCell align="left">{row.subscription}</TableCell>
                      <TableCell align="right">{row.lastactive}</TableCell>
                      <TableCell align="center">
                        {/* <NavLink to={`../admin/Dashboard/${row.dashboard}`}> */}
                          <Button
                            variant="contained"
                            color="primary"
                            // to={`/Dashboard/${row.dashboard}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUserDashboardMenu(row.dashboard);
                            }}
                            style={{ backgroundColor: "#f1c40f" }}
                          >
                            Go To Dashboard
                          </Button>
                        {/* </NavLink> */}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}
