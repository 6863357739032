import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../util/init-firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  confirmPasswordReset,
  sendPasswordResetEmail,
  sendEmailVerification,
  signInWithPopup,
  GoogleAuthProvider,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import axios from "axios";

export const AuthContext = createContext(); // สร้าง context สำหรับการจัดการ authentication
export const useAuth = () => useContext(AuthContext); // สร้าง Hook สำหรับใช้ AuthContext

const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL; // URL ของ Modela API

export const AuthProvider = ({ children }) => {
  const [currentUser, setUser] = useState(localStorage.getItem("currentUser") || null); // สถานะของผู้ใช้ปัจจุบัน
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem("isAdmin") || false); // สถานะของผู้ใช้ที่เป็น Admin
  const [emailVerified, setEmailVerified] = useState(false); // สถานะของการยืนยันอีเมล

  // Effect ที่ทำงานเมื่อมีการเปลี่ยนแปลงในสถานะของผู้ใช้
  useEffect(() => {
    // ตัวแปร unsubscribe ใช้สำหรับยกเลิกการติดตาม state ที่เปลี่ยนแปลง
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      // กำหนด state ใหม่
      setUser(user ? user : null);
      // console.log("User is signed in:", user ? user : "No user");

      // เรียกใช้ฟังก์ชันเช็ค isAdmin หลังจากได้ข้อมูลผู้ใช้
      if (user) {
        const isAdminUser = await checkAdmin(user.email);
        setIsAdmin(isAdminUser);
        localStorage.setItem("currentUser", user);
        localStorage.setItem("isAdmin", isAdmin);

        // เช็คสถานะการยืนยันอีเมลและกำหนดค่า emailVerified
        if (user.emailVerified) {
          console.log("User's email is verified.");
          setEmailVerified(true);
        } else {
          console.log("User's email is not verified.");
          setEmailVerified(false);
        }
      } else {
        setIsAdmin(false); // สำหรับกรณีไม่มีผู้ใช้เข้าสู่ระบบ
        setEmailVerified(false); // สำหรับกรณีไม่มีผู้ใช้เข้าสู่ระบบหรือผู้ใช้ลงออก
        localStorage.clear("currentUser");
        localStorage.clear("isAdmin");
      }
    });

    // ทำลาย unsubscribe เมื่อ component ถูกถอดจาก DOM
    return () => unsubscribe();
  }, [currentUser]);

  // login
  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const emailVerified = user.emailVerified;
        return { user, emailVerified };
      });
  }

  // login with google
  function loginWithGoogle() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider)
      .then((userCredential) => {
        const user = userCredential.user;
        const emailVerified = user.emailVerified;
        return { user, emailVerified };
      });
  }
  
  // register
  async function register(email, password) {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log("User registered successfully.");
      
      try {
        await sendEmailVerification(user);
        console.log("Email verification sent.");
      } catch (verificationError) {
        console.error("Error sending email verification:", verificationError);
        return { success: false, reason: "Email verification not sent" };
      }
      
      return { success: true, user };
    } catch (error) {
      console.error("Error registering user:", error.code, error.message);
      if (error.code === "auth/email-already-in-use") {
        const emailExists = await checkEmailExists(email);
        if (emailExists.exists) {
          if (emailExists.providers.includes("password")) {
            return { success: false, reason: "Email already in use" };
          }
          else if(emailExists.providers.includes("google.com")) {
            return { success: false, reason: "Email already in use by Google" };
          }
        } else {
          return { success: false, reason: "Registration failed" };
        }
      } else {
        return { success: false, reason: "Registration failed" };
      }
    }
  }

  // ฟังก์ชันเช็ค Email ซ้ำ
  async function checkEmailExists(email) {
    try {
      const methods = await fetchSignInMethodsForEmail(auth, email);
      return { exists: methods.length > 0, providers: methods };
    } catch (error) {
      console.error("Error checking email existence:", error);
      return { exists: false, reason: "Error checking email existence" };
    }
  }

  // // reset password
  // function resetPassword(email) {
  //   return sendPasswordResetEmail(auth, email);
  // }

  // reset password
  async function resetPassword(email) {
    // ตรวจสอบว่า Email นี้มีอยู่ในระบบหรือไม่
    const emailExists = await checkEmailExists(email);

    if (emailExists.exists) {
      // ถ้ามี Email ในระบบ ให้ทำการ Reset Password และคืน Promise ที่สำเร็จ
      return sendPasswordResetEmail(auth, email);
    } else {
      // ถ้าไม่มี Email ในระบบ คืน Promise ที่ล้มเหลวพร้อมรายละเอียด
      return Promise.reject({ code: "auth/email-not-found", message: "Email not found in the system" });
    }
  }

  // confirm password reset
  function confirmPassword(code, password) {
    return confirmPasswordReset(auth, code, password);
  }

  // logout
  function logout() {
    return signOut(auth);
  }

  // สร้างฟังก์ชันสำหรับเช็ค isAdmin
  async function checkAdmin(email) {
    try {
      const response = await axios.post(ModelaRestURL + "/api/user/isAdminUser", { email });
      if(response.status === 200) { return true; }
      else { return false; }
    } catch (error) {
      console.error("Error checking admin status:", error);
      return false;
    }
  }

  // ส่งค่าใน Context Provider
  return (
    <AuthContext.Provider value={{ currentUser, isAdmin, emailVerified, login, loginWithGoogle, register, resetPassword, confirmPassword, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
