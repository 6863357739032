module.exports = [
    {
      name: "None",
      value: 0,
    },
    {
      name: "No Control",
      value: 1,
    },
    {
      name: "1",
      value: 2,
    },
    {
      name: "2",
      value: 3,
    },
    {
      name: "1,2",
      value: 4,
    },
    {
      name: "Mobile notify only",
      value: 5,
    },
    {
      name: "1 Within Period Timer Switch 1",
      value: 6,
    },
    {
      name: "2 Within Period Timer Switch 2",
      value: 7,
    },
    {
      name: "1,2 Within Period Timer Switch 1,2",
      value: 8,
    },
  ]