import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  test: {
    background: "#4b4b4b",
    position: "absolute",
    bottom: "0",
    width: "100%",
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  logo: {
    height: "12vmin",
    margin: theme.spacing(1),
  },
  div1: {
    textAlign: "left",
  },
  footer: {
    // borderTop: `1px solid ${theme.palette.divider}`,
    color: "#b2b2b2",
    // position: "absolute",
    // width: "100%",
    // bottom: "0",
    background: "#4b4b4b",
    marginTop: theme.spacing(0),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
    },
  },
  typography: {
    textAlign: "left",
  },
  div: {
    margin: theme.spacing(8),
    color: "#DFDFDF",
  },
  grid: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(10),
  },
}));
function ContactUs() {
  const classes = useStyles();

  const handleClick = (prop) => async (e) => {
    window.open(prop, '_blank' )
    };

  return (
    <div className={classes.div}>
      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        className={classes.grid}
      >
        <Grid
          item
          xs={12}
          md={4}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.grid}
        >
          <Grid item xs={2} md={12}>
            <img src="/images/Icon-51.png" className={classes.logo}></img>
          </Grid>
          <Grid item xs={4} md={12}>
            <Typography className={classes.typography}>
              Modela IoT CO.,LTD. Maket Today MG10
            </Typography>
            <Typography className={classes.typography}>
              Soi. Krungthepkritha 7
            </Typography>
            <Typography className={classes.typography}>
              Huamak Bangkapi Bangkok 10240 Thailand
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.grid}
          onClick={handleClick(
            "https://www.facebook.com/Modela.InternetofTHINK/"
          )}
        >
          <Grid
            // onClick={handleClick(
            //   "https://www.facebook.com/Modela.InternetofTHINK/"
            // )}
            style={{ cursor: "pointer" }}
            item
            xs={2}
            md={12}
          >
            <img src="/images/Icon-50.png" className={classes.logo}></img>
          </Grid>
          <Grid
            // onClick={handleClick(
            //   "https://www.facebook.com/Modela.InternetofTHINK/"
            // )}
            style={{ cursor: "pointer" }}
            item
            xs={4}
            md={12}
          >
            <Typography className={classes.typography}>
              https://www.facebook.com/Modela.InternetofTHINK/
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.grid}
          onClick={handleClick(
            "https://page.line.me/modelaiot?openQrModal=true"
          )}
        >
          <Grid
            // onClick={handleClick(
            //   "https://page.line.me/modelaiot?openQrModal=true"
            // )}
            style={{ cursor: "pointer" }}
            item
            xs={2}
            md={12}
          >
            <img src="/images/Icon-52.png" className={classes.logo}></img>
          </Grid>
          <Grid
            // onClick={handleClick(
            //   "https://page.line.me/modelaiot?openQrModal=true"
            // )}
            style={{ cursor: "pointer" }}
            item
            xs={4}
            md={12}
          >
            <Typography className={classes.typography}>
              Line : @modelaiot
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.grid}
        >
          <Grid item xs={2} md={12}>
            <img src="/images/Icon-53.png" className={classes.logo}></img>
          </Grid>
          <Grid item xs={4} md={12}>
            <Typography className={classes.typography}>
              Sales : 06-1920-3920
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.grid}
        >
          <Grid item xs={2} md={12}>
            <img src="/images/Icon-53.png" className={classes.logo}></img>
          </Grid>
          <Grid item xs={4} md={12}>
            <Typography className={classes.typography}>
              Business : 06-2021-2255
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ContactUs;
