import React, { useState } from "react";
import {
  Container,
  Grid,
  Paper,
  TextField,
  Button,
  CssBaseline,
  Avatar,
  Typography,
  Link,
  Checkbox,
  Input,
  FormControlLabel,
  OutlinedInput,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import GenerateTokenKey from "../component/Modal/GenerateTokenKey";

const useStyle = makeStyles((theme) => ({
  root: {
    // backgroundImage: "url(" + denpicture + ")",
    // opacity: 0.75,
    // background: url()
    background: "linear-gradient(45deg, #323232 30%, #323332 90%)",
    // height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  submit: {
    background: "linear-gradient(45deg, #F29C19 30%, #fbb116 90%)",
    borderRadius: "5px",
    boxShadow: "0 3px 5px 2px rgba(251, 177, 22, .3)",
    padding: "15px",
    color: "white",
    fontSize: "18px",
    // backgroundColor: "#3f51b5",
    margin: theme.spacing(5, 0, 2),
  },
  paperStyle: {
    // padding: 20,
    maxWidth: "550px",
    margin: "25px auto",
    // width: "40%",
    // height: "70vh",
    display: "flex",
    // alignItems: "center",
    flexDirection: "column",
    // padding: "35px 10px",
    borderRadius: "10px",
    marginTop: theme.spacing(15),
    [theme.breakpoints.down("xs")]: {
      marginTop: "auto",
      width: "90%",
      height: "90%",
    },
    backgroundColor: "#59585e",
    // backdropFilter: "blur(8px)",
  },
  div: {
    // marginTop: theme.spacing(5),
    // padding: "35px 10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    margin: "auto",
    height: "6vmin",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    border: "none",
    backgroundColor: "#45444a",
    borderRadius: "4px",
    "&:focus": {
      borderColor: "#fbb116",
    },
    color: "#fff"
    // paddingLeft: 8,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  showpass: {
    position: "absolute",
    right: "0",
    top: "1px",
    padding: "7px 10px",
    marginRight: "5px",
    marginTop: "12px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#2a4365",
    backgroundColor: "#EDF2F7",
    borderRadius: "5px",
    cursor: "pointer",
  },
  two: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  h1: {
    fontSize: "34px",
    color: "#fff",
  },
  p: {
    color: "#718096",
    fontSize: "15px",
    marginTop: 0,
  },
  inputprops: {
    color: "#fff",
    "&:focus": {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: "#fbb116",
    },
  },
}));


const GeneraeteToken = () => {
  const [deviceName, setDeviceName] = useState("");
  const {currentUser} = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCreateDeviceOpen, setIsModalCreateDeviceOpen] = useState(false);
 
  const randomKey = Math.random().toString(36).substring(2, 12);
  const [key, setKey] = useState(randomKey)
  const openModal= () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  

  const classes = useStyle();

  const setVal = (e) => {
    setDeviceName(e.target.value);
  };

  const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const result = await axios.post(
        ModelaRestURL + "/api/socket/generateKey",
        {
          key: key,
          user: currentUser.email,
          deviceName: deviceName
        }
      );
      if (result.status === 200) {
        openModal()
        console.log("Send API Done!");
      }
    } catch (error) {
      console.log(error);
    }
    
  };

  return (
    <Grid container component="main">
      {/* <div> */}
      <Container
        component={Paper}
        elevation={6}
        // maxWidth="20%"
        className={classes.paperStyle}
      >
        <div>
          <div className={classes.div}>
            <h1 className={classes.h1}>Create Device</h1>
          </div>

          <form className={classes.form} noValidate>
            <div>
              <OutlinedInput
                InputProps={{
                  className: classes.inputprops,
                }}
                fullWidth
                value={deviceName}
                onChange={setVal}
                type="name"
                className={classes.input}
                placeholder="Enter Device Name"
                autoComplete="name"
                id="deviceName"
              />
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={classes.submit}
            >
              Create
            </Button>
          </form>

        </div>
      </Container>
      {isModalOpen && (
        <GenerateTokenKey
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          title="Value Sensor 1"
          tokenkey={key}
          
        />
      )}
      {/* </div> */}
    </Grid>
  );
};

export default GeneraeteToken;
