import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  IconButton,
  CssBaseline,
  Button,
  Divider,
} from "@material-ui/core";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import LinkIcon from "@material-ui/icons/Link";
import { makeStyles } from "@material-ui/core/styles";
import ConnectLine from "../component/Modal/ConnectLine";
import { useQRCode } from "react-qrcode";
import { useAuth } from "../context/AuthContext";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      // display: "flex",
      backgroundColor: "#353535",
      minHeight: "100vh",
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      width: "100%",
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    textfieldStatus: {
      backgroundColor: "#45444a",
      borderRadius: "30px",
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(3),
      width: "80%",
    },
    multilineColor: {
      color: "#fff",
      borderRadius: "30px",
      height: "35px",
      borderColor: "#45444a",
    },
    topicpaper: {
      // marginLeft: theme.spacing(1)
      textAlign: "center",
    },
    imglight: {
      margin: theme.spacing(1),
    },
    grid: {
      // marginLeft: theme.spacing(1)
      textAlign: "center",
    },
    grid1: {
      alignItems: "right",
      // marginLeft: theme.spacing(1)
    },
    div: {
      color: "#fff",
      borderColor: "#fff",
      margin: theme.spacing(10),
    },
    divider: {
      background: "#898989",
    },
    button: {
      color: "#fff",
      borderColor: "#B7B7B7",
    },
  };
});

function Setting() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const { currentUser } = useAuth();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = useState("https://www.1stg.me");
  const dataUrl = useQRCode(value);

  const url = "https://liff.line.me/1657874245-DqJPGneX/" + currentUser.uid; ///logo is not show but can use param
  // const url = "/connect-line/ssdfsdfsdfsd";     //// logo is show but can't use param

  const qrcode = useQRCode(url);

  return (
    <div className={classes.div}>
      <h1>Setting</h1>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item lg={3}>
          <h3>User Id</h3>
        </Grid>
        <Grid item lg={9}>
          <p>{currentUser.uid}</p>
        </Grid>
        <Grid item lg={12}>
          <Divider className={classes.divider} variant="middle" />
        </Grid>

        <Grid item lg={3}>
          <h3>Email</h3>
        </Grid>
        <Grid item lg={9}>
          <p>{currentUser.email}</p>
        </Grid>
        <Grid item lg={12}>
          <Divider className={classes.divider} variant="middle" />
        </Grid>
        <Grid item lg={3}>
          <h3>Line Notification</h3>
        </Grid>
        <Grid item lg={9}>
          <p> - </p>
          <Button
            onClick={() => {
              handleClickOpen();
            }}
            variant="outlined"
            className={classes.button}
          >
            connect
          </Button>
        </Grid>
        {/* <Grid spacing={1} container></Grid> */}
        <Grid item lg={12}>
          <Divider className={classes.divider} variant="middle" />
        </Grid>
      </Grid>

      {open && (
        <ConnectLine
          open={open}
          handleClose={handleClose}
          qrcode={qrcode}
          url={url}
        />
      )}
    </div>
    // <Grid spacing={1} container>
    //   <Grid item xs={12} md={12} lg={12}>
    //     <main className={classes.layout}>
    //       <Paper className={classes.paper}>
    //         <h1>Setting</h1>
    //         {status === true && (
    //           <Button
    //             className={classes.button}
    //             onClick={() => {
    //               // generateQRCode();
    //               handleClickOpen();
    //             }}
    //             size="large"
    //             variant="contained"
    //             color="secondary"
    //           >
    //             Connected Line notification _<LinkIcon />
    //           </Button>
    //         )}
    //         {status === false && (
    //           <Button
    //             className={classes.button}
    //             onClick={() => {
    //               // generateQRCode();
    //               handleClickOpen();
    //             }}
    //             size="large"
    //             variant="contained"
    //             // color="secondary"
    //             style={{ color: "white", background: "#FC5430" }}
    //           >
    //             Connect LINE Notification _<LinkOffIcon />
    //           </Button>
    //         )}
    //       </Paper>
    //       {open && (
    //         <ConnectLine
    //           open={open}
    //           handleClose={handleClose}
    //           qrcode={qrcode}
    //           url={url}
    //         />
    //       )}
    //     </main>
    //   </Grid>
    // </Grid>
  );
}

export default Setting;
