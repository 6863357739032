import React from "react";
import GaugeChart from "react-gauge-chart";
import { Typography, makeStyles } from "@material-ui/core";
import useGlobalStyle from "./style/globalStyle";

const useStyles = makeStyles(() => ({
  gauge: {
    minWidth: "100%",
  },
  minMaxContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
  },
  minText: {
    paddingLeft: "1.8rem",
    fontWeight: "bold",
    marginTop: "-1rem",
    marginBottom: "1rem",
  },
  maxText: {
    paddingRight: "1.5rem",
    fontWeight: "bold",
    marginTop: "-1rem",
    marginBottom: "1rem",
  },
}));

function EfficiencyGraph({
  percentage,
  minPercentage,
  maxPercentage,
  lowLim,
  highLim,
  levels,
  label,
  SecondValueData,
  SecondValueName,
}) {
  const classes = useStyles();
  const globalStyle = useGlobalStyle();
  const colors = ["#F59C1B", "#02FFE5", "#FF5A59"];

  const minP = (minPercentage) ? minPercentage : 0;
  const maxP = (maxPercentage) ? maxPercentage : 100;
  const lowL = (lowLim) ? lowLim : 0;
  const highL = (highLim) ? highLim : 100;
  
  const totalRange = maxP - minP;
  const normalizedLowLim = (lowL - minP) / totalRange;
  const normalizedHighLim = (highL - minP) / totalRange;
  const normalizedPercentage = (percentage - minP) / totalRange;

  // Calculate arc lengths
  const arcsLength = [
    normalizedLowLim, // Length of first arc
    normalizedHighLim - normalizedLowLim, // Length of second arc
    1 - normalizedHighLim, // Length of third arc
  ];

  return (
    <React.Fragment>
      <div className={`${globalStyle.padding1} ${globalStyle.marginT1}`}>
        <Typography className={globalStyle.topicPaper}>
          {label}
        </Typography>
      </div>
      <div>
        <GaugeChart
          id={label}
          nrOfLevels={levels}
          arcsLength={arcsLength}
          percent={normalizedPercentage}
          arcPadding={0.0}
          textColor="#fff"
          needleColor="#ffd166"
          needleBaseColor="#ffd166"
          colors={colors}
          arcWidth={0.1}
          cornerRadius={3}
          marginInPercent={0.02}
          formatTextValue={() => `${percentage}`}
        />
      </div>
      <div className={classes.minMaxContainer}>
        <Typography
          variant="h6"
          component="div"
          align="left"
          className={classes.minText}
        >
          {minP}
        </Typography>
        <Typography
          variant="h6"
          component="div"
          align="right"
          className={classes.maxText}
        >
          {maxP}
        </Typography>
      </div>
      <div className={globalStyle.flexCenter}>
        <div>
          <Typography align="center">
            {SecondValueName}
          </Typography>
          <Typography align="center">
            {SecondValueData}
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
}

export default React.memo(EfficiencyGraph);
