import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Container,
  OutlinedInput,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import GenerateTokenKey from "./GenerateTokenKey";
import useGlobalStyle from "../style/globalStyle";

const useStyle = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(3),
    textIndent: "20px",
  },
  modal: {
    backgroundColor: "#59585e",
    color: "#fff",
    minWidth: "30%",
    maxWidth: "35%",
  },
  choose: {
    color: "#fff",
  },
  submit: {
    background: "linear-gradient(45deg, #F29C19 30%, #fbb116 90%)",
    borderRadius: "5px",
    boxShadow: "0 3px 5px 2px rgba(251, 177, 22, .3)",
    padding: "8px",
    color: "white",
    fontSize: "14px",
    margin: theme.spacing(5, 0, 2),
  },
  submitcancel: {
    background: "linear-gradient(45deg, #B4B4B4 30%, #9C9595 90%)",
    borderRadius: "5px",
    boxShadow: "0 3px 5px 2px rgba(251, 177, 22, .3)",
    padding: "8px",
    color: "white",
    fontSize: "14px",
    margin: theme.spacing(5, 0, 2),
  },

  logo: {
    margin: "auto",
    height: "6vmin",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    border: "1px solid #000",
    textAlign: "center",
    fontSize: "22px",
    backgroundColor: "#45444a",
    padding: "8px",
    borderRadius: "4px",
    "&:focus": {
      borderColor: "#fbb116",
    },
    color: "#fff",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  h1: {
    fontSize: "34px",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  error: {
    color: "red",
  },
  inputprops: {
    color: "#fff",
    textAlign: "center",
    alignItems: "center",
    "&:focus": {
      borderColor: "#fbb116",
    },
  },
  paperStyle: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
  },
}));

const CreateDevice = ({ closeModal, isModalOpen, dataSet, user }) => {
  const classes = useStyle();
  const globalStyle = useGlobalStyle();
  const [error, setError] = useState("");
  const [isModalGenOpen, setIsModalGenOpen] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [key, setKey] = useState("");

  const openModalGen = () => {
    setIsModalGenOpen(true);
  };
  const closeModalGen = () => {
    setIsModalGenOpen(false);
  };

  const setVal = (e) => {
    setDeviceName(e.target.value);
  };

  const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        ModelaRestURL + "/api/socket/generateKey",
        {
          user: user,
          deviceName: deviceName,
          path: "/dashboard/",
        }
      );
      if (response.status === 200) {
        // รับค่า key ที่สร้างและทำตามขั้นตอนต่อไปตามความต้องการ
        const { key, path, text, user } = response.data;
        setKey(key);
        
        dataSet.push(
          {
            key: key,
            user: user,
            text: text,
            path: path,
          });
        openModalGen();
      }
      else {
        setError("An error occurred while creating the device.");
      }
    } catch (error) {
      console.log(error);
      setError("An error occurred while creating the device.");
    }
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="create-device-dialog-title"
      className={globalStyle.modal1}
      PaperProps={{
        className: globalStyle.modalXS,
      }}
    >
      <DialogContent>
        <Container className={classes.paperStyle}>
          <div>
            <div className={classes.div}>
              <h1 className={classes.h1}>Create Device</h1>
            </div>

            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <div>
                <OutlinedInput
                  InputProps={{
                    className: classes.inputprops,
                  }}
                  fullWidth
                  value={deviceName}
                  onChange={setVal}
                  type="name"
                  className={classes.input}
                  placeholder="Enter Device Name"
                  autoComplete="name"
                  id="deviceName"
                />
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Create
              </Button>
            </form>
          </div>
        </Container>

        {error && <p className={classes.error}>{error}</p>}
      </DialogContent>
      {isModalOpen && (
        <GenerateTokenKey
          isModalOpen={isModalGenOpen}
          closeModal={closeModalGen}
          closeModalCreate={closeModal}
          title="Value Sensor 1"
          tokenkey={key}
        />
      )}
    </Dialog>
  );
};

export default CreateDevice;
