import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  IconButton,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { SwitchIosSmallGreen } from "./SwitchIos";
import ValueSensorModal from "./Modal/ValueSensorModal";
import EfficiencyGraph from "./Guage";
import ValueOption from "../api/ui/ValueOption";
import ValueControl from "../api/ui/ValueControl";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from "@material-ui/core/styles";
import useGlobalStyle from "./style/globalStyle";

const useStyles = makeStyles((theme) => ({
  margin1: {
    margin: theme.spacing(1),
  },
  marginTB1: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textfieldInput: {
    color: '#FFF',
    borderRadius: "2rem",
    height: "45px",
    borderColor: '#1A1E27',
  },
}));

function ValueSensor({
  ValueSensor,
  ValueSensorlcd,
  ValueSensorUnit,
  SecondValueData,
  SecondValueName,
  sensorValue,
  sensorValueOption,
  sensorValueControl,
  sensorValueHighLimit,
  sensorValueLowLimit,
  sensorValueCalibrate,
  notifyValueSensor1,
  setDataSetting,
  dataSetting,
  blynkToken,
  menuValueSensor,
  titleName,
}) {
  const globalStyle = useGlobalStyle();
  const classes = useStyles();

  const [isModalValueSensor1Open, setIsModalValueSensor1Open] = useState(false);

  const openModalValueSensor1 = () => {
    setIsModalValueSensor1Open(true);
  };
  const closeModalValueSensor1 = () => {
    setIsModalValueSensor1Open(false);
  };
  
  const labelSensor = ValueSensorUnit ? `${ValueSensorlcd} (${ValueSensorUnit})` : ValueSensorlcd;
  const sensorControlText = (!sensorValueOption || sensorValueOption.length === 0) ? 'Control' : sensorValueOption.name;
  const sensorSwitchText = (!sensorValueControl || sensorValueControl.length === 0) ? 'Switch' : sensorValueControl.name;

  const sensorControl = [
    { label: "Control", value: sensorControlText },
    { label: "Switch", value: sensorSwitchText },
  ];

  const sensorSetting = [
    { label: "High Limit", value: sensorValueHighLimit, color: "#ec665f" },
    { label: "Low Limit", value: sensorValueLowLimit, color: "#e9a13f" },
    { label: "Calibrate", value: sensorValueCalibrate, color: "#7ad576" },
  ];

  const sensorValueData = sensorValue ? sensorValue : { name: "Sensor", value: 999, sensorID: "9999", min: 0, max: 100 };

  return (
    <>
    <div className={globalStyle.padding1}>
      {/* <Paper className={globalStyle.paperMain}> */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item lg={2}></Grid>
          <Grid item lg={8}>
            <Typography className={globalStyle.topicPaper}>
              {titleName}
            </Typography>
          </Grid>
          <Grid item lg={2}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => {
                openModalValueSensor1();
              }}
              color="inherit"
            >
              <SettingsIcon />
            </IconButton>
          </Grid>

          <Grid item lg={12} className={globalStyle.flexCenter}>
            <TextField
              id="standard-read-only-input"
              defaultValue="Sensor"
              variant="outlined"
              className={`${globalStyle.textfield} ${classes.margin1}`}
              InputProps={{
                readOnly: true,
                className: classes.textfieldInput,
              }}
              value={sensorValueData.name}
            />
          </Grid>

          <Grid item lg={3} className={`${globalStyle.flexStart} ${globalStyle.paddingL2}`}>
            <Typography>Notify</Typography>
          </Grid>
          <Grid item lg={4} className={globalStyle.flexStart}>
            <SwitchIosSmallGreen
              checked={notifyValueSensor1}
              name="notifyValueSensor1"
              handleChange={() => {openModalValueSensor1()}}
            />
          </Grid>
          <Grid item lg={5}></Grid>

          {sensorControl.map((item, index) => (
            <React.Fragment key={index}>
              <Grid item lg={3} className={`${globalStyle.flexStart} ${globalStyle.paddingL2}`}>
                <Typography>{item.label}</Typography>
              </Grid>
              <Grid item lg={9} className={globalStyle.flexStart}>
                <Typography className={globalStyle.borderSensor}>{item.value}</Typography>
              </Grid>
            </React.Fragment>
          ))}

          <Grid item lg={12} className={classes.margin1}></Grid>
          
          {sensorSetting.map((item, index) => (
            <React.Fragment key={index}>
              <Grid item lg={2} className={`${globalStyle.flexEnd} ${classes.marginTB1}`}>
                <FiberManualRecordIcon style={{ color: item.color, fontSize: '15px' }} />
              </Grid>
              <Grid item lg={1}></Grid>
              <Grid item lg={3} className={`${globalStyle.flexStart} ${classes.marginTB1}`}>
                <Typography>{item.label}</Typography>
              </Grid>
              <Grid item lg={6} className={`${globalStyle.flexCenter} ${classes.marginTB1}`}>
                {item.value}
              </Grid>
            </React.Fragment>
          ))}

          <Grid item lg={12}>
            <EfficiencyGraph
              label={labelSensor}
              percentage={ValueSensor.toFixed(2)}
              levels={3}
              highLim={sensorValueHighLimit}
              lowLim={sensorValueLowLimit}
              minPercentage={sensorValueData.min}
              maxPercentage={sensorValueData.max}
              SecondValueData={SecondValueData}
              SecondValueName={SecondValueName}
            />
          </Grid>
        </Grid>
      {/* </Paper> */}
    </div>
      {isModalValueSensor1Open && (
        <ValueSensorModal
          isModalOpen={isModalValueSensor1Open}
          closeModal={closeModalValueSensor1}
          title={titleName}
          valueSensor={menuValueSensor}
          valueOption={ValueOption}
          valueControl={ValueControl}
          sensorValue={sensorValueData}
          sensorValueControl={sensorValueControl}
          sensorValueOption={sensorValueOption}
          sensorValueHighLimit={sensorValueHighLimit}
          sensorValueLowLimit={sensorValueLowLimit}
          sensorValueCalibrate={sensorValueCalibrate}
          notify={notifyValueSensor1}
          setDataSetting={setDataSetting}
          dataSetting={dataSetting}
          blynkToken={blynkToken}
        />
      )}
    </>
  );
}

export default ValueSensor;
