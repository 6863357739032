import React from 'react';
import { Typography, Paper, Button, makeStyles } from '@material-ui/core';
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#353535',
  },
  paper: {
    padding: theme.spacing(6),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
  },
  title: {
    color: '#fbb116',
    marginBottom: theme.spacing(3),
  },
  message: {
    color: '#59585e',
  },
  button: {
    marginTop: theme.spacing(3),
    color: '#fff',
    backgroundColor: '#fbb116',
    '&:hover': {
      backgroundColor: '#e0a106',
    },
  },
  bounce: {
    animation: '$bounce 1s ease infinite',
  },
  '@keyframes bounce': {
    '0%, 100%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(-10px)',
    },
  },
}));

function ErrorPage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={3}>
        <Typography variant="h3" className={`${classes.title} ${classes.bounce}`}>
          Oops!
        </Typography>
        <Typography variant="h5" className={classes.message}>
          The page you're looking for can't be found.
        </Typography>
        <NavLink to="/home">
          <Button className={classes.button} variant="contained">
            Go Back Home
          </Button>
        </NavLink>
      </Paper>
    </div>
  );
}

export default ErrorPage;
