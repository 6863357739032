module.exports = {
    switchDeley1: 0,
  switchDeley2: 0,
  delaySwitchOption1: 0,
  delaySwitchOption2: 0,
  statusValue1: { name: "sensor" },
  statusValue1Option: [],
  statusValue1Control: [],
  statusValue2: { name: "sensor" },
  statusValue2Option: [],
  statusValue2Control: [],
  notifyStatusSensor1: 0,
  notifyStatusSensor2: 0,
  sensorValue1: { name: "sensor" },
  sensorValue2: { name: "sensor" },
  sensorValue3: { name: "sensor" },
  sensorValue4: { name: "sensor" },
  sensorValue1Option: [],
  sensorValue2Option: [],
  sensorValue3Option: [],
  sensorValue4Option: [],
  sensorValue1Control: [],
  sensorValue2Control: [],
  sensorValue3Control: [],
  sensorValue4Control: [],
  sensorValue1HighLimit: 99,
  sensorValue2HighLimit: 99,
  sensorValue3HighLimit: 99,
  sensorValue4HighLimit: 99,
  sensorValue1LowLimit: 0,
  sensorValue2LowLimit: 0,
  sensorValue3LowLimit: 0,
  sensorValue4LowLimit: 0,
  sensorValue1Calibrate: 0,
  sensorValue2Calibrate: 0,
  sensorValue3Calibrate: 0,
  sensorValue4Calibrate: 0,
  notifyValueSensor1: 0,
  notifyValueSensor2: 0,
  notifyValueSensor3: 0,
  notifyValueSensor4: 0,

  switchTimerOn1Ax: 0,
  switchTimerOn2Ax: 0,
  switchTimerOn3Ax: 0,
  switchTimerOff1Ax: 0,
  switchTimerOff2Ax: 0,
  switchTimerOff3Ax: 0,
  daySelectedOn1Ax: [],
  daySelectedOn2Ax: [],
  daySelectedOn3Ax: [],
  daySelectedOff1Ax: [],
  daySelectedOff2Ax: [],
  daySelectedOff3Ax: [],
  timerOn1Ax: 3900,
  timerOff1Ax: 3900,
  timerOn2Ax: 3900,
  timerOff2Ax: 3900,
  timerOn3Ax: 3900,
  timerOff3Ax: 3900,
  timeZoneOn1Ax: 1,
  timeZoneOff1Ax: 1,
  timeZoneOn2Ax: 1,
  timeZoneOff2Ax: 1,
  timeZoneOn3Ax: 1,
  timeZoneOff3Ax: 1,

  switchTimerOn1Bx: 0,
  switchTimerOn2Bx: 0,
  switchTimerOn3Bx: 0,
  switchTimerOff1Bx: 0,
  switchTimerOff2Bx: 0,
  switchTimerOff3Bx: 0,
  daySelectedOn1Bx: [],
  daySelectedOn2Bx: [],
  daySelectedOn3Bx: [],
  daySelectedOff1Bx: [],
  daySelectedOff2Bx: [],
  daySelectedOff3Bx: [],
  timerOn1Bx: 0,
  timerOff1Bx: 0,
  timerOn2Bx: 0,
  timerOff2Bx: 0,
  timerOn3Bx: 0,
  timerOff3Bx: 0,
  timeZoneOn1Bx: 1,
  timeZoneOff1Bx: 1,
  timeZoneOn2Bx: 1,
  timeZoneOff2Bx: 1,
  timeZoneOn3Bx: 1,
  timeZoneOff3Bx: 1,
}