import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { Button, Dialog, DialogContent } from "@material-ui/core";

const RealtimeChartAll = ({
  ValueSensorData,
  ValueSensorName,
  ValueSensorUnit,
  SecondValueData,
  SecondValueName,
  ChartTitle,
}) => {
  const { token } = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    // Initialize series
    const initSeries = ValueSensorData.flatMap((data, index) => {
      const labelSensor = ValueSensorUnit[index]
        ? `${ValueSensorName[index]} (${ValueSensorUnit[index]})`
        : ValueSensorName[index];
      const existingSeries = series.find(s => s.name === labelSensor);
      const existingData = existingSeries ? existingSeries.data : [];
      
      // Add a corresponding 'SecondValue' series for each sensor
      const secondValueLabel = SecondValueName[index] ? SecondValueName[index] : "SecondValue";
      const existingSecondValueSeries = series.find(s => s.name === secondValueLabel);
      const existingSecondValueData = existingSecondValueSeries ? existingSecondValueSeries.data : [];
      
      return [
        { name: labelSensor, data: existingData },
        { name: secondValueLabel, data: existingSecondValueData },
      ];
    });

    // console.log(initSeries);
  
    setSeries(initSeries);
  }, [ValueSensorData, ValueSensorName, ValueSensorUnit]);
  
  useEffect(() => {
    const interval = setInterval(() => {
      const newSeries = series.map((s, index) => {
        let yValue;
        if (index % 2 === 0) { // Sensor data
          yValue = parseFloat(ValueSensorData[index / 2].toFixed(2));
        } else { // SecondValue data
          const secondValueIndex = (index - 1) / 2;
          yValue = parseFloat(SecondValueData[secondValueIndex].toFixed(2));
        }
  
        const newData = {
          x: Date.now(),
          y: yValue,
        };
  
        let data = [...s.data, newData];
        if (data.length > 20) {
          data = data.slice(1);
        }
  
        return { ...s, data };
      });
  
      setSeries(newSeries);
    }, 5000);
  
    return () => clearInterval(interval);
  }, [series, ValueSensorData, SecondValueData]);

  useEffect(() => {
    setSeries([]);
  }, [token]);

  const options = {
    chart: {
      type: "line",
      foreColor: "#fff", // Color of text on the chart
      background: "#1E222B", // Set the background color here
    },
    // colors: ["#f1c40f", "#4c59ed", "#34a853", "#ea4335", "#ff6f00", "#7c4dff", "#0d47a1", "#00c853"], // Colors for each series
    stroke: {
      curve: "smooth",
      width: 3, // Width of the line
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false, // Use local time
        style: {
          fontSize: "10px",
        },
      },
    },
    legend: {
      position: "top", // Position of the legend
      horizontalAlign: "right",
      floating: true,
      offsetY: -10,
      offsetX: -5,
    },
    title: {
      text: ChartTitle,
      style: {
        color: "#FFF", // Adjust the color of the title text to make it visible on the dark background
      },
    },
  };

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);
  // console.log(series);

  return (
    <div style={{ color: '#000' }}>
      <div
        style={{
          textAlign: "right",
          marginTop: "1rem",
          marginBottom: "0.5rem",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={openDialog}
          style={{ backgroundColor: "#f1c40f" }}
        >
          Fullscreen
        </Button>
      </div>

      <ReactApexChart options={options} series={series} type="line" />

      <Dialog open={isDialogOpen} onClose={closeDialog} fullWidth maxWidth="lg">
        <DialogContent>
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height="550px"
            forceRender
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RealtimeChartAll;
