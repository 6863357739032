import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Container,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useGlobalStyle from "../style/globalStyle";

const useStyle = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(3),
    textIndent: "20px",
  },
  modal: {
    backgroundColor: "#59585e",
    color: "#fff",
    minWidth: "35%",
    maxWidth: "45%",
  },
  submit: {
    background: "linear-gradient(45deg, #F29C19 30%, #fbb116 90%)",
    borderRadius: "5px",
    boxShadow: "0 3px 5px 2px rgba(251, 177, 22, .3)",
    padding: "8px",
    color: "white",
    fontSize: "14px",
    margin: theme.spacing(5, 0, 2),
  },
  input: {
    border: "1px solid #000",
    textAlign: "center",
    fontSize: "22px",
    backgroundColor: "#45444a",
    padding: "8px",
    borderRadius: "4px",
    "&:focus": {
      borderColor: "#fbb116",
    },
    color: "#fff",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  error: {
    color: "red",
  },
  paperStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
  },
  contentImg: {
    margin: "auto",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: "50vmin",
    display: "block",
  },
}));

const GenerateTokenKey = ({
  closeModal,
  closeModalCreate,
  isModalOpen,
  tokenkey,
}) => {
  const classes = useStyle();
  const globalStyle = useGlobalStyle();
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    closeModal();
    closeModalCreate();
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
      className={globalStyle.modal1}
      PaperProps={{
        className: globalStyle.modalXS,
      }}
    >
      <DialogContent>
        <Container className={classes.paperStyle}>
          <div>
            <form className={classes.form} noValidate>
              <div>
                <Typography className={classes.text}>
                เชื่อมต่อกับแอพพลิเคชั่น โดยการคัดลอก Token นี้ ไปใส่ในช่องพิมพ์ข้อความ (Type here) <br/>โดยต้องเลือกให้ตรงกับ Device ที่กำลังทำการตั้งค่าด้วย
                </Typography>
                <img src="/images/app-guide/popup_matching.png" className={classes.contentImg} alt="App Guide" />
                <Typography className={classes.input}>
                  tkn:{tokenkey}
                </Typography>
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                className={classes.submit}
              >
                OK
              </Button>
            </form>
          </div>
        </Container>

        {error && <p className={classes.error}>{error}</p>}
      </DialogContent>
    </Dialog>
  );
};

export default GenerateTokenKey;
