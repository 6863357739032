import React, { useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Button,
  OutlinedInput,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import CSS for toastify
import { useAuth } from "../context/AuthContext";

const useStyle = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(45deg, #323232 30%, #323332 90%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  submit: {
    background: "linear-gradient(45deg, #F29C19 30%, #fbb116 90%)",
    borderRadius: "5px",
    boxShadow: "0 3px 5px 2px rgba(195, 155, 245, .3)",
    padding: "15px",
    color: "white",
    fontSize: "18px",
    margin: theme.spacing(5, 0, 2),
  },
  paperStyle: {
    maxWidth: "550px",
    margin: "25px auto",
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    marginTop: theme.spacing(15),
    [theme.breakpoints.down("xs")]: {
      marginTop: "auto",
      width: "90%",
      height: "90%",
    },
    backgroundColor: "rgba(256, 256, 256, 0.9)",
    backdropFilter: "blur(8px)",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    margin: "auto",
    height: "6vmin",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    border: "2px solid #E8E8E8",
    borderRadius: "4px",
    "&:focus": {
      borderColor: "#fbb116",
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  h1: {
    fontSize: "34px",
    color: "#F29C19",
  },
  inputprops: {
    color: "#F29C19",
    "&:focus": {
      borderColor: "#fbb116",
    },
  },
}));

const ForgotPassword = () => {
  const { resetPassword } = useAuth();
  const [email, setEmail] = useState("");

  const classes = useStyle();

  const setVal = (e) => {
    setEmail(e.target.value);
  };

  const sendLink = async (e) => {
    e.preventDefault();

    // ตรวจสอบความถูกต้องของอีเมล
    if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      toast.error("Please enter a valid email address!");
      return;
    }

    try {
      await resetPassword(email);

      toast.success("Check your email for further instructions!", {
        position: "top-center",
      });

      setTimeout(() => {
        window.location.href = "/Login";
      }, 2500);
    } catch (error) {
      if (error.code === "auth/email-not-found") {
        toast.error(`${error.message}. Please check your email and try again.`);
      } else {
        console.error("Error sending reset email:", error);
        toast.error("Failed to send reset email. Please try again later.");
      }
    }
  };

  return (
    <Grid container component="main">
      <Container
        component={Paper}
        elevation={6}
        className={classes.paperStyle}
      >
        <div>
          <div className={classes.div}>
            <h1 className={classes.h1}>Enter Your Email</h1>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />

          <form className={classes.form} noValidate>
            <div>
              <label
                style={{ color: "black", fontWeight: "bold" }}
                htmlFor="email"
              >
                Email
              </label>
              <OutlinedInput
                InputProps={{
                  className: classes.inputprops,
                }}
                fullWidth
                value={email}
                onChange={setVal}
                type="email"
                className={classes.input}
                placeholder="Enter Your Email Address"
                autoComplete="email"
                id="email"
              />
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={sendLink}
              className={classes.submit}
            >
              Send
            </Button>
          </form>
          <img src="https://image.makewebeasy.net/makeweb/m_1920x0/1y4PqMoFk/DefaultData/Untitled_1.png?v=202012190947" className={classes.logo} />
        </div>
      </Container>
    </Grid>
  );
};

export default ForgotPassword;
