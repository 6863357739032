import React from 'react';
import Sidebar from '../../component/backend/SideBar';
// import TopNav from '../../component/backend/TopNav';
// import TopNav from './TopNav';
import ManageUser from './ManageUser';
// import Footer from './Footer';

function AdminPanel() {
    return (
        <div>
            <ManageUser />
        </div>
    );
}

export default AdminPanel;
