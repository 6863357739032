import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const RealtimeGauge = ({
  ValueSensorData,
  ValueSensorName,
  ValueSensorUnit,
  ChartTitle,
}) => {
  const labelSensor = ValueSensorUnit
    ? `${ValueSensorName} (${ValueSensorUnit})`
    : ValueSensorName;

  const [series, setSeries] = useState([0]); // Initialize with dummy data

  useEffect(() => {
    const interval = setInterval(() => {
      setSeries([
        parseFloat(ValueSensorData.toFixed(2)), // Update MainValue
        // Math.floor(Math.random() * 100) + 1, // Update SecondValue
      ]);
    }, 5000);

    return () => clearInterval(interval);
  }, [ValueSensorData]);

  const options = {
    series: series,
    chart: {
      height: 350,
      type: 'radialBar',
      toolbar: { show: true }
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: '70%',
          background: '#fff',
          dropShadow: { enabled: true, top: 3, left: 0, blur: 4, opacity: 0.24 }
        },
        track: {
          background: '#fff',
          strokeWidth: '67%',
          margin: 0,
          dropShadow: { enabled: true, top: -3, left: 0, blur: 4, opacity: 0.35 }
        },
        dataLabels: {
          show: true,
          name: { offsetY: -10, show: true, color: '#888', fontSize: '17px' },
          value: { 
            formatter: function(val) { return parseInt(val); },
            color: '#111',
            fontSize: '36px',
            show: true,
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#ABE5A1'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: { lineCap: 'round' },
    labels: [labelSensor],
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={390}
      />
    </div>
  );
};

export default RealtimeGauge;
