import {
  makeStyles,
  Drawer,
  Fab,
  Typography,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import StoreIcon from "@material-ui/icons/Store";
import EmailIcon from "@material-ui/icons/Email";
import { NavLink } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import clsx from "clsx"; //ใช้ classname ได้มากกว่า 1 class
import { useAuth } from "../context/AuthContext";
import AddBoxIcon from "@material-ui/icons/AddBox";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteDevice from "./Modal/DeleteDevice";
import CreateDevice from "./Modal/CreateDevice";
import StorageIcon from "@material-ui/icons/Storage";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import TimelineIcon from "@material-ui/icons/Timeline";
import YouTubeIcon from '@material-ui/icons/YouTube';

// Import Style
import useGlobalStyle from "./style/globalStyle";

const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;

const drawerWidth = 260;
const useStyles = makeStyles((theme) => {
  return {
    page: {
      // width: "100vw",
      // minHeight: "100vh",
    },
    root: {
      display: "flex",
    },
    drawer: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
      [theme.breakpoints.up("lg")]: {
        width: theme.spacing(9),
      },
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: "rgba(256, 256, 256 , 0.3)",
      backdropFilter: "blur(10px)",
      color: "#fff",
      whiteSpace: "nowrap",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      // width: "100px",
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      // width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(6.5),
      },
      "&:hover": {
        width: drawerWidth,
        boxShadow: theme.shadows[6],
        "& nav": {
          display: "block",
        },
      },
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    delete: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(1),
    },
    addBox: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1.5),
      [theme.breakpoints.down("xl")]: {
        marginLeft: theme.spacing(10),
      },
    },
    subactive: {
      color: "#FAB117",
      backgroundColor: "rgba(26, 30, 39, 1)",
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      margin: `${theme.spacing(0.5)}px 0`,
      [theme.breakpoints.down("xl")]: {
        paddingLeft: theme.spacing(8.5),
      },
    },
    subnonactive: {
      color: "#fff",
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      margin: `${theme.spacing(0.5)}px 0`,
      [theme.breakpoints.down("xl")]: {
        paddingLeft: theme.spacing(8.5),
      },
    },
    active: {
      color: "#FAB117",
    },
    nonActive: {
      color: "#fff",
    },
    title: {
      padding: theme.spacing(2),
    },
    icon: {
      minWidth: theme.spacing(5),
    },
    titleimg: {
      margin: "auto",
      maxHeight: theme.spacing(9)
    },
    menuButton: {
      [theme.breakpoints.up("lg")]: {
        marginLeft: -45,
      },
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    menuButtonHidden: {
      [theme.breakpoints.up("lg")]: {
        marginLeft: 10,
      },
    },
    appBar: {
      // minHeight: "64px",
      backgroundColor: "#FAB117",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    textitem: {
      marginLeft: -20,
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      marginTop: theme.spacing(8), // Add margin top to content
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentHome: {
      flexGrow: 1,
      marginTop: theme.spacing(8), // Add margin top to content
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  };
});

export default function ({ children, socket }) {
  const classes = useStyles();
  const globalStyle = useGlobalStyle();
  const history = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [openMenuDevice, setOpenMenuDevice] = React.useState(false);
  const { logout, currentUser, isAdmin } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectDeleteDevice, setSelectDeleteDevice] = useState(0);
  const [selectDeleteDeviceName, setSelectDeleteDeviceName] = useState("");
  const [isModalCreateDeviceOpen, setIsModalCreateDeviceOpen] = useState(false);
  const [timer, setTimer] = useState(0);
  const [oldUser, setOldUser] = useState("");
  const menuItems = [
    {
      text: "Export Data To CSV",
      icon: <StorageIcon />,
      path: "/exportcsv",
    },
    {
      text: "History/Compare Chart",
      icon: <TimelineIcon />,
      path: "/comparechart",
    },
    {
      text: "Store",
      icon: <StoreIcon />,
      path: "/home",
    },
    {
      text: "Contact Us",
      icon: <EmailIcon />,
      path: "/contactus",
    },
    {
      text: "Video How To (วิธีใช้งาน)",
      icon: <YouTubeIcon />,
      path: "/video",
    },
  ];

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModalCreateDevice = () => {
    setIsModalCreateDeviceOpen(true);
  };
  const closeModalCreateDevice = () => {
    setIsModalCreateDeviceOpen(false);
  };

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [dataSet, setDataSet] = useState([]);

  const handleClickopen = () => {
    setOpenMenuDevice(!openMenuDevice);
  };

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSettingUserMenu = () => {
    setAnchorElUser(null);
    history("/setting");
  };

  const handleAdminPanelMenu = () => {
    setAnchorElUser(null);
    history("/admin/manageUser");
  };

  const getDevice = async () => {
    if (currentUser.email) {
      try {
        axios
          .post(ModelaRestURL + "/api/socket/getDevice", {
            user: currentUser.email,
          })
          .then((data) => {
            setDataSet(data.data);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCreateDevice = (e) => {
    openModalCreateDevice();
  };

  const handleClickDelete = (deviceName, deviceNo) => async (e) => {
    e.preventDefault();
    setSelectDeleteDevice(deviceNo);
    setSelectDeleteDeviceName(deviceName);
    openModal();
  };
  
  const handleClick = (path) => {
    if (path === '/video') {
      window.open('https://www.youtube.com/watch?v=xp0Sg2w750Mu', '_blank');
    } else {
      history(path);
    }
  };

  const countOnlineDevice = dataSet.filter((obj) => obj.status === true).length;

  useEffect(async () => {
    getDevice();
  }, [currentUser]);

  useEffect(() => {
    if (oldUser && socket.connected) {
      socket.emit("leave_room", oldUser);
    }
    if (currentUser.email && socket.connected) {
      socket.emit("join_room", currentUser.email);
    }
    setOldUser(currentUser.email);
  }, [currentUser]);

  useEffect(() => {
    dataSet.map((data, idx) => {
      if (data.tokenBlynkKey) {
        fetch(
          "https://modela.blynk.cc/" +
            data.tokenBlynkKey +
            "/isHardwareConnected"
        )
          .then((res) => res.json())
          .then((resStatus) => {
            dataSet[idx].status = resStatus;
          });
      } else {
        dataSet[idx].status = false;
      }
    });

    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 10000); // 10 seconds in milliseconds

    return () => {
      clearInterval(interval);
    };
  }, [timer, currentUser, dataSet]);

  useEffect(() => {
    const handleDevice = () => {
      // console.log('Call getDevice');
      getDevice();
    };

    socket.on("receive_device", handleDevice);

    return () => {
      socket.off("receive_device", handleDevice);
    };
  }, [socket, timer]);

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar disableGutters={!open} className={classes.toolbar}>
          <Fab
            className={clsx(
              classes.menuButton,
              !open && classes.menuButtonHidden
            )}
            size="small"
            onClick={handleDrawerClose}
            aria-label="Menu"
          >
            <MenuIcon />
          </Fab>
          <NavLink to="/home" className={classes.titleimg}>
            <img src="/images/Icon-60.png" className={classes.titleimg}></img>
          </NavLink>
          <Typography>Online {countOnlineDevice} device</Typography>
          {
            <div>
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenUserMenu}
                color="inherit"
              >
                <AccountCircleIcon style={{ fontSize: "35px" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {/* <MenuItem onClick={handleSettingUserMenu}>My Profile</MenuItem> */}
                {isAdmin && (
                  <MenuItem onClick={handleAdminPanelMenu}>
                    Admin Panel
                  </MenuItem>
                )}
                <MenuItem onClick={handleSettingUserMenu}>Settings</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          }
        </Toolbar>
      </AppBar>
      {/* side drawer */}
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(globalStyle.drawerPaper, !open && globalStyle.drawerPaperClose),
        }}
        open={open}
        className={clsx(globalStyle.drawer, !open && globalStyle.drawerClose)}
      >
        <div className={classes.toolbarIcon}></div>
        <List>
          <ListItem
            button
            onClick={handleClickopen}
            className={openMenuDevice ? classes.active : classes.nonActive}
          >
            <ListItemIcon
              className={openMenuDevice ? classes.active : classes.nonActive}
            >
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText className={classes.textitem} primary="Dashboard" />
          </ListItem>
          <Collapse in={openMenuDevice} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {dataSet.map((item, idx) => (
                <ListItem
                  key={item.text}
                  onClick={() => history(item.path)}
                  button
                  className={
                    location.pathname === item.path
                      ? classes.subactive
                      : classes.subnonactive
                  }
                >
                  <ListItemText className={classes.textitem}>
                    {item.status == true && (
                      <>
                        <Brightness1Icon
                          style={{ color: "#00E52A", paddingRight: "0.3rem" }}
                        ></Brightness1Icon>
                      </>
                    )}
                    {item.status != true && (
                      <>
                        <Brightness1Icon
                          style={{ color: "#9D9D9D", paddingRight: "0.3rem" }}
                        ></Brightness1Icon>
                      </>
                    )}
                    {item.text} {item.tokenBlynkKey ? <span style={{ color: '#4CAF50' }}>Sync</span> : ""}
                  </ListItemText>
                  <ListItemSecondaryAction style={{ right: "3px" }}>
                    <IconButton className={classes.delete}>
                      <DeleteIcon
                        style={{
                          color: "#ffffff",
                        }}
                        onClick={handleClickDelete(item.text, idx)}
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}

              <IconButton className={classes.addBox}>
                <AddBoxIcon
                  style={{
                    color: "#ffffff",
                    alignItems: "center",
                    alignContent: "center",
                    textAlign: "center",
                  }}
                  onClick={handleCreateDevice}
                />
              </IconButton>
            </List>
          </Collapse>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              onClick={() => handleClick(item.path)}
              className={
                location.pathname === item.path
                  ? classes.active
                  : classes.nonActive
              }
            >
              <ListItemIcon
                className={clsx(
                  classes.icon && location.pathname === item.path
                    ? classes.active
                    : classes.nonActive
                )}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText className={classes.textitem} primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <div className={children.type.name === 'Home' ? classes.contentHome : classes.content}>
        {/* <div className={classes.toolbar}></div> */}
        {children}
        {isModalOpen && (
          <DeleteDevice
            isModalOpen={isModalOpen}
            dataSet={dataSet}
            selectDeleteDevice={selectDeleteDevice}
            selectDeleteDeviceName={selectDeleteDeviceName}
            closeModal={closeModal}
          />
        )}
        {isModalCreateDeviceOpen && (
          <CreateDevice
            isModalOpen={isModalCreateDeviceOpen}
            dataSet={dataSet}
            user={currentUser.email}
            closeModal={closeModalCreateDevice}
          />
        )}
      </div>
    </div>
  );
}