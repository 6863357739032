import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Select,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

const useStyles = makeStyles((theme) => {
  return {
    div: {
      padding: theme.spacing(3),
    },
    paper: {
      backgroundColor: "#59585e",
      borderRadius: "10px",
      color: "#fff",
      padding: theme.spacing(2),
    },
    select: {
      color: "#fff",
      backgroundColor: "#45444a",
      borderRadius: "20px",
    },
    label: {
      color: "#fff",
    },
    submit: {
      background: "linear-gradient(45deg, #F29C19 30%, #fbb116 90%)",
      borderRadius: "3px",
      boxShadow: "0 2px 3px 1px rgba(195, 155, 245, .3)",
      color: "white",
      fontSize: "15px",
      marginTop: theme.spacing(1),
    },
    textfield: {
      margin: theme.spacing(0, 0, 2),
    },
  };
});

const YellowCheckbox = withStyles({
  root: {
    color: '#f1c40f', // สีของ Checkbox ตัวเลือก
    '&$checked': {
      color: '#f1c40f', // สีของ Checkbox ที่ถูกเลือก
    },
    marginBottom: '0.7rem',
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function ExportCSV() {
  const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;
  const classes = useStyles();
  const { currentUser } = useAuth();
  const [selectedDevice, setSelectedDevice] = useState("");
  const [devices, setDevice] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const menuValues  = [
    { key: '1', text: 'Value1' },
    { key: '2', text: 'Value2' },
    { key: '3', text: 'Value3' },
    { key: '4', text: 'Value4' },
  ];

  const isOutsideRange = (day) => {
    if (focusedInput === "endDate") {
      return day.isAfter(moment().endOf('day'));
    }
    if (focusedInput === "startDate") {
      return day.isAfter(moment().endOf('day'));
    }
    return false;
  };

  useEffect(() => {
    getDevice();
  }, [currentUser]);

  const getDevice = async () => {
    try {
      axios
        .post(ModelaRestURL + "/api/socket/getDevice", {
          user: currentUser.email,
        })
        .then((data) => {
          setDevice(data.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
  };

  const handleValueToggle = (value) => () => {
    const currentIndex = selectedValues.indexOf(value);
    const newSelectedValues = [...selectedValues];

    if (currentIndex === -1) {
      newSelectedValues.push(value);
    } else {
      newSelectedValues.splice(currentIndex, 1);
    }

    setSelectedValues(newSelectedValues);
  };

  const handleExport = () => {
    axios
      .post(
        ModelaRestURL + "/api/socket/export-csv",
        {
          key: selectedDevice,
          startDate: startDate,
          endDate: endDate,
          selectedValues: selectedValues,
        },
        { responseType: "blob" }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "exported-data.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error exporting CSV:", error);
      });
  };

  return (
    <div className={classes.div}>
      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid item xs={12} md={8} lg={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom align="center">
              Export CSV
            </Typography>
            <FormControl
              variant="outlined"
              classes={{ root: classes.select }}
              className={classes.textfield}
              fullWidth
            >
              <DateRangePicker
                startDate={startDate}
                startDateId="startDateID"
                endDate={endDate}
                endDateId="endDateID"
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                isOutsideRange={isOutsideRange}
                showDefaultInputIcon
                showClearDates
                block
                displayFormat="YYYY/MM/DD" // ตั้งค่ารูปแบบวันที่ที่นี่
              />
            </FormControl>
            <FormControl
              variant="outlined"
              classes={{ root: classes.select }}
              className={classes.textfield}
              fullWidth
            >
              <InputLabel id="device-label" className={classes.label}>
                Devices
              </InputLabel>
              <Select
                labelId="device-label"
                id="device"
                value={selectedDevice}
                onChange={handleDeviceChange}
                label="Devices"
                classes={{
                  root: classes.select,
                  icon: classes.select,
                }}
                className={classes.select}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {devices.map((data) => (
                  <MenuItem key={data.key} value={data.key}>
                    {data.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="body3" gutterBottom>
              Select Values for CSV data export
            </Typography>
            <FormGroup row>
              {menuValues.map((data) => (
                <FormControlLabel
                  key={data.key}
                  control={
                    <YellowCheckbox
                      checked={selectedValues.indexOf(data.key) !== -1}
                      onChange={handleValueToggle(data.key)}
                      name={data.key}
                    />
                  }
                  label={data.text}
                />
              ))}
            </FormGroup>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleExport}
              disabled={selectedDevice === "" || selectedValues.length === 0}
              style={{ margin: "auto" }}
            >
              Export
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ExportCSV;
