import React from "react";
import { makeStyles, Typography, Button, Grid, Link } from "@material-ui/core";
import picture from "../api/background/modelaLanding.png";

import useGlobalStyle from "../component/style/globalStyle";
import BusinessIcon from '@material-ui/icons/Business';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles((theme) => {
  return {
    page: {
      background: "#f9f9f9",
      width: "100%",
      padding: theme.spacing(3),
    },
    root: {
      display: "flex",
    },

    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    active: {
      background: "#f4f4f4",
    },
    title: {
      fontWeight: "bold",
      color: "#fbb116",
      marginBottom: theme.spacing(5),
    },
    titleWhite: {
      fontWeight: "bold",
      color: "#fff",
    },
    submit: {
      background: "linear-gradient(45deg, #F29C19 30%, #fbb116 90%)",
      borderRadius: "30px",
      boxShadow: "0 3px 5px 2px rgba(251, 177, 22, .3)",
      padding: "10px",
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      color: "white",
      fontSize: "18px",
      margin: theme.spacing(90),
    },
    submitButtonContainer: {
      position: "relative",
      width: "100%",
      textAlign: "center",
      bottom: 0,
      left: 0,
      right: 0,
      // marginBottom: "2rem", // 2rem ห่างจากขอบล่าง
    },
    titleimg: {
      margin: "auto",
      height: "8vmin",
    },
    appAvatar: {
      marginLeft: theme.spacing(2),
      color: "#ec407a",
    },
    menuButton: {
      marginRight: 30,
    },
    mainFeaturedPost: {
      position: 'relative',
      backgroundImage: "url(" + picture + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: `calc(100vh - 64px)`, // 64px is the height of the app bar
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      background: "linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.9) 93%)",
    },
  };
});

function Home() {
  const classes = useStyles();
  const globalStyle = useGlobalStyle();

  return (
    <>
        <div className={classes.mainFeaturedPost}>
          <div className={classes.overlay} />
          <Grid container>
            <Grid item md={12}></Grid>
            <Grid item md={12}>
              <div className={classes.submitButtonContainer}>
                {/* <Button
                  href="https://modela.co.th"
                  className={classes.submit}
                  style={{ marginBottom: "2rem" }}
                  target="_blank"
                >
                  Go to Company
                </Button>
                <Button
                  href="https://www.store.modela.co.th/"
                  className={classes.submit}
                  style={{ marginBottom: "2rem" }}
                  target="_blank"
                >
                  Go to Shopping
                </Button> */}
                <Button
                  variant="contained"
                  className={globalStyle.buttonHome}
                  classes={{ root: globalStyle.buttonHome, contained: globalStyle.buttonHover }}
                  startIcon={<BusinessIcon />}
                  href="https://modela.co.th"
                  target="_blank"
                  style={{ marginTop: '80vh' }}
                >
                  Go to Company
                </Button>
                <Button
                  variant="contained"
                  className={globalStyle.buttonHome}
                  classes={{ root: globalStyle.buttonHome, contained: globalStyle.buttonHover }}
                  startIcon={<ShoppingCartIcon />}
                  href="https://www.store.modela.co.th/"
                  target="_blank"
                  style={{ marginTop: '80vh' }}
                >
                  Go to Shopping
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
    </>
  );
}
export default Home;
