import React, { useState } from "react";
import {
  Container,
  Grid,
  Paper,
  TextField,
  Button,
  CssBaseline,
  Avatar,
  Typography,
  Link,
  Checkbox,
  Input,
  FormControlLabel,
  OutlinedInput,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate, useLocation, NavLink } from "react-router-dom";


const useStyle = makeStyles((theme) => ({
  root: {
    // backgroundImage: "url(" + denpicture + ")",
    // opacity: 0.75,
    // background: url()
    background: "linear-gradient(45deg, #323232 30%, #323332 90%)",
    // height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  submit: {
    background: "linear-gradient(45deg, #F29C19 30%, #fbb116 90%)",
    borderRadius: "5px",
    boxShadow: "0 3px 5px 2px rgba(195, 155, 245, .3)",
    padding: "15px",
    color: "white",
    fontSize: "18px",
    // backgroundColor: "#3f51b5",
    margin: theme.spacing(5, 0, 2),
  },
  paperStyle: {
    // padding: 20,
    maxWidth: "550px",
    margin: "25px auto",
    // width: "40%",
    // height: "70vh",
    display: "flex",
    // alignItems: "center",
    flexDirection: "column",
    // padding: "35px 10px",
    borderRadius: "10px",
    marginTop: theme.spacing(15),
    [theme.breakpoints.down("xs")]: {
      marginTop: "auto",
      width: "90%",
      height: "90%",
    },
    backgroundColor: "rgba(256, 256, 256, 0.9)",
    backdropFilter: "blur(8px)",
  },
  div: {
    // marginTop: theme.spacing(5),
    // padding: "35px 10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    margin: "auto",
    height: "6vmin",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    border: "2px solid #E8E8E8",
    borderRadius: "4px",
    "&:focus": {
      borderColor: "#fbb116",
    },
    // paddingLeft: 8,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  showpass: {
    position: "absolute",
    right: "0",
    top: "1px",
    padding: "7px 10px",
    marginRight: "5px",
    marginTop: "12px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#2a4365",
    backgroundColor: "#EDF2F7",
    borderRadius: "5px",
    cursor: "pointer",
  },
  two: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  h1: {
    fontSize: "34px",
    color: "#F29C19",
  },
  p: {
    color: "#718096",
    fontSize: "15px",
    marginTop: 0,
  },
  inputprops: {
    color: "#F29C19",
    "&:focus": {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: "#fbb116",
    },
  },
}));

const ResetPassword = () => {
    const [passShow1, setPassShow1] = useState(false);
    const [passShow2, setPassShow2] = useState(false);

  const classes = useStyle();

  return (
    <Grid container component="main">
      {/* <div> */}
      <Container
        component={Paper}
        elevation={6}
        // maxWidth="20%"
        className={classes.paperStyle}
      >
        <div>
          <div className={classes.div}>
            <h1 className={classes.h1}>Enter Your New Password</h1>
          </div>

          <form className={classes.form} noValidate>
            <div>
              <label
                style={{ color: "black", fontWeight: "bold" }}
                htmlFor="password"
              >
                New Password
              </label>
              <div className={classes.two}>
                <OutlinedInput
                  fullWidth
                  type={!passShow1 ? "password" : "text"}
                  className={classes.input}
                  name="password"
                  id="password"
                  placeholder="Enter Your New Password"
                />
                <div
                  className={classes.showpass}
                  onClick={() => setPassShow1(!passShow1)}
                >
                  {!passShow1 ? "Show" : "Hide"}
                </div>
              </div>
            </div>
            <div>
              <label
                style={{ color: "black", fontWeight: "bold" }}
                htmlFor="password"
              >
                Re New Password
              </label>
              <div className={classes.two}>
                <OutlinedInput
                  fullWidth
                  type={!passShow2 ? "password" : "text"}
                  className={classes.input}
                  name="password"
                  id="password"
                  placeholder="Enter Your New Password"
                />
                <div
                  className={classes.showpass}
                  onClick={() => setPassShow2(!passShow2)}
                >
                  {!passShow2 ? "Show" : "Hide"}
                </div>
              </div>
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Send
            </Button>
          </form>
          <img src="https://image.makewebeasy.net/makeweb/m_1920x0/1y4PqMoFk/DefaultData/Untitled_1.png?v=202012190947" className={classes.logo} />

        </div>
      </Container>
      {/* </div> */}
    </Grid>
  );
};

export default ResetPassword;
