import React, { useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import { SwitchIosSmallGreen, SwitchIosSmallRed } from "./SwitchIos";
import TimerSwitchModal from "./Modal/TimerSwitchModal";

const useStyles = makeStyles((theme) => {
  return {
    topicpaper: {
      fontSize: "1.3rem",
      fontWeight: "500",
      textAlign: "center",
    },
    grid: {
      textAlign: "center",
    },
    fontSmall: {
      textAlign: "center",
      fontSize: "0.9rem",
    },
  };
});

function TimerSetting({
  switchTimerOn1,
  switchTimerOn2,
  switchTimerOn3,
  switchTimerOff1,
  switchTimerOff2,
  switchTimerOff3,
  timerOn1,
  timerOff1,
  timerOn2,
  timerOff2,
  timerOn3,
  timerOff3,
  daySelectedOn1,
  daySelectedOn2,
  daySelectedOn3,
  daySelectedOff1,
  daySelectedOff2,
  daySelectedOff3,
  setDataSetting,
  dataSetting,
  blynkToken,
  timeZoneOn1,
  timeZoneOff1,
  timeZoneOn2,
  timeZoneOff2,
  timeZoneOn3,
  timeZoneOff3,
  titleName,
}) {
  const classes = useStyles();
  const [isModalTimer1Open, setIsModalTimer1Open] = useState(false);

  const openModalTimer1 = () => {
    setIsModalTimer1Open(true);
  };

  const closeModalTimer1 = () => {
    setIsModalTimer1Open(false);
  };

  const renderSwitchRow = (label, switchValue, handleSwitchChange, timerValue, daysSelected, isGreen) => (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item lg={2} className={classes.fontSmall}>
        {label}
      </Grid>
      <Grid item lg={3}>
        {isGreen ? (
          <SwitchIosSmallGreen checked={switchValue} name={label} handleChange={handleSwitchChange} />
        ) : (
          <SwitchIosSmallRed checked={switchValue} name={label} handleChange={handleSwitchChange} />
        )}
      </Grid>
      <Grid item lg={2} className={classes.fontSmall}>
        {new Date(timerValue * 1000).toISOString().slice(11, 16)}
      </Grid>
      <Grid item lg={5} className={classes.fontSmall}>
        {daysSelected.length > 6 && <>everyday</>}
        {daysSelected.length === 0 && <>none</>}
        {daysSelected.length <= 6 && (
          <>{daysSelected.map((days) => days.d).join(", ")}</>
        )}
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={1}></Grid>
        <Grid item lg={8}>
          <Typography className={classes.topicpaper}>
            {titleName}
          </Typography>
        </Grid>
        <Grid item lg={3}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => {
              openModalTimer1();
            }}
            color="inherit"
          >
            <SettingsIcon />
          </IconButton>
        </Grid>
      </Grid>
      {/* <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={2} className={classes.fontSmall}>
          on1
        </Grid>
        <Grid item lg={3}>
          <SwitchIosSmallGreen
            checked={switchTimerOn1}
            name="switchTimerOn1"
            handleChange={() => {openModalTimer1()}}
          />
        </Grid>
        <Grid item lg={2} className={classes.fontSmall}>
          {new Date(timerOn1 * 1000).toISOString().slice(11, 16)}
        </Grid>
        <Grid item lg={5} className={classes.fontSmall}>
          {daySelectedOn1.length > 6 && <>everyday</>}
          {daySelectedOn1.length == 0 && <>none</>}
          {daySelectedOn1.length <= 6 && (
            <>{daySelectedOn1.map((days) => days.d).join(", ")}</>
          )}
        </Grid>
        <Grid item lg={2} className={classes.fontSmall}>
          off1
        </Grid>
        <Grid item lg={3}>
          <SwitchIosSmallRed
            checked={switchTimerOff1}
            name="switchTimerOff1"
            handleChange={() => {openModalTimer1()}}
          />
        </Grid>
        <Grid item lg={2} className={classes.fontSmall}>
          {new Date(timerOff1 * 1000).toISOString().slice(11, 16)}
        </Grid>
        <Grid item lg={5} className={classes.fontSmall}>
          {daySelectedOff1.length > 6 && <>everyday</>}
          {daySelectedOff1.length == 0 && <>none</>}
          {daySelectedOff1.length <= 6 && (
            <>{daySelectedOff1.map((days) => days.d).join(", ")}</>
          )}
        </Grid>
        <Grid item lg={2} className={classes.fontSmall}>
          on2
        </Grid>
        <Grid item lg={3}>
          <SwitchIosSmallGreen
            checked={switchTimerOn2}
            name="switchTimerOn2"
            handleChange={() => {openModalTimer1()}}
          />
        </Grid>
        <Grid item lg={2} className={classes.fontSmall}>
          {new Date(timerOn2 * 1000).toISOString().slice(11, 16)}
        </Grid>
        <Grid item lg={5} className={classes.fontSmall}>
          {daySelectedOn2.length > 6 && <>everyday</>}
          {daySelectedOn2.length == 0 && <>none</>}
          {daySelectedOn2.length <= 6 && (
            <>{daySelectedOn2.map((days) => days.d).join(", ")}</>
          )}
        </Grid>
        <Grid item lg={2} className={classes.fontSmall}>
          off2
        </Grid>
        <Grid item lg={3}>
          <SwitchIosSmallRed
            checked={switchTimerOff2}
            name="switchTimerOff2"
            handleChange={() => {openModalTimer1()}}
          />
        </Grid>
        <Grid item lg={2} className={classes.fontSmall}>
          {new Date(timerOff2 * 1000).toISOString().slice(11, 16)}
        </Grid>
        <Grid item lg={5} className={classes.fontSmall}>
          {daySelectedOff2.length > 6 && <>everyday</>}
          {daySelectedOff2.length == 0 && <>none</>}
          {daySelectedOff2.length <= 6 && (
            <>{daySelectedOff2.map((days) => days.d).join(", ")}</>
          )}
        </Grid>
        <Grid item lg={2} className={classes.fontSmall}>
          on3
        </Grid>
        <Grid item lg={3}>
          <SwitchIosSmallGreen
            checked={switchTimerOn3}
            name="switchTimerOn3"
            handleChange={() => {openModalTimer1()}}
          />
        </Grid>
        <Grid item lg={2} className={classes.fontSmall}>
          {new Date(timerOn3 * 1000).toISOString().slice(11, 16)}
        </Grid>
        <Grid item lg={5} className={classes.fontSmall}>
          {daySelectedOn3.length > 6 && <>everyday</>}
          {daySelectedOn3.length == 0 && <>none</>}
          {daySelectedOn3.length <= 6 && (
            <>{daySelectedOn3.map((days) => days.d).join(", ")}</>
          )}
        </Grid>
        <Grid item lg={2} className={classes.fontSmall}>
          off3
        </Grid>
        <Grid item lg={3}>
          <SwitchIosSmallRed
            checked={switchTimerOff3}
            name="switchTimerOff3"
            handleChange={() => {openModalTimer1()}}
          />
        </Grid>
        <Grid item lg={2} className={classes.fontSmall}>
          {new Date(timerOff3 * 1000).toISOString().slice(11, 16)}
        </Grid>
        <Grid item lg={5} className={classes.fontSmall}>
          {daySelectedOff3.length > 6 && <>everyday</>}
          {daySelectedOff3.length == 0 && <>none</>}
          {daySelectedOff3.length <= 6 && (
            <>{daySelectedOff3.map((days) => days.d).join(", ")}</>
          )}
        </Grid>
      </Grid> */}
      <Grid container direction="row" justifyContent="center" alignItems="center">
        {renderSwitchRow("on1", switchTimerOn1, openModalTimer1, timerOn1, daySelectedOn1, true)}
        {renderSwitchRow("off1", switchTimerOff1, openModalTimer1, timerOff1, daySelectedOff1, false)}
        {renderSwitchRow("on2", switchTimerOn2, openModalTimer1, timerOn2, daySelectedOn2, true)}
        {renderSwitchRow("off2", switchTimerOff2, openModalTimer1, timerOff2, daySelectedOff2, false)}
        {renderSwitchRow("on3", switchTimerOn3, openModalTimer1, timerOn3, daySelectedOn3, true)}
        {renderSwitchRow("off3", switchTimerOff3, openModalTimer1, timerOff3, daySelectedOff3, false)}
      </Grid>
      {isModalTimer1Open && (
        <TimerSwitchModal
          isModalOpen={isModalTimer1Open}
          closeModal={closeModalTimer1}
          title={titleName}
          timerOn1={timerOn1}
          timerOn2={timerOn2}
          timerOn3={timerOn3}
          timerOff1={timerOff1}
          timerOff2={timerOff2}
          timerOff3={timerOff3}
          daySelectedOn1={daySelectedOn1}
          daySelectedOn2={daySelectedOn2}
          daySelectedOn3={daySelectedOn3}
          daySelectedOff1={daySelectedOff1}
          daySelectedOff2={daySelectedOff2}
          daySelectedOff3={daySelectedOff3}
          stateSwitchOn1={switchTimerOn1}
          stateSwitchOn2={switchTimerOn2}
          stateSwitchOn3={switchTimerOn3}
          stateSwitchOff1={switchTimerOff1}
          stateSwitchOff2={switchTimerOff2}
          stateSwitchOff3={switchTimerOff3}
          setDataSetting={setDataSetting}
          dataSetting={dataSetting}
          blynkToken={blynkToken}
          timeZoneOn1={timeZoneOn1}
          timeZoneOff1={timeZoneOff1}
          timeZoneOn2={timeZoneOn2}
          timeZoneOff2={timeZoneOff2}
          timeZoneOn3={timeZoneOn3}
          timeZoneOff3={timeZoneOff3}
        />
      )}
    </div>
  );
}

export default TimerSetting;