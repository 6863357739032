import { makeStyles } from "@material-ui/core/styles";

const colors = {
  background: {
    primary: "#FAB117",
    secondary: "#1E222B",
    tertiary: "#1A1E27",
  },
  text: {
    primary: "#fff",
    secondary: "#FAB117",
  },
  alert: {
    success: "#4CAF50",
    warning: "#FF9800",
    danger: "#F44336",
    info: "#2196F3",
    light: "#f8f9fa",
    dark: "#343a40",
    custom: "#6c757d",
  },
  icon: {
    default: "#6E7A8A",
    primary: "#FAB117",
    secondary: "#1E222B",
    success: "#4CAF50",
    warning: "#FF9800",
    danger: "#F44336",
    info: "#2196F3",
    light: "#f8f9fa",
    dark: "#343a40",
    custom: "#6c757d",
    action: "#607D8B",
    highlight: "#FFC107",
  },
  border: {
    primary: "#BEBEBE",
    secondary: "#1A1E27",
  },
};

const drawerWidth = 260;

const useGlobalStyle = makeStyles((theme) => ({
  // ส่วนของพื้นหลัง
  bgPrimary: {
    backgroundColor: colors.background.primary,
  },
  bgSecondary: {
    backgroundColor: colors.background.secondary,
  },
  bgTertiary: {
    backgroundColor: colors.background.tertiary,
  },
  // ตัวอักษรทั่วไป
  bodyTextPrimay: {
    color: colors.text.primary,
    fontSize: "1rem",
  },
  // หัวข้อหลัก
  heading: {
    fontSize: "2rem",
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2), // ใช้หน่วย spacing ของ theme
  },
  
  // ระยะห่างสำหรับบล็อคหรือส่วนต่างๆ
  flexStart: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  // ระยะห่างสำหรับบล็อคหรือส่วนต่างๆ
  margin1: {
    margin: theme.spacing(1),
  },
  marginB1: {
    marginBottom: theme.spacing(1),
  },
  marginL1: {
    marginLeft: theme.spacing(1),
  },
  marginR1: {
    marginRight: theme.spacing(1),
  },
  marginT1: {
    marginTop: theme.spacing(1),
  },
  padding1: {
    padding: theme.spacing(1),
  },
  paddingB1: {
    paddingBottom: theme.spacing(1),
  },
  paddingL1: {
    paddingLeft: theme.spacing(1),
  },
  paddingR1: {
    paddingRight: theme.spacing(1),
  },
  paddingT1: {
    paddingTop: theme.spacing(1),
  },
  
  margin2: {
    margin: theme.spacing(2),
  },
  marginB2: {
    marginBottom: theme.spacing(2),
  },
  marginL2: {
    marginLeft: theme.spacing(2),
  },
  marginR2: {
    marginRight: theme.spacing(2),
  },
  marginT2: {
    marginTop: theme.spacing(2),
  },
  padding2: {
    padding: theme.spacing(2),
  },
  paddingB2: {
    paddingBottom: theme.spacing(2),
  },
  paddingL2: {
    paddingLeft: theme.spacing(2),
  },
  paddingR2: {
    paddingRight: theme.spacing(2),
  },
  paddingT2: {
    paddingTop: theme.spacing(2),
  },
  
  margin3: {
    margin: theme.spacing(3),
  },
  marginB3: {
    marginBottom: theme.spacing(3),
  },
  marginL3: {
    marginLeft: theme.spacing(3),
  },
  marginR3: {
    marginRight: theme.spacing(3),
  },
  marginT3: {
    marginTop: theme.spacing(3),
  },
  padding3: {
    padding: theme.spacing(3),
  },
  paddingB3: {
    paddingBottom: theme.spacing(3),
  },
  paddingL3: {
    paddingLeft: theme.spacing(3),
  },
  paddingR3: {
    paddingRight: theme.spacing(3),
  },
  paddingT3: {
    paddingTop: theme.spacing(3),
  },

  // paper Dashboard
  paperMain: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: colors.text.primary,
    backgroundColor: colors.background.secondary,
    borderRadius: "1rem",
    height: "100%",
    boxShadow: "0 0px 6px rgba(0, 0, 0, 0.25)", // Add box shadow
    // border: `1px solid ${colors.border.primary}`,
  },
  paperChart: {
    color: colors.text.primary,
    backgroundColor: colors.background.secondary,
    borderRadius: "1rem",
    height: "100%",
    boxShadow: "0 0px 6px rgba(0, 0, 0, 0.25)", // Add box shadow
    // border: `1px solid ${colors.border.primary}`,
  },
  paperLogin: {
    maxWidth: "600px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    borderRadius: "1.5rem",
    marginTop: theme.spacing(8),
    [theme.breakpoints.down("xs")]: {
      marginTop: "auto",
      width: "90%",
      height: "90%",
    },
    backgroundColor: "#343434",
    backdropFilter: "blur(8px)",
  },
  topicPaper: {
    fontSize: "1.3rem",
    fontWeight: "500",
    textAlign: "center",
  },
  bodyPaper: {
    fontSize: "1rem",
    textAlign: "center",
  },

  // textfield
  textfield: {
    backgroundColor: colors.background.tertiary,
    borderRadius: "2rem",
    color: colors.text.primary,
    width: "90%",
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  textfieldInput: {
    color: colors.text.primary,
    borderRadius: "2rem",
    height: "45px",
    borderColor: colors.border.secondary,
  },
  textfieldToken: {
    backgroundColor: colors.background.tertiary,
    borderRadius: "0.5rem",
    color: colors.text.primary,
    width: "100%",
  },
  textfieldTokenInput: {
    color: colors.text.primary,
    borderRadius: "0.5rem",
    borderColor: colors.border.secondary,
    textAlign: "center",
    margin: theme.spacing(-1),
  },

  // sensorControl border
  borderSensor: {
    border: `1px solid ${colors.border.primary}`,
    borderRadius: '1rem',
    padding: '0.5rem',
    margin: '0.2rem',
    textAlign: 'center',
    width: '80%',
  },

  // container
  container: {
    backgroundColor: colors.background.tertiary,
    minHeight: "100vh",
  },

  // drawer
  drawer: {
    width: drawerWidth - 10,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(6),
    },
    [theme.breakpoints.up("lg")]: {
      width: theme.spacing(6),
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: colors.background.secondary,
    backdropFilter: "blur(10px)",
    color: "#fff",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(6.5),
    },
    "&:hover": {
      width: drawerWidth,
      boxShadow: theme.shadows[6],
      "& nav": {
        display: "block",
      },
    },
  },
  button: {
    margin: theme.spacing(1),
    color: colors.text.primary, // เปลี่ยนสีของ text เป็นสีขาว
    backgroundColor: colors.background.primary,
    borderRadius: '0.5rem',
    '&:hover': {
      backgroundColor: 'rgba(250, 177, 23, 0.8)', // เปลี่ยนสีของปุ่มเมื่อ hover
    },
  },
  buttonHover: {
    '&:hover': {
      backgroundColor: 'rgba(250, 177, 23, 0.8)', // เปลี่ยนสีของปุ่มเมื่อ hover
    },
  },
  buttonHome: {
    fontSize: "1.3rem",
    color: colors.text.primary, // เปลี่ยนสีของ text เป็นสีขาว
    backgroundColor: colors.background.primary,
    boxShadow: "0 3px 5px 2px rgba(250, 177, 23, 0.3)",
    borderRadius: '1.5rem',
    margin: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    '&:hover': {
      backgroundColor: 'rgba(250, 177, 23, 0.8)', // เปลี่ยนสีของปุ่มเมื่อ hover
    },
  },
  buttonCopy: {
    margin: theme.spacing(1),
    color: colors.text.primary, // เปลี่ยนสีของ text เป็นสีขาว
    backgroundColor: colors.alert.custom,
    borderRadius: '0.5rem',
    '&:hover': {
      backgroundColor: 'rgba(108, 117, 125, 0.8)', // เปลี่ยนสีของปุ่มเมื่อ hover
    },
  },
  buttonCopyHover: {
    '&:hover': {
      backgroundColor: 'rgba(108, 117, 125, 0.8)', // เปลี่ยนสีของปุ่มเมื่อ hover
    },
  },
  buttonCopied: {
    margin: theme.spacing(1),
    color: colors.text.primary, // เปลี่ยนสีของ text เป็นสีขาว
    backgroundColor: colors.alert.success,
    borderRadius: '0.5rem',
    '&:hover': {
      backgroundColor: 'rgba(108, 117, 125, 0.8)', // เปลี่ยนสีของปุ่มเมื่อ hover
    },
  },
  buttonCopiedHover: {
    '&:hover': {
      backgroundColor: 'rgba(108, 117, 125, 0.8)', // เปลี่ยนสีของปุ่มเมื่อ hover
    },
  },
  modal: {
    backgroundColor: colors.background.secondary,
    color: colors.text.primary,
    minWidth: "65vw",
    borderRadius: '1rem',
  },
  modalSM: {
    backgroundColor: colors.background.secondary,
    color: colors.text.primary,
    minWidth: "50vw",
    borderRadius: '1rem',
  },
  modalXS: {
    backgroundColor: colors.background.secondary,
    color: colors.text.primary,
    minWidth: "40vw",
    borderRadius: '1rem',
  },
  modal1: {
    zIndex: 1,
  },
}));

export default useGlobalStyle;