module.exports = [
    {
      name: "None",
      value: 0,
    },
    {
      name: "เปิดเมื่อพบ/ปิดเมื่อไม่พบ",
      value: 1,
    },
    {
      name: "ปิดเมื่อพบ/เปิดเมื่อไม่พบ",
      value: 2,
    },
    {
      name: "เปิดและเตือนเมื่อตรวจพบ",
      value: 3,
    },
    {
      name: "ปิดและเตือนเมื่อตรวจพบ",
      value: 4,
    },
    {
      name: "เปิดและเตือนเมื่อตรวจไม่พบ",
      value: 5,
    },
    {
      name: "ปิดและเตือนเมื่อตรวจไม่พบ",
      value: 6,
    },
    {
      name: "ไม่ควบคุม",
      value: 7,
    },
  ]