import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  DialogActions,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  Typography,
  Grid,
  Input,
  ListItemText,
  Paper,
  Card,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GreenSwitchIos, SwitchIosRed } from "../SwitchIos";
import axios from "axios";
import { useParams } from "react-router-dom";
import TimeZone from "../../api/ui/TimeZone";
import dataDay from "../../api/ui/DaySelect"
import useGlobalStyle from "../style/globalStyle";

const useStyle = makeStyles((theme) => ({
  textfield: {
    margin: theme.spacing(0, 0, 2),
  },
  TimePicker: {
    zIndex: 2000,
    fontSize: "20px",
  },
  p: {
    color: "red",
  },
  icon: {
    color: "red", // Set the desired color for the icon
    display: "none",
  },
  modal: {
    backgroundColor: "#59585e",
    color: "#fff",
    minWidth: "70vw",
  },
  modal1: {
    // width:"50%"
    zIndex: 1,
  },
  selectoption: {
    color: "#fff",
    // backgroundColor: "#45444a",
    // borderRadius: "30px",

    borderColor: "none",
  },

  textcenter: {
    textAlign: "center",
    fontSize: "30px",
  },
  select: {
    color: "#fff",
    backgroundColor: "#45444a",
    borderRadius: "30px",
  },
  active: {
    backgroundColor: "#F29C19",
  },
  checkbox: {
    width: 16,
    height: 16,
  },
  menuItem: {
    "&.Mui-selected": {
      backgroundColor: "rgba(251, 177, 22, 0.8)", // Set the desired color for the selected/active option
    },
    "&.Mui-selected:hover": {
      backgroundColor: "rgba(251, 177, 22, 0.4)", // Set the desired color for the selected/active option
    },
  },
  selectTime: {
    color: "#fff",
    backgroundColor: "#45444a",
    width: "100%",
    borderRadius: "8px",
    textAlign: "center",
    fontSize: "20px",
    alignItems: "center",
  },
  paper: {
    backgroundColor: "#45444a",
    padding: theme.spacing(1),
    width: "55%",
    // height: h,
    marginTop: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
    color: "#fff",
  },
  finish: {
    color: "#F29C19",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 0,
  },
  dialog: {
    fontSize: "2px",
  },
  timeBox: {
    // border: "4px solid red",
    width: "100%",
  },
  popupStyle: {
    fontFamily: "Arial, sans-serif",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    justifyContent: "space-evenly",
  },
  dateBox: {
    border: "0px",
    boxShadow: "2px 2px 7px 2px rgba(0,0,0,0.3)",
  },
  checkedIcon: {
    backgroundColor: "#fbb116",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 19,
      height: 19,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#F29C19",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      backgroundColor: "#45444a",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#F29C19",
      },
    },
  },
  classes: {
    style: {
      "&:hover": {
        backgroundColor: "#F29C19",
      },
    },
  },
};

const AddAvailableModal = ({
  closeModal,
  isModalOpen,
  title,
  timerOn1,
  timerOn2,
  timerOn3,
  timerOff1,
  timerOff2,
  timerOff3,
  stateSwitchOn1,
  stateSwitchOn2,
  stateSwitchOn3,
  stateSwitchOff1,
  stateSwitchOff2,
  stateSwitchOff3,
  daySelectedOn1,
  daySelectedOn2,
  daySelectedOn3,
  daySelectedOff1,
  daySelectedOff2,
  daySelectedOff3,
  setDataSetting,
  dataSetting,
  blynkToken,
  timeZoneOn1,
  timeZoneOff1,
  timeZoneOn2,
  timeZoneOff2,
  timeZoneOn3,
  timeZoneOff3,
}) => {
  const classes = useStyle();
  const globalStyle = useGlobalStyle();
  const [error, setError] = useState("");
  const { token } = useParams();

  const [daySelectedOn1Modal, setDaySelectedOn1Modal] = useState([]);
  const [daySelectedOn2Modal, setDaySelectedOn2Modal] = useState([]);
  const [daySelectedOn3Modal, setDaySelectedOn3Modal] = useState([]);
  const [daySelectedOff1Modal, setDaySelectedOff1Modal] = useState([]);
  const [daySelectedOff2Modal, setDaySelectedOff2Modal] = useState([]);
  const [daySelectedOff3Modal, setDaySelectedOff3Modal] = useState([]);

  const [TimeZoneOn1Modal, setTimeZoneOn1Modal] = useState([]);
  const [TimeZoneOn2Modal, setTimeZoneOn2Modal] = useState([]);
  const [TimeZoneOn3Modal, setTimeZoneOn3Modal] = useState([]);
  const [TimeZoneOff1Modal, setTimeZoneOff1Modal] = useState([]);
  const [TimeZoneOff2Modal, setTimeZoneOff2Modal] = useState([]);
  const [TimeZoneOff3Modal, setTimeZoneOff3Modal] = useState([]);

  const [states, setStates] = useState({
    checkOn1: 0,
    checkOff1: 0,
    checkOn2: 0,
    checkOff2: 0,
    checkOn3: 0,
    checkOff3: 0,
  });

  const [stateTimer, setStateTimer] = useState({
    HoursOn1: 0,
    MinuteOn1: 0,
    HoursOff1: 0,
    MinuteOff1: 0,
    HoursOn2: 0,
    MinuteOn2: 0,
    HoursOff2: 0,
    MinuteOff2: 0,
    HoursOn3: 0,
    MinuteOn3: 0,
    HoursOff3: 0,
    MinuteOff3: 0,
  });

  const handleChangesTimer = (event) => {
    setStateTimer({ ...stateTimer, [event.target.name]: event.target.value });
  };

  const handleChanges = (event) => {
    setStates({ ...states, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    setStateTimer({
      ...stateTimer,
      ["HoursOn1"]: Number(
        new Date(timerOn1 * 1000).toISOString().slice(11, 13)
      ),
      ["MinuteOn1"]: Number(
        new Date(timerOn1 * 1000).toISOString().slice(14, 16)
      ),
      ["HoursOn2"]: Number(
        new Date(timerOn2 * 1000).toISOString().slice(11, 13)
      ),
      ["MinuteOn2"]: Number(
        new Date(timerOn2 * 1000).toISOString().slice(14, 16)
      ),
      ["HoursOn3"]: Number(
        new Date(timerOn3 * 1000).toISOString().slice(11, 13)
      ),
      ["MinuteOn3"]: Number(
        new Date(timerOn3 * 1000).toISOString().slice(14, 16)
      ),
      ["HoursOff1"]: Number(
        new Date(timerOff1 * 1000).toISOString().slice(11, 13)
      ),
      ["MinuteOff1"]: Number(
        new Date(timerOff1 * 1000).toISOString().slice(14, 16)
      ),
      ["HoursOff2"]: Number(
        new Date(timerOff2 * 1000).toISOString().slice(11, 13)
      ),
      ["MinuteOff2"]: Number(
        new Date(timerOff2 * 1000).toISOString().slice(14, 16)
      ),
      ["HoursOff3"]: Number(
        new Date(timerOff3 * 1000).toISOString().slice(11, 13)
      ),
      ["MinuteOff3"]: Number(
        new Date(timerOff3 * 1000).toISOString().slice(14, 16)
      ),
    });
    setStates({
      ...states,
      ["checkOn1"]: stateSwitchOn1,
      ["checkOn2"]: stateSwitchOn2,
      ["checkOn3"]: stateSwitchOn3,
      ["checkOff1"]: stateSwitchOff1,
      ["checkOff2"]: stateSwitchOff2,
      ["checkOff3"]: stateSwitchOff3,
    });
    setDaySelectedOn1Modal(daySelectedOn1);
    setDaySelectedOn2Modal(daySelectedOn2);
    setDaySelectedOn3Modal(daySelectedOn3);
    setDaySelectedOff1Modal(daySelectedOff1);
    setDaySelectedOff2Modal(daySelectedOff2);
    setDaySelectedOff3Modal(daySelectedOff3);
    setTimeZoneOn1Modal(timeZoneOn1);
    setTimeZoneOff1Modal(timeZoneOff1);
    setTimeZoneOn2Modal(timeZoneOn2);
    setTimeZoneOff2Modal(timeZoneOff2);
    setTimeZoneOn3Modal(timeZoneOn3);
    setTimeZoneOff3Modal(timeZoneOff3);

  }, []);

  const handleChangeDaySelect = (props) => (event) => {
    props(event.target.value.sort((a, b) => (a.dayId > b.dayId ? 1 : -1)));
  };

  const handleChange = (props) => (event) => {
    // console.log("event ====", event.target.value, TimeZoneOn1Modal);
    props(event.target.value);
  };
  const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;
  const handleSubmit = async (event) => {
    // const str = daySelectedOn1Modal.map((day) => day.dayId)
    // console.log(str.toString())
    event.preventDefault();

    if (title == "Timer Switch 1") {
      try {
        const result = await axios.post(ModelaRestURL + "/api/socket/timer1", {
          deviceName: token,
          blynkToken: blynkToken,
          dateSelectOn1: daySelectedOn1Modal
            .map((days) => days.dayId)
            .join(","),
          startOn1At: stateTimer.HoursOn1 * 3600 + stateTimer.MinuteOn1 * 60,
          tzOn1At: "25200",
          timezoneOn1: "Asia/Bangkok",
          pinOn1: "v21",

          startOff1At: stateTimer.HoursOff1 * 3600 + stateTimer.MinuteOff1 * 60,
          timezoneOff1: "Asia/Bangkok",
          dateSelectOff1: daySelectedOff1Modal
            .map((days) => days.dayId)
            .join(","),
          tzOff1At: "25200",
          pinOff1: "v22",

          startOn2At: stateTimer.HoursOn2 * 3600 + stateTimer.MinuteOn2 * 60,
          timezoneOn2: "Asia/Bangkok",
          dateSelectOn2: daySelectedOn2Modal
            .map((days) => days.dayId)
            .join(","),
          tzOn2At: "25200",
          pinOn2: "v23",

          startOff2At: stateTimer.HoursOff2 * 3600 + stateTimer.MinuteOff2 * 60,
          timezoneOff2: "Asia/Bangkok",
          dateSelectOff2: daySelectedOff2Modal
            .map((days) => days.dayId)
            .join(","),
          tzOff2At: "25200",
          pinOff2: "v24",

          startOn3At: stateTimer.HoursOn3 * 3600 + stateTimer.MinuteOn3 * 60,
          timezoneOn3: "Asia/Bangkok",
          dateSelectOn3: daySelectedOn3Modal
            .map((days) => days.dayId)
            .join(","),
          tzOn3At: "25200",
          pinOn3: "v25",

          startOff3At: stateTimer.HoursOff3 * 3600 + stateTimer.MinuteOff3 * 60,
          timezoneOff3: "Asia/Bangkok",
          dateSelectOff3: daySelectedOff3Modal
            .map((days) => days.dayId)
            .join(","),
          tzOff3At: "25200",
          pinOff3: "v26",

          slideTimeOn1: Number(states.checkOn1),
          pinSlideOn1: "v11",
          slideTimeOff1: Number(states.checkOff1),
          pinSlideOff1: "v12",
          slideTimeOn2: Number(states.checkOn2),
          pinSlideOn2: "v13",
          slideTimeOff2: Number(states.checkOff2),
          pinSlideOff2: "v14",
          slideTimeOn3: Number(states.checkOn3),
          pinSlideOn3: "v15",
          slideTimeOff3: Number(states.checkOff3),
          pinSlideOff3: "v16",
          timerOn1: "timerOn1Ax",
          timerOff1: "timerOff1Ax",
          timerOn2: "timerOn2Ax",
          timerOff2: "timerOff2Ax",
          timerOn3: "timerOn3Ax",
          timerOff3: "timerOff3Ax",
          switchTimerOn1: "switchTimerOn1Ax",
          switchTimerOff1: "switchTimerOff1Ax",
          switchTimerOn2: "switchTimerOff1Ax",
          switchTimerOff2: "switchTimerOff2Ax",
          switchTimerOn3: "switchTimerOn3Ax",
          switchTimerOff3: "switchTimerOff3Ax",
          schemaDaySelectedOn1: "daySelectedOn1Ax",
          schemaDaySelectedOff1: "daySelectedOff1Ax",
          schemaDaySelectedOn2: "daySelectedOn2Ax",
          schemaDaySelectedOff2: "daySelectedOff2Ax",
          schemaDaySelectedOn3: "daySelectedOn3Ax",
          schemaDaySelectedOff3: "daySelectedOff3Ax",
        });
        setDataSetting({
          ...dataSetting,
          ["daySelectedOn1Ax"]: daySelectedOn1Modal,
          ["daySelectedOn2Ax"]: daySelectedOn2Modal,
          ["daySelectedOn3Ax"]: daySelectedOn3Modal,
          ["daySelectedOff1Ax"]: daySelectedOff1Modal,
          ["daySelectedOff2Ax"]: daySelectedOff2Modal,
          ["daySelectedOff3Ax"]: daySelectedOff3Modal,
          ["timerOn1Ax"]:
            stateTimer.HoursOn1 * 3600 + stateTimer.MinuteOn1 * 60,
          ["timerOff1Ax"]:
            stateTimer.HoursOff1 * 3600 + stateTimer.MinuteOff1 * 60,
          ["timerOn2Ax"]:
            stateTimer.HoursOn2 * 3600 + stateTimer.MinuteOn2 * 60,
          ["timerOff2Ax"]:
            stateTimer.HoursOff2 * 3600 + stateTimer.MinuteOff2 * 60,
          ["timerOn3Ax"]:
            stateTimer.HoursOn3 * 3600 + stateTimer.MinuteOn3 * 60,
          ["timerOff3Ax"]:
            stateTimer.HoursOff3 * 3600 + stateTimer.MinuteOff3 * 60,
          ["switchTimerOn1Ax"]: states.checkOn1,
          ["switchTimerOn2Ax"]: states.checkOn2,
          ["switchTimerOn3Ax"]: states.checkOn3,
          ["switchTimerOff1Ax"]: states.checkOff1,
          ["switchTimerOff2Ax"]: states.checkOff2,
          ["switchTimerOff3Ax"]: states.checkOff3,
        });
        closeModal();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const result = await axios.post(ModelaRestURL + "/api/socket/timer1", {
          deviceName: token,
          blynkToken: blynkToken,
          dateSelectOn1: daySelectedOn1Modal
            .map((days) => days.dayId)
            .join(","),
          startOn1At: stateTimer.HoursOn1 * 3600 + stateTimer.MinuteOn1 * 60,
          tzOn1At: "25200",
          timezoneOn1: "Asia/Bangkok",
          pinOn1: "v71",

          startOff1At: stateTimer.HoursOff1 * 3600 + stateTimer.MinuteOff1 * 60,
          timezoneOff1: "Asia/Bangkok",
          dateSelectOff1: daySelectedOff1Modal
            .map((days) => days.dayId)
            .join(","),
          tzOff1At: "25200",
          pinOff1: "v72",

          startOn2At: stateTimer.HoursOn2 * 3600 + stateTimer.MinuteOn2 * 60,
          timezoneOn2: "Asia/Bangkok",
          dateSelectOn2: daySelectedOn2Modal
            .map((days) => days.dayId)
            .join(","),
          tzOn2At: "25200",
          pinOn2: "v73",

          startOff2At: stateTimer.HoursOff2 * 3600 + stateTimer.MinuteOff2 * 60,
          timezoneOff2: "Asia/Bangkok",
          dateSelectOff2: daySelectedOff2Modal
            .map((days) => days.dayId)
            .join(","),
          tzOff2At: "25200",
          pinOff2: "v74",

          startOn3At: stateTimer.HoursOn3 * 3600 + stateTimer.MinuteOn3 * 60,
          timezoneOn3: "Asia/Bangkok",
          dateSelectOn3: daySelectedOn3Modal
            .map((days) => days.dayId)
            .join(","),
          tzOn3At: "25200",
          pinOn3: "v75",

          startOff3At: stateTimer.HoursOff3 * 3600 + stateTimer.MinuteOff3 * 60,
          timezoneOff3: "Asia/Bangkok",
          dateSelectOff3: daySelectedOff3Modal
            .map((days) => days.dayId)
            .join(","),
          tzOff3At: "25200",
          pinOff3: "v76",

          slideTimeOn1: Number(states.checkOn1),
          pinSlideOn1: "v61",
          slideTimeOff1: Number(states.checkOff1),
          pinSlideOff1: "v62",
          slideTimeOn2: Number(states.checkOn2),
          pinSlideOn2: "v63",
          slideTimeOff2: Number(states.checkOff2),
          pinSlideOff2: "v64",
          slideTimeOn3: Number(states.checkOn3),
          pinSlideOn3: "v65",
          slideTimeOff3: Number(states.checkOff3),
          pinSlideOff3: "v66",
          timerOn1: "timerOn1Bx",
          timerOff1: "timerOff1Bx",
          timerOn2: "timerOn2Bx",
          timerOff2: "timerOff2Bx",
          timerOn3: "timerOn3Bx",
          timerOff3: "timerOff3Bx",
          switchTimerOn1: "switchTimerOn1Bx",
          switchTimerOff1: "switchTimerOff1Bx",
          switchTimerOn2: "switchTimerOff1Bx",
          switchTimerOff2: "switchTimerOff2Bx",
          switchTimerOn3: "switchTimerOn3Bx",
          switchTimerOff3: "switchTimerOff3Bx",
          schemaDaySelectedOn1: "daySelectedOn1Bx",
          schemaDaySelectedOff1: "daySelectedOff1Bx",
          schemaDaySelectedOn2: "daySelectedOn2Bx",
          schemaDaySelectedOff2: "daySelectedOff2Bx",
          schemaDaySelectedOn3: "daySelectedOn3Bx",
          schemaDaySelectedOff3: "daySelectedOff3Bx",
        });
        setDataSetting({
          ...dataSetting,
          ["daySelectedOn1Bx"]: daySelectedOn1Modal,
          ["daySelectedOn2Bx"]: daySelectedOn2Modal,
          ["daySelectedOn3Bx"]: daySelectedOn3Modal,
          ["daySelectedOff1Bx"]: daySelectedOff1Modal,
          ["daySelectedOff2Bx"]: daySelectedOff2Modal,
          ["daySelectedOff3Bx"]: daySelectedOff3Modal,
          ["timerOn1Bx"]:
            stateTimer.HoursOn1 * 3600 + stateTimer.MinuteOn1 * 60,
          ["timerOff1Bx"]:
            stateTimer.HoursOff1 * 3600 + stateTimer.MinuteOff1 * 60,
          ["timerOn2Bx"]:
            stateTimer.HoursOn2 * 3600 + stateTimer.MinuteOn2 * 60,
          ["timerOff2Bx"]:
            stateTimer.HoursOff2 * 3600 + stateTimer.MinuteOff2 * 60,
          ["timerOn3Bx"]:
            stateTimer.HoursOn3 * 3600 + stateTimer.MinuteOn3 * 60,
          ["timerOff3Bx"]:
            stateTimer.HoursOff3 * 3600 + stateTimer.MinuteOff3 * 60,
          ["switchTimerOn1Bx"]: states.checkOn1,
          ["switchTimerOn2Bx"]: states.checkOn2,
          ["switchTimerOn3Bx"]: states.checkOn3,
          ["switchTimerOff1Bx"]: states.checkOff1,
          ["switchTimerOff2Bx"]: states.checkOff2,
          ["switchTimerOff3Bx"]: states.checkOff3,
        });
        closeModal();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const TimerSelect = ({ type, inputName, value }) => {
    const renderOptions = () => {
      let j;
      if(type === "Hours"){ j = 23; }
      else if(type === "Minutes"){ j = 59; }
      
      const options = [];
      for (let i = 0; i <= j; i++) {
        const formattedValue = String(i).padStart(2, "0");
        options.push(
          <MenuItem className={classes.menuItem} key={i} value={i}>
            {formattedValue}
          </MenuItem>
        );
      }
      return options;
    };

    return (
      <Grid item lg={4} xl={3} md={4}>
        <Select
          variant="outlined"
          MenuProps={MenuProps}
          classes={{
            root: classes.selectTime,
            icon: classes.icon,
            selectMenu: classes.active,
          }}
          className={classes.selectTime}
          inputProps={{
            root: classes.selectTime,
            selectMenu: classes.active,
          }}
          name={inputName}
          value={value}
          onChange={handleChangesTimer}
        >
          {renderOptions()}
        </Select>
      </Grid>
    );
  };

  const renderTimerRow = (label1, switchValue, switchName, handleSwitchChange, timerHourValue, timerHourName, timerMinValue, timerMinName, daysSelected, handleDaySelect, dataDay, TimeZoneValue, handleTimeZoneSelect, DataTimeZone, isGreen) => (
    <>
      <Grid item lg={2} className={globalStyle.flexCenter}>
        <Typography>Active Timer Switch </Typography>
      </Grid>

      <Grid item lg={3} className={globalStyle.flexCenter}>
        <Typography>Setting Timer Switch {label1} </Typography>
      </Grid>
      <Grid item lg={3} className={globalStyle.flexStart}>
        <Typography>Select Days </Typography>
      </Grid>
      <Grid item lg={4} className={globalStyle.flexStart}>
        <Typography>Time Zone</Typography>
      </Grid>
      <Grid item lg={2} className={`${globalStyle.flexCenter} ${globalStyle.paddingL2}`}>
        <Typography>{label1} </Typography>
        {isGreen ? (
          <GreenSwitchIos checked={switchValue} handleChange={handleSwitchChange} name={switchName}/>
        ) : (
          <SwitchIosRed checked={switchValue} handleChange={handleSwitchChange} name={switchName}/>
        )}
      </Grid>
      <Grid item lg={3} className={globalStyle.flexCenter}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <TimerSelect
            type="Hours"
            inputName={timerHourName}
            value={timerHourValue}
          />
          <Grid item lg={1}>
            <Typography className={classes.textcenter}> :</Typography>
          </Grid>
          <TimerSelect
            type="Minutes"
            inputName={timerMinName}
            value={timerMinValue}
          />
        </Grid>
      </Grid>
      <Grid item lg={3} className={globalStyle.flexCenter}>
        <FormControl
          variant="outlined"
          classes={{ root: classes.select }}
          fullWidth
          className={classes.textfield}
        >
          {/* <InputLabel
            className={classes.select}
            id="demo-simple-select-outlined-label"
          >
            Select Days
          </InputLabel> */}
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={daysSelected}
            multiple
            classes={{
              root: classes.select,
              icon: classes.select,
            }}
            className={classes.select}
            renderValue={(selected) =>
              selected.map((days) => days.d).join(", ")
            }
            onChange={handleChangeDaySelect(handleDaySelect)}
            label="Select"
          >
            {dataDay.map((day, idx) => (
              <MenuItem key={day} value={day}>
                <Checkbox
                  checkedIcon={<span className={classes.checkedIcon} />}
                  className={classes.checkbox}
                  checked={daysSelected.indexOf(day) > -1}
                />
                <ListItemText primary={day.day} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={4} className={globalStyle.flexCenter}>
        <FormControl
          variant="outlined"
          classes={{ root: classes.select }}
          fullWidth
          className={classes.textfield}
        >
          {/* <InputLabel
            className={classes.select}
            id="demo-simple-select-outlined-label"
          >
            TimeZone
          </InputLabel> */}
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={TimeZoneValue}
            classes={{
              root: classes.select,
              icon: classes.select,
            }}
            className={classes.select}
            onChange={handleChange(handleTimeZoneSelect)}
            label="Select"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {DataTimeZone.map((tz, idx) => (
              <MenuItem value={tz}>{tz.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
      className={globalStyle.modal1}
      PaperProps={{
        className: globalStyle.modal,
      }}
    >
      <DialogTitle id="form-dialog-title" component="h1">
        {title}
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          {renderTimerRow(
            "on1",
            states.checkOn1,
            "checkOn1",
            handleChanges,
            stateTimer.HoursOn1,
            "HoursOn1",
            stateTimer.MinuteOn1,
            "MinuteOn1",
            daySelectedOn1Modal,
            setDaySelectedOn1Modal,
            dataDay,
            TimeZoneOn1Modal,
            setTimeZoneOn1Modal,
            TimeZone,
            true
          )}
          {renderTimerRow(
            "off1",
            states.checkOff1,
            "checkOff1",
            handleChanges,
            stateTimer.HoursOff1,
            "HoursOff1",
            stateTimer.MinuteOff1,
            "MinuteOff1",
            daySelectedOff1Modal,
            setDaySelectedOff1Modal,
            dataDay,
            TimeZoneOff1Modal,
            setTimeZoneOff1Modal,
            TimeZone,
            false
          )}
          {renderTimerRow(
            "on2",
            states.checkOn2,
            "checkOn2",
            handleChanges,
            stateTimer.HoursOn2,
            "HoursOn2",
            stateTimer.MinuteOn2,
            "MinuteOn2",
            daySelectedOn2Modal,
            setDaySelectedOn2Modal,
            dataDay,
            TimeZoneOn2Modal,
            setTimeZoneOn2Modal,
            TimeZone,
            true
          )}
          {renderTimerRow(
            "off2",
            states.checkOff2,
            "checkOff2",
            handleChanges,
            stateTimer.HoursOff2,
            "HoursOff2",
            stateTimer.MinuteOff2,
            "MinuteOff2",
            daySelectedOff2Modal,
            setDaySelectedOff2Modal,
            dataDay,
            TimeZoneOff2Modal,
            setTimeZoneOff2Modal,
            TimeZone,
            false
          )}
          {renderTimerRow(
            "on3",
            states.checkOn3,
            "checkOn3",
            handleChanges,
            stateTimer.HoursOn3,
            "HoursOn3",
            stateTimer.MinuteOn3,
            "MinuteOn3",
            daySelectedOn3Modal,
            setDaySelectedOn3Modal,
            dataDay,
            TimeZoneOn3Modal,
            setTimeZoneOn3Modal,
            TimeZone,
            true
          )}
          {renderTimerRow(
            "off3",
            states.checkOff3,
            "checkOff3",
            handleChanges,
            stateTimer.HoursOff3,
            "HoursOff3",
            stateTimer.MinuteOff3,
            "MinuteOff3",
            daySelectedOff3Modal,
            setDaySelectedOff3Modal,
            dataDay,
            TimeZoneOff3Modal,
            setTimeZoneOff3Modal,
            TimeZone,
            false
          )}
        </Grid>

        {error && <p className={classes.p}>{error}</p>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          className={classes.finish}
          color="primary"
          size="large"
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAvailableModal;