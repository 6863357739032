import React, { useState } from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 52,
    height: 26,
    padding: 0,
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(0),
  },
  label: {
    margin: theme.spacing(4),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(27px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function SwitchIos(props) {
  // const [state, setState] = React.useState({
  //   checkedA: true,
  //   checkedB: true,
  //   checkedC: true,
  // });
  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };
  return (
    <React.Fragment>
      <FormControlLabel
        control={
          <IOSSwitch
            checked={props.checked}
            onChange={props.handleChange}
            name={props.name}
          />
        }
        // label="Manual Auto"
        // labelPlacement="top"
        // label="iOS style"
      />
    </React.Fragment>
  );
}

export default SwitchIos;

/////////////////////////////////////////////   Red     //////////////////////////////////////////////////////

const IOSSwitchGreen = withStyles((theme) => ({
  root: {
    width: 52,
    height: 26,
    padding: 0,
    margin: theme.spacing(3),
  },
  label: {
    margin: theme.spacing(4),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(27px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function GreenSwitchIos(props) {
  // const [state, setState] = React.useState({
  //   checkedA: true,
  //   checkedB: true,
  //   checkedC: true,
  // });
  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };
  return (
    <React.Fragment>
      <FormControlLabel
        control={
          <IOSSwitchGreen
            checked={props.checked}
            onChange={props.handleChange}
            name={props.name}
          />
        }
        // label="Manual Auto"
        // labelPlacement="top"
        // label="iOS style"
      />
    </React.Fragment>
  );
}

export { GreenSwitchIos };

const RedIOSSwitch = withStyles((theme) => ({
  root: {
    width: 52,
    height: 26,
    padding: 0,
    margin: theme.spacing(3),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(27px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#FC5656",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#FC5656",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function SwitchIosRed(props) {
  // const [state, setState] = React.useState({
  //   checkedA: true,
  //   checkedB: true,
  //   checkedC: true,
  // });
  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };
  return (
    <React.Fragment>
      <FormControlLabel
        control={
          <RedIOSSwitch
            checked={props.checked}
            onChange={props.handleChange}
            name={props.name}
          />
        }
        // label="iOS style"
      />
    </React.Fragment>
  );
}

export { SwitchIosRed };

//////////////////////////////////////////////    Small Green    ///////////////////////////////////////////

const IOSSwitch1 = withStyles((theme) => ({
  root: {
    width: 42,
    height: 21,
    padding: 1,
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(21px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function SwitchIosSmallGreen(props) {
  // const [state, setState] = React.useState({
  //   checkedA: true,
  //   checkedB: true,
  //   checkedC: true,
  // });
  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };
  return (
    <React.Fragment>
      <FormControlLabel
        control={
          <IOSSwitch1
            checked={props.checked}
            onChange={props.handleChange}
            name={props.name}
          />
        }
        // label="iOS style"
      />
    </React.Fragment>
  );
}

export { SwitchIosSmallGreen };

////////////////////////////////////////////////   Small Red   /////////////////////////////////////////////

const IOSSwitchRed = withStyles((theme) => ({
  root: {
    width: 42,
    height: 21,
    padding: 1,
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(21px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#FC5656",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#FC5656",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function SwitchIosSmallRed(props) {
  // const [state, setState] = React.useState({
  //   checkedA: true,
  //   checkedB: true,
  //   checkedC: true,
  // });
  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };
  return (
    <React.Fragment>
      <FormControlLabel
        control={
          <IOSSwitchRed
            checked={props.checked}
            onChange={props.handleChange}
            name={props.name}
          />
        }
        // label="iOS style"
      />
    </React.Fragment>
  );
}

export { SwitchIosSmallRed };
