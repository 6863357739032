import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  DialogActions,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  FormLabel,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Switch,
  Typography,
  Grid,
  Input,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  textfield: {
    margin: theme.spacing(0, 0, 2),
  },
  p: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#B0B0B0",
    fontSize: "17px",
    marginTop: 0,
  },
  modal: {
    backgroundColor: "#59585e",
    color: "#fff",
    // width: "100%",
    minWidth: "68vh",
  },
  modal1: {
    // width:"50%"
  },
  select: {
    color: "#fff",
    backgroundColor: "#45444a",
    borderRadius: "30px",
  },
  div: {
    // marginTop: theme.spacing(5),
    // padding: "35px 10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  img: {
    height: "30vmin",
  },
  finish: {
    color: "#F29C19",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 0,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ConnectLine = ({ open, handleClose, qrcode, url }) => {
  const classes = useStyle();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.modal1}
      PaperProps={{
        className: classes.modal,
      }}
    >
      <DialogTitle id="form-dialog-title">
        <h2 className={classes.finish}>Connect Line Notification</h2>
        <p className={classes.p}>Please scan or click on QR code to connect</p>
      </DialogTitle>
      <DialogContent>
        <div className={classes.div}>
          <Button className={classes.img} href={url} target="_blank">
            <img className={classes.img} src={qrcode} />
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className={classes.finish} size="large">
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConnectLine;
