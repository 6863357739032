module.exports = [
    {
      name: "None",
      value: 0,
    },
    {
      name: "ไม่ควบคุม",
      value: 1,
    },
    {
      name: "1",
      value: 2,
    },
    {
      name: "2",
      value: 3,
    },
    {
      name: "1,2",
      value: 4,
    },
    {
      name: "แจ้งเตือนด้วยข้อความเท่านั้น",
      value: 5,
    },
    {
      name: "1 ในช่วงเวลาตั้งเวลาสวิตช์ 1",
      value: 6,
    },
    {
      name: "2 ในช่วงเวลาตั้งเวลาสวิตช์ 2",
      value: 7,
    },
    {
      name: "1,2 ช่วงเวลาตั้งเวลาสวิตช์ 1,2",
      value: 8,
    },
  ]