module.exports = [
  {
    day: "จันทร์",
    dayId: "1",
    d: "จ",
  },
  {
    day: "อังคาร",
    dayId: "2",
    d: "อ",
  },
  {
    day: "พุธ",
    dayId: "3",
    d: "พ",
  },
  {
    day: "พฤหัส",
    dayId: "4",
    d: "พฤ",
  },
  {
    day: "ศุกร์",
    dayId: "5",
    d: "ศ",
  },
  {
    day: "เสาร์",
    dayId: "6",
    d: "ส",
  },
  {
    day: "อาทิตย์",
    dayId: "7",
    d: "อา",
  },
];
