import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

const useStyles = makeStyles((theme) => {
  return {
    div: {
      padding: theme.spacing(3),
    },
    paper: {
      backgroundColor: "#59585e",
      borderRadius: "10px",
      color: "#fff",
      padding: theme.spacing(2),
    },
    select: {
      color: "#fff",
      backgroundColor: "#45444a",
      borderRadius: "20px",
    },
    label:{
        color: "#fff",
    },
    submit: {
      background: "linear-gradient(45deg, #F29C19 30%, #fbb116 90%)",
      borderRadius: "3px",
      boxShadow: "0 2px 3px 1px rgba(195, 155, 245, .3)",
      color: "white",
      fontSize: "15px",
      marginTop: theme.spacing(1),
    },
    textfield: {
      margin: theme.spacing(0, 0, 2),
    },
  };
});

function ExportDB() {
  const classes = useStyles();
  const [selectedCollection, setSelectedCollection] = useState("");
  const [collections, setCollections] = useState([]);
  const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;

  useEffect(() => {
    getCollections();
  }, []);

  const getCollections = async () => {
    try {
      const response = await axios.get(
        ModelaRestURL + "/api/socket/getcollection"
      );
      if (response.status === 200) {
        setCollections(response.data.collections);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCollectionChange = (event) => {
    setSelectedCollection(event.target.value);
  };

  const handleExport = async () => {
    try {
      const response = await axios.post(
        ModelaRestURL + "/api/socket/exportdb",
        {
          collection: selectedCollection,
        }
      );
      if (response.status === 200) {
        const jsonData = JSON.stringify(response.data, null, 2);

        const element = document.createElement("a");
        const file = new Blob([jsonData], { type: "application/json" });
        element.href = URL.createObjectURL(file);
  
  
        const currentDate = new Date().toLocaleString("en-US", {
          timeZone: "Asia/Bangkok",
        }); // ดึงวันที่และเวลาปัจจุบันในโซนเวลาของกรุงเทพฯ
        const formattedDate = currentDate
        .replace(",", "") // ลบเครื่องหมายคอมม่า
        .replace(/\//g, "-") // แทนที่เครื่องหมาย / ด้วย -
        .replace(/:/g, "-"); // แทนที่เครื่องหมาย : ด้วย -
      
        const filename = `Backup_${selectedCollection}_${formattedDate}.json`;
        element.download = filename;

        element.click();

        console.log("Export API request sent!");
      } else {
        console.error("Export failed");
      }
    } catch (error) {
      console.error("Export failed", error);
    }
  };

  return (
    <div className={classes.div}>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={12} md={8} lg={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>
              Select Collection
            </Typography>
            <FormControl
              variant="outlined"
              classes={{ root: classes.select }}
              className={classes.textfield}
              fullWidth
            >
              <InputLabel id="collection-label" className={classes.label}>
                Collection
              </InputLabel>
              <Select
                labelId="collection-label"
                id="collection"
                value={selectedCollection}
                onChange={handleCollectionChange}
                label="Collection"
                classes={{
                  root: classes.select,
                  icon: classes.select,
                }}
                className={classes.select}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {collections.map((collection) => (
                  <MenuItem key={collection} value={collection}>
                    {collection}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleExport}
              disabled={!selectedCollection}
              style={{ margin: "auto" }}
            >
              Export
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ExportDB;
