module.exports =[
    {
      name: "None",
      value: 0,
    },
    {
      name: "เปิดเมื่อเกินค่าควบคุม/ปิดเมื่อต่ำกว่า",
      value: 1,
    },
    {
      name: "ปิดเมื่อเกินค่าควบคุม/เปิดเมื่อต่ำกว่า",
      value: 2,
    },
    {
      name: "เปิดเมื่อเกินค่าควบคุมด้านสูง",
      value: 3,
    },
    {
      name: "ปิดเมื่อเกินค่าควบคุมด้านสูง",
      value: 4,
    },
    {
      name: "เปิดเมื่อน้อยกว่าค่าด้านต่ำ",
      value: 5,
    },
    {
      name: "ปิดเมื่อน้อยกว่าค่าด้านต่ำ",
      value: 6,
    },
    {
      name: "ไม่ควบคุม",
      value: 7,
    },
  ]
  