import "./App.css";
import {
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import React from "react";
import { makeStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import Nav from "./component/Nav";
import Sidebar from './component/backend/SideBar';
import Home from "./page/Home";
import Dashboard from "./page/Dashboard";
import Login from "./page/Login";
import Setting from "./page/Setting";
import ConnectLine from "./page/ConnectLine";
import ForgotPassword from "./page/ForgotPassword";
import Register from "./page/Register";
import ResetPassword from "./page/ResetPassword";
import Errorpage from "./page/Errorpage";
import AdminPanel from "./page/backend/AdminPanel";
import PrivateRoute from "./router/PrivateRoute";
import ContactUs from "./page/ContactUs";
import ExportCSV from "./page/ExportCSV";
import ExportDB from "./page/ExportDB";
import CompareChart from "./page/CompareChart";
import GenerateToken from "./page/GenerateToken";
import useGlobalStyle from "./component/style/globalStyle";

// Import For Test
import TestSocket from "./page/TestSocket";
// import PasswordGenerator from "./page/GeneratePass";
// import ChatApp from "./page/ChatAiTest";

import { io } from "socket.io-client";

const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;
const socket = io(ModelaRestURL, { 
  secure: true,
  reconnection: true, // เปิดใช้งานการ reconnect
  reconnectionAttempts: Infinity, // กำหนดจำนวนครั้งที่จะทำการ reconnect
  reconnectionDelay: 1000, // ระยะเวลาที่จะรอระหว่างการ retry
  reconnectionDelayMax: 5000, // ระยะเวลาที่จะรอระหว่างการ retry สูงสุด
}); // Server NodeJS

socket.on("connect", () => {
  console.log(`Connected to WebSocket server`);
});

// console log for all debug socket io
// socket.onAny((event, ...args) => {
//   console.log(event, args);
// });

socket.on("disconnect", () => {
  console.log(`Disconnected from WebSocket server`);
});

const useStyles = makeStyles({
  container: {
    // display: "flex",
    backgroundColor: "#353535",
    minHeight: "100vh",
  },
  containerLogin: {
    backgroundColor: "#000",
    minHeight: "100vh",
  }
});

const theme = createTheme({
  typography: {
    fontFamily: "'Prompt', sans-serif", // กำหนด 'Prompt'
  },
});

function App() {
  const classes = useStyles();
  const globalStyle = useGlobalStyle();
  return (
    <ThemeProvider theme={theme}>
      <div className={globalStyle.container}>
        <Routes>
          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/connect-line" element={<ConnectLine />} />
          <Route exact path="/connect-line/:id" element={<ConnectLine />} />

          <Route 
            path="/home"
            element={<PrivateRoute element={<Nav socket={socket} ><Home /></Nav>} />}
          />
          {/* <Route 
            path="/TestContent"
            element={<PrivateRoute element={<TestContent />} />}
          /> */}
          <Route 
            path="/contactus"
            element={<PrivateRoute element={<Nav socket={socket} ><ContactUs /></Nav>} />}
          />
          <Route
            path="/dashboard/:token"
            element={<PrivateRoute element={<Nav socket={socket} ><Dashboard socket={socket} /></Nav>} />}
          />
          <Route
            path="/setting"
            element={<PrivateRoute element={<Nav socket={socket} ><Setting /></Nav>} />}
          />
          <Route
            path="/generate"
            element={<PrivateRoute element={<Nav socket={socket} ><GenerateToken /></Nav>} />}
          />
          <Route
            path="/exportcsv"
            element={<PrivateRoute element={<Nav socket={socket} ><ExportCSV /></Nav>} />}
          />
          <Route
            path="/exportdb"
            element={<PrivateRoute element={<Nav socket={socket} ><ExportDB /></Nav>} />}
          />
          <Route
            path="/comparechart"
            element={<PrivateRoute element={<Nav socket={socket} ><CompareChart /></Nav>} />}
          />

          {/* Admin */}
          <Route
            path="/admin/manageUser"
            element={<PrivateRoute element={<Sidebar><AdminPanel /></Sidebar>} adminRequired={true} />}
          />
          <Route
            path="/admin/dashboard/:uid"
            element={<PrivateRoute element={<Sidebar><AdminPanel /></Sidebar>} adminRequired={true} />}
          />
          <Route
            path="/admin/dashboard/:uid/:token"
            element={<PrivateRoute element={<Sidebar><Dashboard socket={socket} /></Sidebar>} />}
          />
          <Route path="*" element={<Errorpage />} />
          
          {/* <Route path="/chat" element={<ChatApp />} /> */}
          <Route path="/testsocket" element={<TestSocket socket={socket} />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;