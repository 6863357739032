import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Container,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import useGlobalStyle from "../style/globalStyle";

const useStyle = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(3),
    textAlign: "center",
    fontSize: "1.5rem",
  },
  modal: {
    backgroundColor: "#59585e",
    color: "#fff",
    minWidth: "30%",
    maxWidth: "35%",
  },
  submit: {
    background: "linear-gradient(45deg, #F29C19 30%, #fbb116 90%)",
    borderRadius: "5px",
    boxShadow: "0 3px 5px 2px rgba(251, 177, 22, .3)",
    padding: "0.3rem 1rem",
    color: "white",
    fontSize: "1.2rem",
    margin: theme.spacing(5, 0, 2),
  },
  submitcancel: {
    background: "linear-gradient(45deg, #B4B4B4 30%, #9C9595 90%)",
    borderRadius: "5px",
    boxShadow: "0 3px 5px 2px rgba(251, 177, 22, .3)",
    padding: "0.3rem 1rem",
    color: "white",
    fontSize: "1.2rem",
    margin: theme.spacing(5, 0, 2),
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-around', // Distributes space evenly around items
    alignItems: 'center', // Vertically aligns items in the center
    marginTop: theme.spacing(2), // Adds margin at the top, adjust as needed

    '& > *': { // Applies styles to every direct child (i.e., buttons)
      margin: theme.spacing(1), // Adds margin around each button
    },

    // Optional: you can also add media queries for responsiveness
    '@media (max-width: 600px)': {
      flexDirection: 'column', // Stacks buttons vertically on smaller screens
    },
  },
  logo: {
    margin: "auto",
    height: "6vmin",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    border: "1px solid #000",
    textAlign: "center",
    fontSize: "22px",
    backgroundColor: "#45444a",
    padding: "8px",
    borderRadius: "4px",
    "&:focus": {
      borderColor: "#fbb116",
    },
    color: "#fff",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
  },
  error: {
    color: "red",
  },
  paperStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
}));

const DeleteDevice = ({
  closeModal,
  isModalOpen,
  dataSet,
  selectDeleteDevice,
  selectDeleteDeviceName,
}) => {
  const classes = useStyle();
  const globalStyle = useGlobalStyle();
  const [error, setError] = useState("");

  const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.delete(
        `${ModelaRestURL}/api/socket/DeleteDevice`,
        { data: dataSet.at(selectDeleteDevice) }
      );
      if (response.status == 200) {
          dataSet.splice(selectDeleteDevice, 1);
          closeModal();
      }
    } catch (error) {
      console.log(error);
      setError("An error occurred while deleting the device.");
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();
    closeModal();
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="delete-device-dialog-title"
      className={globalStyle.modal1}
      PaperProps={{
        className: globalStyle.modalXS,
      }}
    >
      <DialogContent>
        <Container className={classes.paperStyle}>
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <Typography className={classes.text}>
            Are you sure to delete "{selectDeleteDeviceName}"?
            </Typography>
            <div className={classes.buttonGroup}>
              <Button
                type="submit"
                variant="contained"
                className={classes.submit}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                onClick={handleCancel}
                className={classes.submitcancel}
              >
                Cancel
              </Button>
            </div>
          </form>
        </Container>

        {error && <Typography className={classes.error}>{error}</Typography>}
      </DialogContent>
    </Dialog>
  );
};

export default DeleteDevice;
