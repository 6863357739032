import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Dialog,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import ReactApexChart from "react-apexcharts";

const useStyles = makeStyles((theme) => {
  return {
    div: {
      padding: theme.spacing(3),
    },
    paper: {
      backgroundColor: "#59585e",
      borderRadius: "10px",
      color: "#fff",
      padding: theme.spacing(2),
    },
    select: {
      color: "#fff",
      backgroundColor: "#45444a",
      borderRadius: "20px",
    },
    label: {
      color: "#fff",
    },
    submit: {
      background: "linear-gradient(45deg, #F29C19 30%, #fbb116 90%)",
      borderRadius: "3px",
      boxShadow: "0 2px 3px 1px rgba(195, 155, 245, .3)",
      color: "white",
      fontSize: "15px",
      marginTop: theme.spacing(1),
    },
    textfield: {
      margin: theme.spacing(0, 0, 2),
    },
  };
});

const YellowCheckbox = withStyles({
  root: {
    color: '#f1c40f', // สีของ Checkbox ตัวเลือก
    '&$checked': {
      color: '#f1c40f', // สีของ Checkbox ที่ถูกเลือก
    },
    marginBottom: '0.7rem',
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function CompareChart() {
  const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;
  const classes = useStyles();
  const { currentUser } = useAuth();
  const [selectedDevice, setSelectedDevice] = useState("");
  const [devices, setDevice] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const menuValues = [
    { key: "1", text: "Value1" },
    { key: "2", text: "Value2" },
    { key: "3", text: "Value3" },
    { key: "4", text: "Value4" },
  ];

  const isOutsideRange = (day) => {
    if (focusedInput === "endDate") {
      return day.isAfter(moment().endOf("day"));
    }
    if (focusedInput === "startDate") {
      return day.isAfter(moment().endOf("day"));
    }
    return false;
  };

  useEffect(() => {
    getDevice();
  }, [currentUser]);

  const getDevice = async () => {
    try {
      axios
        .post(ModelaRestURL + "/api/socket/getDevice", {
          user: currentUser.email,
        })
        .then((data) => {
          setDevice(data.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
  };

  const handleValueToggle = (value) => () => {
    const currentIndex = selectedValues.indexOf(value);
    const newSelectedValues = [...selectedValues];

    if (currentIndex === -1) {
      newSelectedValues.push(value);
    } else {
      newSelectedValues.splice(currentIndex, 1);
    }

    setSelectedValues(newSelectedValues);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [series, setSeries] = useState([]);
  
  const processDataForSensor = (dataArray, sensorKey, labelKey, unitKey) => {
    dataArray.reverse(); // กลับข้อมูลให้เรียงจากวันที่ใหม่ไปเก่า เพื่อดึงชื่อ Sensor ล่าสุด
    const lastNonEmptyData = dataArray.find(item => item[labelKey] !== "");
    dataArray.reverse(); // กลับข้อมูลให้เรียงจากวันที่เก่าไปใหม่ เพื่อให้ข้อมูลเป็นตัวเลขล่าสุดอยู่ด้านหน้า
    const label = lastNonEmptyData[labelKey];
    const unit = lastNonEmptyData[unitKey];
    const seriesData = [];
  
    dataArray.forEach(item => {
      seriesData.push({
        x: new Date(item.updated_at).getTime(),
        y: item[sensorKey].toFixed(2),
      });
    });
  
    return { name: unit ? `${label} (${unit})` : label, data: seriesData };
  };

  const handleExport = () => {
    axios
      .post(
        ModelaRestURL + "/api/socket/export-chart-data",
        {
          key: selectedDevice,
          startDate: startDate ? startDate.format("YYYY-MM-DD") : null,
          endDate: endDate ? endDate.format("YYYY-MM-DD") : null,
          selectedValues: selectedValues,
        },
        { responseType: "json" }
      )
      .then((response) => {
        const dataArray = response.data;
        const newSeries = [];
  
        if (dataArray.some(item => item.hasOwnProperty('ValueSensorAXlcd') && item.ValueSensorAXlcd !== "")) {
          const sensorData = processDataForSensor(dataArray, 'ValueSensorAX', 'ValueSensorAXlcd', 'ValueSensorUnitAX');
          newSeries.push(sensorData);
        }
  
        if (dataArray.some(item => item.hasOwnProperty('SecondValueAXName') && item.SecondValueAXName !== "")) {
          const sensorData = processDataForSensor(dataArray, 'SecondValueAX', 'SecondValueAXName', '');
          newSeries.push(sensorData);
        }
  
        if (dataArray.some(item => item.hasOwnProperty('ValueSensorBXlcd') && item.ValueSensorBXlcd !== "")) {
          const sensorData = processDataForSensor(dataArray, 'ValueSensorBX', 'ValueSensorBXlcd', 'ValueSensorUnitBX');
          newSeries.push(sensorData);
        }
  
        if (dataArray.some(item => item.hasOwnProperty('SecondValueBXName') && item.SecondValueBXName !== "")) {
          const sensorData = processDataForSensor(dataArray, 'SecondValueBX', 'SecondValueBXName', '');
          newSeries.push(sensorData);
        }
  
        if (dataArray.some(item => item.hasOwnProperty('ValueSensorCXlcd') && item.ValueSensorCXlcd !== "")) {
          const sensorData = processDataForSensor(dataArray, 'ValueSensorCX', 'ValueSensorCXlcd', 'ValueSensorUnitCX');
          newSeries.push(sensorData);
        }
  
        if (dataArray.some(item => item.hasOwnProperty('SecondValueCXName') && item.SecondValueCXName !== "")) {
          const sensorData = processDataForSensor(dataArray, 'SecondValueCX', 'SecondValueCXName', '');
          newSeries.push(sensorData);
        }
  
        if (dataArray.some(item => item.hasOwnProperty('ValueSensorDXlcd') && item.ValueSensorDXlcd !== "")) {
          const sensorData = processDataForSensor(dataArray, 'ValueSensorDX', 'ValueSensorDXlcd', 'ValueSensorUnitDX');
          newSeries.push(sensorData);
        }
  
        if (dataArray.some(item => item.hasOwnProperty('SecondValueDXName') && item.SecondValueDXName !== "")) {
          const sensorData = processDataForSensor(dataArray, 'SecondValueDX', 'SecondValueDXName', '');
          newSeries.push(sensorData);
        }

        openDialog();
        setSeries(newSeries); // ตั้งค่า series ใหม่ให้กับ state
      })
      .catch((error) => {
        console.error("Error Chart:", error);
      });
  };

  const options = {
    chart: {
      type: "line",
      foreColor: "#fff", // Color of text on the chart
      background: "#353535", // Set the background color here
    },
    stroke: {
      curve: "smooth",
      width: 3, // Width of the line
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false, // Use local time
        style: {
          fontSize: "10px",
        },
      },
    },
    legend: {
      position: "top", // Position of the legend
      horizontalAlign: "right",
      floating: true,
      offsetY: -10,
      offsetX: -5,
    },
    title: {
      text: "Compare Chart",
      style: {
        color: "#FFF", // Adjust the color of the title text to make it visible on the dark background
      },
    },
  };

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  return (
    <div className={classes.div}>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={12} md={8} lg={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom align="center">
              Compare Chart
            </Typography>
            <FormControl
              variant="outlined"
              classes={{ root: classes.select }}
              className={classes.textfield}
              fullWidth
            >
              <DateRangePicker
                startDate={startDate}
                startDateId="startDateID"
                endDate={endDate}
                endDateId="endDateID"
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                isOutsideRange={isOutsideRange}
                showDefaultInputIcon
                showClearDates
                block
                displayFormat="YYYY/MM/DD" // ตั้งค่ารูปแบบวันที่ที่นี่
              />
            </FormControl>

            <FormControl
              variant="outlined"
              classes={{ root: classes.select }}
              className={classes.textfield}
              fullWidth
            >
              <InputLabel id="device-label" className={classes.label}>
                Devices
              </InputLabel>
              <Select
                labelId="device-label"
                id="device"
                value={selectedDevice}
                onChange={handleDeviceChange}
                label="Devices"
                classes={{
                  root: classes.select,
                  icon: classes.select,
                }}
                className={classes.select}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {devices.map((data) => (
                  <MenuItem key={data.key} value={data.key}>
                    {data.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="body3" gutterBottom>
            Select Values for Compare Chart
            </Typography>
            <FormGroup row>
              {menuValues.map((data) => (
                <FormControlLabel
                  key={data.key}
                  control={
                    <YellowCheckbox
                      checked={selectedValues.indexOf(data.key) !== -1}
                      onChange={handleValueToggle(data.key)}
                      name={data.key}
                    />
                  }
                  label={data.text}
                />
              ))}
            </FormGroup>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleExport}
              disabled={selectedDevice === "" || selectedValues.length < 1 || startDate === null || endDate === null}
              style={{ margin: "auto", marginBottom: "1rem" }}
            >
              View Chart
            </Button>
            <div>
              <small>
                1. กรณีมีการเปลี่ยน Sensor ระหว่างช่วงเวลาที่เลือก ระบบจะแสดงชื่อ Sensor ล่าสุดเท่านั้น ถ้าต้องการข้อมูลแบบละเอียดโปรดใช้เมนู "Export Data To CSV"
              </small>
              {/* <br />
              <small>
                2. กรุณาเลือกข้อมูลให้ครบถ้วนและเลือก Values อย่างน้อย 2 ตัวเลือกเพื่อแสดงผล Chart
              </small> */}
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={isDialogOpen} onClose={closeDialog} fullWidth maxWidth="lg">
        <DialogContent>
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height="550px"
            forceRender
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CompareChart;
