import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { Button, Dialog, DialogContent } from "@material-ui/core";

const RealtimeChart = ({
  ValueSensorData,
  SecondValueData,
  ValueSensorName,
  ValueSensorUnit,
  SecondValueName,
  sensorValueHighLimit,
  sensorValueLowLimit,
  ChartTitle,
}) => {
  // console.log(SecondValueName);
  const { token } = useParams();
  const [series, setSeries] = useState([
    { name: 'Sensor Name (Unit)', data: [], },
    { name: "SecondValue", data: [], },
    // Add more series as needed
  ]);
  useEffect(() => {
    const labelSensor = ValueSensorUnit ? `${ValueSensorName} (${ValueSensorUnit})` : ValueSensorName;
    const labelSecondValue = SecondValueName ? SecondValueName : "SecondValue";
    setSeries(prevSeries => [
      { ...prevSeries[0], name: labelSensor },
      { ...prevSeries[1], name: labelSecondValue },
      ...prevSeries.slice(2)
    ]);
  }, [ValueSensorName, ValueSensorUnit, SecondValueName]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const newSeries = series.map((s, index) => {
        let yValue;
        if (index === 0) {
          yValue = ValueSensorData.toFixed(2);
        } else if (index === 1) {
          yValue = SecondValueData.toFixed(2);
        }
  
        const newData = {
          x: Date.now(),
          y: yValue
        };

        let data = [...s.data, newData];
        if (data.length > 20) {
          // Keep only the last 10 data points
          data = data.slice(1);
        }

        return { ...s, data };
      });

      // console.log('newSeries');
      // console.log(newSeries);
      setSeries(newSeries);
    }, 5000);

    return () => clearInterval(interval);
  }, [series, ValueSensorData, SecondValueData]);

  useEffect(() => {
    setSeries([
      { name: 'Sensor Name (Unit)', data: [] },
      { name: "SecondValue", data: [] },
      // Add more series as needed
    ]);
  }, [token]);

  const options = {
    chart: {
      type: "line",
      foreColor: "#fff", // Color of text on the chart
      background: "#1E222B", // Set the background color here
    },
    colors: ["#FAB117", "#4c59ed"], // Colors for each series
    stroke: {
      curve: "smooth",
      width: 3, // Width of the line
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false, // Use local time
        style: {
          fontSize: "10px",
        },
      },
    },
    legend: {
      position: "top", // Position of the legend
      horizontalAlign: "right",
      floating: true,
      offsetY: -10,
      offsetX: -5,
    },
    title: {
      text: ChartTitle,
      style: {
        color: "#FFF", // Adjust the color of the title text to make it visible on the dark background
      },
    },
    annotations: {
      yaxis: [
        {
          y: sensorValueLowLimit,  // Value for Limit Low
          borderColor: '#C70039',
          label: {
            borderColor: '#fff',
            style: {
              color: '#fff',
              background: '#C70039',
              fontSize: "8px",
            },
            text: 'Low Limit',
          },
        },
        {
          y: sensorValueHighLimit,  // Value for Limit High
          borderColor: '#C70039',
          label: {
            borderColor: '#fff',
            style: {
              color: '#fff',
              background: '#C70039',
              fontSize: "8px",
            },
            text: 'High Limit',
          },
        },
      ],
    },
  };

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);
  // console.log(series);

  return (
    <div style={{ color: '#000' }}>
      <div
        style={{
          textAlign: "right",
          marginTop: "1rem",
          marginBottom: "0.5rem",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={openDialog}
          style={{ backgroundColor: "#f1c40f" }}
        >
          Fullscreen
        </Button>
      </div>

      <ReactApexChart options={options} series={series} type="line" />

      <Dialog open={isDialogOpen} onClose={closeDialog} fullWidth maxWidth="lg">
        <DialogContent>
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height="550px"
            forceRender
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RealtimeChart;
