import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, OutlinedInput, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useParams } from "react-router-dom";

// Import components
import Terminal from "../component/Terminal";
import SwitchIos from "../component/SwitchIos";
import StatusSensor from "../component/StatusSensor";
import TimerSetting from "../component/TimerSetting.js";
import ValueSensor from "../component/ValueSensor";
import RealtimeChart from "../component/RealtimeChart";
import RealtimeChartAll from "../component/RealtimeChartAll";
import RealtimeGauge from "../component/RealtimeGauge";

// Import API and UI components
import ValueOption from "../api/ui/ValueOption";
import ValueControl from "../api/ui/ValueControl";
import StatusOption from "../api/ui/StatusOption";
import StatusControl from "../api/ui/StatusControl";
import TimeZone from "../api/ui/TimeZone";
import DaySelect from "../api/ui/DaySelect";
import intervalSetting from "../api/ui/IntervalSetting";

// Import Styles
import useGlobalStyle from "../component/style/globalStyle.js";

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';

const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;
const useStyles = makeStyles((theme) => {
  return {
    margin1: {
      margin: theme.spacing(1),
    },
    div: {
      padding: theme.spacing(3),
    },
    container: {
      backgroundColor: "#353535",
      minHeight: "100vh",
    },
    textValueSensor: {
      textAlign: "center",
      marginBottom: theme.spacing(1),
    },
    textSwitchHead: {
      fontSize: "1.3rem",
      fontWeight: "500",
      textAlign: "center",
      marginBottom: theme.spacing(1),
    },
    textSwitch: {
      fontSize: "1rem",
      textAlign: "center",
      // marginBottom: theme.spacing(1),
    },
    switchDeley: {
      width: "110px",
      height: "110px",
      cursor: "pointer",
      padding: theme.spacing(1),
    },
    paper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#59585e",
      borderRadius: "10px",
      color: "#fff",
      height: "100%",
    },
    papershowtoken: {
      backgroundColor: "#59585e",
      borderRadius: "10px",
      color: "#fff",
      height: "100%",
      textAlign: "center",
    },
    textfieldStatus: {
      backgroundColor: "#45444a",
      borderRadius: "30px",
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(3),
      width: "80%",
    },
    multilineColor: {
      color: "#fff",
      borderRadius: "30px",
      height: "35px",
      borderColor: "#45444a",
    },
    showpass: {
      position: "absolute",
      right: "0",
      top: "4px",
      padding: "7px 10px",
      marginRight: "5px",
      marginTop: "12px",
      fontSize: "12px",
      fontWeight: "600",
      color: "#fff",
      backgroundColor: "#fbb116",
      borderRadius: "5px",
      cursor: "pointer",
    },
    input: {
      border: "1px solid #45444a",
      fontSize: "14px",
      backgroundColor: "#45444a",
      borderRadius: "0.5rem",
      "&:focus": {
        borderColor: "#fbb116",
      },
      color: "#fff",
      margin: "10px",
      cursor: "default",
      minWidth: "35%",
      maxWidth: "50%",
    },
    inputprops: {
      textAlign: "center",
      margin: theme.spacing(-1),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      cursor: "default",
    },
    topicpaper: {
      textAlign: "center",
    },
    imglight: {
      margin: theme.spacing(1),
    },
    grid: {
      textAlign: "center",
    },
    grid1: {
      alignItems: "right",
    },
    column: {
      flexBasis: "33.33%",
    },
    two: {
      alignItems: "center",
      position: "relative",
      marginRight: theme.spacing(3),
    },
    gridFlexCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    textfieldTokenInput: {
      color: '#FFF',
      borderRadius: "0.5rem",
      borderColor: '#1E222B',
      textAlign: "center",
      margin: theme.spacing(-1),
    },
  };
});

function Dashboard({ socket }) {
  const classes = useStyles();
  const globalStyle = useGlobalStyle();
  const [passShow, setPassShow] = useState(false);
  const { token } = useParams();
  const [oldToken, setOldToken] = useState("");
  const [blynkToken, setBlynkToken] = useState();
  const [menuData, setMenuData] = useState({
    menuValueSensor1: [],
    menuValueSensor2: [],
    menuValueSensor3: [],
    menuValueSensor4: [],
    menuStatusSensor1: [],
    menuStatusSensor2: [],
  });

  const [copyText, setCopyText] = useState('Copy');
  const copyToken = () => {
    navigator.clipboard.writeText(`tkn:${token}`); // คัดลอกข้อความไปยัง Clipboard
    setCopyText('Copied');
    setTimeout(() => {
      setCopyText('Copy');
    }, 3000); // 3 วินาที
  };

  const getMenuData = async () => {
    try {
      const response = await axios.post(`${ModelaRestURL}/api/socket/getmenu`, { key: token });
      const data = response.data;
  
      const findMenuItem = (menuName) => data.find((item) => item.menu_Name === menuName)?.menu_Item;
  
      const menuStatusSensor1 = findMenuItem("statusSensor1");
      const menuStatusSensor2 = findMenuItem("statusSensor2");
      const menuValueSensor1 = findMenuItem("valueSensor1");
      const menuValueSensor2 = findMenuItem("valueSensor2");
      const menuValueSensor3 = findMenuItem("valueSensor3");
      const menuValueSensor4 = findMenuItem("valueSensor4");
  
      setMenuData({
        menuStatusSensor1,
        menuStatusSensor2,
        menuValueSensor1,
        menuValueSensor2,
        menuValueSensor3,
        menuValueSensor4,
      });

      if(data.length >= 6) {
        getSettingSteaming();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [dataValue, setDataValue] = useState({
    ValueSensorAX: 0,
    ValueSensorBX: 0,
    ValueSensorCX: 0,
    ValueSensorDX: 0,
    SecondValueAX: 0,
    SecondValueBX: 0,
    SecondValueCX: 0,
    SecondValueDX: 0,
    StatusSensorAX: 0,
    StatusSensorBX: 0,
    ValueSensorAXlcd: "Sensor Name",
    ValueSensorUnitAX: "Unit",
    ValueSensorBXlcd: "Sensor Name",
    ValueSensorUnitBX: "Unit",
    ValueSensorCXlcd: "Sensor Name",
    ValueSensorUnitCX: "Unit",
    ValueSensorDXlcd: "Sensor Name",
    ValueSensorUnitDX: "Unit",
    SecondValueAXName: "SecondValue",
    SecondValueBXName: "SecondValue",
    SecondValueCXName: "SecondValue",
    SecondValueDXName: "SecondValue",
  });

  const [dataSetting, setDataSetting] = useState(intervalSetting);

  const [state, setState] = useState({
    switchDeley1: 0,
    switchDeley2: 0,
    delaySwitchOption1: 0,
    delaySwitchOption2: 0,
  });

  const getDevice = async () => {
    try {
      const response = await axios.get(`${ModelaRestURL}/api/socket/getDevice/${token}`);
      if (response.data[0]) {
        setBlynkToken(response.data[0].tokenBlynkKey);
      } else {
        setBlynkToken();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDataSteaming = async () => {
    try {
      const response = await axios.get(`${ModelaRestURL}/api/socket/getDataSteaming/${token}`);
      const defaultDataValue = {
        ValueSensorAX: 0,
        ValueSensorBX: 0,
        ValueSensorCX: 0,
        ValueSensorDX: 0,
        SecondValueAX: 0,
        SecondValueBX: 0,
        SecondValueCX: 0,
        SecondValueDX: 0,
        StatusSensorAX: 0,
        StatusSensorBX: 0,
        ValueSensorAXlcd: "Sensor Name",
        ValueSensorUnitAX: "Unit",
        ValueSensorBXlcd: "Sensor Name",
        ValueSensorUnitBX: "Unit",
        ValueSensorCXlcd: "Sensor Name",
        ValueSensorUnitCX: "Unit",
        ValueSensorDXlcd: "Sensor Name",
        ValueSensorUnitDX: "Unit",
        SecondValueAXName: "SecondValue",
        SecondValueBXName: "SecondValue",
        SecondValueCXName: "SecondValue",
        SecondValueDXName: "SecondValue",
      };
      const responseData = response.data[0] || defaultDataValue;
      setDataValue(responseData);
    } catch (error) {
      console.error(error);
    }
  };

  const getSettingSteaming = async () => {
    if (menuData.menuValueSensor4.length > 0) {
      try {
        const responseSetting = await axios.get(
          `${ModelaRestURL}/api/socket/getDataSetting/${token}`
        );
        if (responseSetting.data[0]) {
          const filterSensorValue1 = menuData.menuValueSensor1.find(
            (item) => item.value === responseSetting.data[0].sensorValue1
          );
          const filteredSensorValue1Option = ValueOption.find(
            (item) => item.value === responseSetting.data[0].sensorValue1Option
          );
          const filteredSensorValue1Control = ValueControl.find(
            (item) => item.value === responseSetting.data[0].sensorValue1Control
          );
  
          const filterSensorValue2 = menuData.menuValueSensor2.find(
            (item) => item.value === responseSetting.data[0].sensorValue2
          );
          const filteredSensorValue2Option = ValueOption.find(
            (item) => item.value === responseSetting.data[0].sensorValue2Option
          );
          const filteredSensorValue2Control = ValueControl.find(
            (item) => item.value === responseSetting.data[0].sensorValue2Control
          );
  
          const filterSensorValue3 = menuData.menuValueSensor3.find(
            (item) => item.value === responseSetting.data[0].sensorValue3
          );
          const filteredSensorValue3Option = ValueOption.find(
            (item) => item.value === responseSetting.data[0].sensorValue3Option
          );
          const filteredSensorValue3Control = ValueControl.find(
            (item) => item.value === responseSetting.data[0].sensorValue3Control
          );
  
          const filterSensorValue4 = menuData.menuValueSensor4.find(
            (item) => item.value === responseSetting.data[0].sensorValue4
          );
          const filteredSensorValue4Option = ValueOption.find(
            (item) => item.value === responseSetting.data[0].sensorValue4Option
          );
          const filteredSensorValue4Control = ValueControl.find(
            (item) => item.value === responseSetting.data[0].sensorValue4Control
          );
  
          const filterStatusValue1 = menuData.menuStatusSensor1.find(
            (item) => item.value === responseSetting.data[0].statusValue1
          );
          const filteredStatusValue1Option = StatusOption.find(
            (item) => item.value === responseSetting.data[0].statusValue1Option
          );
          const filteredStatusValue1Control = StatusControl.find(
            (item) => item.value === responseSetting.data[0].statusValue1Control
          );
  
          const filterStatusValue2 = menuData.menuStatusSensor2.find(
            (item) => item.value === responseSetting.data[0].statusValue2
          );
          const filteredStatusValue2Option = StatusOption.find(
            (item) => item.value === responseSetting.data[0].statusValue2Option
          );
          const filteredStatusValue2Control = StatusControl.find(
            (item) => item.value === responseSetting.data[0].statusValue2Control
          );
  
          const filterTimezone = TimeZone.find(
            (item) => item.value === dataSetting.timeZoneOn1Ax
          );
  
          const arrayDaySelectedOn1Ax =
            responseSetting.data[0].daySelectedOn1Ax.split(",");
          const filterDaySelectedOn1Ax = DaySelect.filter((obj) =>
            arrayDaySelectedOn1Ax.includes(obj.dayId)
          );
          const arrayDaySelectedOff1Ax =
            responseSetting.data[0].daySelectedOff1Ax.split(",");
          const filterDaySelectedOff1Ax = DaySelect.filter((obj) =>
            arrayDaySelectedOff1Ax.includes(obj.dayId)
          );
  
          const arrayDaySelectedOn2Ax =
            responseSetting.data[0].daySelectedOn2Ax.split(",");
          const filterDaySelectedOn2Ax = DaySelect.filter((obj) =>
            arrayDaySelectedOn2Ax.includes(obj.dayId)
          );
          const arrayDaySelectedOff2Ax =
            responseSetting.data[0].daySelectedOff2Ax.split(",");
          const filterDaySelectedOff2Ax = DaySelect.filter((obj) =>
            arrayDaySelectedOff2Ax.includes(obj.dayId)
          );
  
          const arrayDaySelectedOn3Ax =
            responseSetting.data[0].daySelectedOn3Ax.split(",");
          const filterDaySelectedOn3Ax = DaySelect.filter((obj) =>
            arrayDaySelectedOn3Ax.includes(obj.dayId)
          );
          const arrayDaySelectedOff3Ax =
            responseSetting.data[0].daySelectedOff3Ax.split(",");
          const filterDaySelectedOff3Ax = DaySelect.filter((obj) =>
            arrayDaySelectedOff3Ax.includes(obj.dayId)
          );
  
          const arrayDaySelectedOn1Bx =
            responseSetting.data[0].daySelectedOn1Bx.split(",");
          const filterDaySelectedOn1Bx = DaySelect.filter((obj) =>
            arrayDaySelectedOn1Bx.includes(obj.dayId)
          );
          const arrayDaySelectedOff1Bx =
            responseSetting.data[0].daySelectedOff1Bx.split(",");
          const filterDaySelectedOff1Bx = DaySelect.filter((obj) =>
            arrayDaySelectedOff1Bx.includes(obj.dayId)
          );
  
          const arrayDaySelectedOn2Bx =
            responseSetting.data[0].daySelectedOn2Bx.split(",");
          const filterDaySelectedOn2Bx = DaySelect.filter((obj) =>
            arrayDaySelectedOn2Bx.includes(obj.dayId)
          );
          const arrayDaySelectedOff2Bx =
            responseSetting.data[0].daySelectedOff2Bx.split(",");
          const filterDaySelectedOff2Bx = DaySelect.filter((obj) =>
            arrayDaySelectedOff2Bx.includes(obj.dayId)
          );
  
          const arrayDaySelectedOn3Bx =
            responseSetting.data[0].daySelectedOn3Bx.split(",");
          const filterDaySelectedOn3Bx = DaySelect.filter((obj) =>
            arrayDaySelectedOn3Bx.includes(obj.dayId)
          );
          const arrayDaySelectedOff3Bx =
            responseSetting.data[0].daySelectedOff3Bx.split(",");
          const filterDaySelectedOff3Bx = DaySelect.filter((obj) =>
            arrayDaySelectedOff3Bx.includes(obj.dayId)
          );
  
          setDataSetting({
            ...dataSetting,
            ...responseSetting.data[0],
            sensorValue1: filterSensorValue1,
            sensorValue1Option: filteredSensorValue1Option,
            sensorValue1Control: filteredSensorValue1Control,
            sensorValue2: filterSensorValue2,
            sensorValue2Option: filteredSensorValue2Option,
            sensorValue2Control: filteredSensorValue2Control,
            sensorValue3: filterSensorValue3,
            sensorValue3Option: filteredSensorValue3Option,
            sensorValue3Control: filteredSensorValue3Control,
            sensorValue4: filterSensorValue4,
            sensorValue4Option: filteredSensorValue4Option,
            sensorValue4Control: filteredSensorValue4Control,
            statusValue1: filterStatusValue1,
            statusValue1Option: filteredStatusValue1Option,
            statusValue1Control: filteredStatusValue1Control,
            statusValue2: filterStatusValue2,
            statusValue2Option: filteredStatusValue2Option,
            statusValue2Control: filteredStatusValue2Control,
            timeZoneOn1Ax: filterTimezone,
            timeZoneOff1Ax: filterTimezone,
            timeZoneOn2Ax: filterTimezone,
            timeZoneOff2Ax: filterTimezone,
            timeZoneOn3Ax: filterTimezone,
            timeZoneOff3Ax: filterTimezone,
            timeZoneOn1Bx: filterTimezone,
            timeZoneOff1Bx: filterTimezone,
            timeZoneOn2Bx: filterTimezone,
            timeZoneOff2Bx: filterTimezone,
            timeZoneOn3Bx: filterTimezone,
            timeZoneOff3Bx: filterTimezone,
            daySelectedOn1Ax: filterDaySelectedOn1Ax,
            daySelectedOff1Ax: filterDaySelectedOff1Ax,
            daySelectedOn2Ax: filterDaySelectedOn2Ax,
            daySelectedOff2Ax: filterDaySelectedOff2Ax,
            daySelectedOn3Ax: filterDaySelectedOn3Ax,
            daySelectedOff3Ax: filterDaySelectedOff3Ax,
            daySelectedOn1Bx: filterDaySelectedOn1Bx,
            daySelectedOff1Bx: filterDaySelectedOff1Bx,
            daySelectedOn2Bx: filterDaySelectedOn2Bx,
            daySelectedOff2Bx: filterDaySelectedOff2Bx,
            daySelectedOn3Bx: filterDaySelectedOn3Bx,
            daySelectedOff3Bx: filterDaySelectedOff3Bx,
          });
        } else {
          setDataSetting({ ...intervalSetting });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSubmitOn = (prop) => async (e) => {
    try {
      const result = await axios.post(
        `${ModelaRestURL}/api/socket/SwitchDelay`,
        {
          switch: prop.switch,
          deviceName: token,
          onClick: 1,
          tokenBlynkKey: blynkToken,
          pin: prop.pin,
        }
      );
      if (result.status === 200) {
        console.log("Send API Done!");
        setDataSetting({ ...dataSetting, [prop.switch]: 1 });
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleSubmitOff = (prop) => async (e) => {
    try {
      const result = await axios.post(
        `${ModelaRestURL}/api/socket/SwitchDelay`,
        {
          switch: prop.switch,
          deviceName: token,
          onClick: 0,
          tokenBlynkKey: blynkToken,
          pin: prop.pin,
        }
      );
      if (result.status === 200) {
        console.log("Send API Done!");
        setDataSetting({ ...dataSetting, [prop.switch]: 0 });
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleChangeSwitchDelay = (prop) => async (event, newvalue) => {
    const checked = event.target.checked;
    try {
      const result = await axios.post(
        `${ModelaRestURL}/api/socket/SwitchDelayOption`,
        {
          switch: event.target.name,
          deviceName: token,
          relayOption: Number(checked),
          tokenBlynkKey: blynkToken,
          pin: prop,
        }
      );
      if (result.status === 200) {
        console.log("Send API Done!");
        setDataSetting({ ...dataSetting, [event.target.name]: checked });
      }
    } catch (error) {
      console.log(error);
    }
  };  

  useEffect(async () => {
    getMenuData();
  }, [token]);

  useEffect(() => {
    const handleMessageReceived = (data) => {
      setDataValue(data);
    };

    const handleSettingReceived = () => {
      getSettingSteaming();
    };

    const handleMenuReceived = () => {
      getMenuData();
    };

    const handleDeviceUpdate = () => {
      getDevice();
    };

    socket.on("receive_message", handleMessageReceived);
    socket.on("receive_Setting", handleSettingReceived);
    socket.on("receive_menu", handleMenuReceived);
    socket.on("update_device", handleDeviceUpdate);

    return () => {
      socket.off("receive_message", handleMessageReceived);
      socket.off("receive_Setting", handleSettingReceived);
      socket.off("receive_menu", handleMenuReceived);
      socket.off("update_device", handleDeviceUpdate);
    };
  }, [socket, menuData]);

  useEffect(() => {
    setState({ state });
    setPassShow(false);
    socket.emit("leave_room", oldToken);
    socket.emit("join_room", token);
    setOldToken(token);
    getDevice();
    getDataSteaming();
  }, [token]);

  useEffect(async () => {
    getSettingSteaming();
  }, [token, menuData]);

  return (
    <>
      <Grid spacing={1} container>
        {/* Token */}
        <Grid item xs={12} md={12} lg={12}>
          <Paper className={`${globalStyle.paperMain}`}>
            <Grid  container alignItems="center" justifyContent="space-between">
              <Grid item lg={4}>
                {}
              </Grid>
              <Grid item lg={4}>
                <OutlinedInput
                  inputProps={{ className: classes.textfieldTokenInput, readOnly: true }}
                  className={`${globalStyle.textfieldToken} ${classes.margin1}`}
                  value={!passShow ? "" : "tkn:" + token}
                />
              </Grid>
              <Grid item lg={3} className={`${globalStyle.flexStart} ${globalStyle.paddingL2}`}>
                <Button
                  variant="contained"
                  className={globalStyle.button}
                  classes={{ root: globalStyle.button, contained: globalStyle.buttonHover }}
                  onClick={() => setPassShow(!passShow)}
                  startIcon={!passShow ? <VisibilityIcon /> : <VisibilityOffIcon />}
                >
                  {!passShow ? "Show Token" : "Hide Token"}
                </Button>
                {passShow ?
                  <Button
                    variant="contained"
                    className={copyText === "Copy" ? globalStyle.buttonCopy : globalStyle.buttonCopied}
                    classes={{ root: copyText === "Copy" ? globalStyle.buttonCopy : globalStyle.buttonCopied, contained: globalStyle.buttonCopyHover }}
                    onClick={copyToken}
                    startIcon={copyText === "Copy" ? <FileCopyIcon /> : <CheckIcon />}
                  >
                    {copyText}
                  </Button>
                  : null
                }
              </Grid>
              <Grid item lg={1}></Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* Switch Delay */}
        <Grid item xs={12} md={12} lg={3}>
          <Paper className={globalStyle.paperMain}>
            <Grid container direction="row" justifyContent="space-between" className={ globalStyle.padding1 }>
              <Grid item lg={5} className={globalStyle.flexCenter}>
                <img
                  className={classes.switchDeley}
                  src={dataSetting.switchDeley1 ? "/images/switchOn.png" : "/images/switchOff.png"}
                  onClick={dataSetting.switchDeley1 ? handleSubmitOff({
                    switch: "switchDeley1",
                    pin: "v2",
                  }) : handleSubmitOn({
                    switch: "switchDeley1",
                    pin: "v2",
                  })
                  }
                ></img>
              </Grid>
              <Grid item lg={7} className={globalStyle.flexCenter}>
                <div>
                  <Typography className={ globalStyle.topicPaper }>Switch 1</Typography>
                  <div className={globalStyle.flexCenter}>
                    <Typography className={ classes.bodyPaper }>Manual</Typography>
                    <SwitchIos
                      checked={dataSetting.delaySwitchOption1}
                      handleChange={handleChangeSwitchDelay("v17")}
                      name="delaySwitchOption1"
                    />
                    <Typography className={ classes.bodyPaper }>Auto</Typography>
                  </div>
                </div>
              </Grid>
              <Grid item lg={5} className={globalStyle.flexCenter}>
                <img
                  className={classes.switchDeley}
                  src={dataSetting.switchDeley2 ? "/images/switchOn.png" : "/images/switchOff.png"}
                  onClick={dataSetting.switchDeley2 ? handleSubmitOff({
                    switch: "switchDeley2",
                    pin: "v8",
                  }) : handleSubmitOn({
                    switch: "switchDeley2",
                    pin: "v8",
                  })
                  }
                ></img>
              </Grid>
              <Grid item lg={7} className={globalStyle.flexCenter}>
                <div>
                  <Typography className={ classes.textSwitchHead }>Switch 2</Typography>
                  <div style={{ display: "flex", justifyContent: "center"}}>
                    <Typography className={ classes.textSwitch }>Manual</Typography>
                    <SwitchIos
                      checked={dataSetting.delaySwitchOption2}
                      handleChange={handleChangeSwitchDelay("v19")}
                      name="delaySwitchOption2"
                    />
                    <Typography className={ classes.textSwitch }>Auto</Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={5}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item lg={6}>
              <Paper className={`${globalStyle.paperMain} ${globalStyle.padding1}`}>
                <StatusSensor
                  StatusSensor={dataValue.StatusSensorAX}
                  statusValue={dataSetting.statusValue1}
                  statusValueOption={dataSetting.statusValue1Option}
                  statusValueControl={dataSetting.statusValue1Control}
                  notifyStatusSensor={dataSetting.notifyStatusSensor1}
                  setDataSetting={setDataSetting}
                  dataSetting={dataSetting}
                  blynkToken={blynkToken}
                  menuStatusSensor={menuData.menuStatusSensor1}
                  titleName="Status Sensor 1"
                />
              </Paper>
            </Grid>
            <Grid item lg={6}>
              <Paper className={`${globalStyle.paperMain} ${globalStyle.padding1}`}>
                <StatusSensor
                  StatusSensor={dataValue.StatusSensorBX}
                  statusValue={dataSetting.statusValue2}
                  statusValueOption={dataSetting.statusValue2Option}
                  statusValueControl={dataSetting.statusValue2Control}
                  notifyStatusSensor={dataSetting.notifyStatusSensor2}
                  setDataSetting={setDataSetting}
                  dataSetting={dataSetting}
                  blynkToken={blynkToken}
                  menuStatusSensor={menuData.menuStatusSensor2}
                  titleName="Status Sensor 2"
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={2}>
          <Paper className={globalStyle.paperMain}>
            <TimerSetting
              daySelectedOn1={dataSetting.daySelectedOn1Ax}
              daySelectedOn2={dataSetting.daySelectedOn2Ax}
              daySelectedOn3={dataSetting.daySelectedOn3Ax}
              daySelectedOff1={dataSetting.daySelectedOff1Ax}
              daySelectedOff2={dataSetting.daySelectedOff2Ax}
              daySelectedOff3={dataSetting.daySelectedOff3Ax}
              timerOn1={dataSetting.timerOn1Ax}
              timerOff1={dataSetting.timerOff1Ax}
              timerOn2={dataSetting.timerOn2Ax}
              timerOff2={dataSetting.timerOff2Ax}
              timerOn3={dataSetting.timerOn3Ax}
              timerOff3={dataSetting.timerOff3Ax}
              switchTimerOn1={dataSetting.switchTimerOn1Ax}
              switchTimerOn2={dataSetting.switchTimerOn2Ax}
              switchTimerOn3={dataSetting.switchTimerOn3Ax}
              switchTimerOff1={dataSetting.switchTimerOff1Ax}
              switchTimerOff2={dataSetting.switchTimerOff2Ax}
              switchTimerOff3={dataSetting.switchTimerOff3Ax}
              setDataSetting={setDataSetting}
              dataSetting={dataSetting}
              blynkToken={blynkToken}
              timeZoneOn1={dataSetting.timeZoneOn1Ax}
              timeZoneOff1={dataSetting.timeZoneOff1Ax}
              timeZoneOn2={dataSetting.timeZoneOn2Ax}
              timeZoneOff2={dataSetting.timeZoneOff2Ax}
              timeZoneOn3={dataSetting.timeZoneOn3Ax}
              timeZoneOff3={dataSetting.timeZoneOff3Ax}
              titleName="Timer Switch 1"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={2}>
          <Paper className={globalStyle.paperMain}>
            <TimerSetting
              daySelectedOn1={dataSetting.daySelectedOn1Bx}
              daySelectedOn2={dataSetting.daySelectedOn2Bx}
              daySelectedOn3={dataSetting.daySelectedOn3Bx}
              daySelectedOff1={dataSetting.daySelectedOff1Bx}
              daySelectedOff2={dataSetting.daySelectedOff2Bx}
              daySelectedOff3={dataSetting.daySelectedOff3Bx}
              timerOn1={dataSetting.timerOn1Bx}
              timerOff1={dataSetting.timerOff1Bx}
              timerOn2={dataSetting.timerOn2Bx}
              timerOff2={dataSetting.timerOff2Bx}
              timerOn3={dataSetting.timerOn3Bx}
              timerOff3={dataSetting.timerOff3Bx}
              switchTimerOn1={dataSetting.switchTimerOn1Bx}
              switchTimerOn2={dataSetting.switchTimerOn2Bx}
              switchTimerOn3={dataSetting.switchTimerOn3Bx}
              switchTimerOff1={dataSetting.switchTimerOff1Bx}
              switchTimerOff2={dataSetting.switchTimerOff2Bx}
              switchTimerOff3={dataSetting.switchTimerOff3Bx}
              setDataSetting={setDataSetting}
              dataSetting={dataSetting}
              blynkToken={blynkToken}
              timeZoneOn1={dataSetting.timeZoneOn1Bx}
              timeZoneOff1={dataSetting.timeZoneOff1Bx}
              timeZoneOn2={dataSetting.timeZoneOn2Bx}
              timeZoneOff2={dataSetting.timeZoneOff2Bx}
              timeZoneOn3={dataSetting.timeZoneOn3Bx}
              timeZoneOff3={dataSetting.timeZoneOff3Bx}
              titleName="Timer Switch 2"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <Paper className={globalStyle.paperMain}>
            <ValueSensor
              ValueSensor={dataValue.ValueSensorAX}
              ValueSensorlcd={dataValue.ValueSensorAXlcd}
              ValueSensorUnit={dataValue.ValueSensorUnitAX}
              SecondValueData={dataValue.SecondValueAX}
              SecondValueName={dataValue.SecondValueAXName}
              sensorValue={dataSetting.sensorValue1}
              sensorValueOption={dataSetting.sensorValue1Option}
              sensorValueControl={dataSetting.sensorValue1Control}
              sensorValueHighLimit={dataSetting.sensorValue1HighLimit}
              sensorValueLowLimit={dataSetting.sensorValue1LowLimit}
              sensorValueCalibrate={dataSetting.sensorValue1Calibrate}
              notifyValueSensor1={dataSetting.notifyValueSensor1}
              setDataSetting={setDataSetting}
              dataSetting={dataSetting}
              blynkToken={blynkToken}
              menuValueSensor={menuData.menuValueSensor1}
              titleName="Value Sensor 1"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <Paper className={globalStyle.paperMain}>
            <ValueSensor
              ValueSensor={dataValue.ValueSensorBX}
              ValueSensorlcd={dataValue.ValueSensorBXlcd}
              ValueSensorUnit={dataValue.ValueSensorUnitBX}
              SecondValueData={dataValue.SecondValueBX}
              SecondValueName={dataValue.SecondValueBXName}
              sensorValue={dataSetting.sensorValue2}
              sensorValueOption={dataSetting.sensorValue2Option}
              sensorValueControl={dataSetting.sensorValue2Control}
              sensorValueHighLimit={dataSetting.sensorValue2HighLimit}
              sensorValueLowLimit={dataSetting.sensorValue2LowLimit}
              sensorValueCalibrate={dataSetting.sensorValue2Calibrate}
              notifyValueSensor1={dataSetting.notifyValueSensor2}
              setDataSetting={setDataSetting}
              dataSetting={dataSetting}
              blynkToken={blynkToken}
              menuValueSensor={menuData.menuValueSensor2}
              titleName="Value Sensor 2"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <Paper className={globalStyle.paperMain}>
            <ValueSensor
              ValueSensor={dataValue.ValueSensorCX}
              ValueSensorlcd={dataValue.ValueSensorCXlcd}
              ValueSensorUnit={dataValue.ValueSensorUnitCX}
              SecondValueData={dataValue.SecondValueCX}
              SecondValueName={dataValue.SecondValueCXName}
              sensorValue={dataSetting.sensorValue3}
              sensorValueOption={dataSetting.sensorValue3Option}
              sensorValueControl={dataSetting.sensorValue3Control}
              sensorValueHighLimit={dataSetting.sensorValue3HighLimit}
              sensorValueLowLimit={dataSetting.sensorValue3LowLimit}
              sensorValueCalibrate={dataSetting.sensorValue3Calibrate}
              notifyValueSensor1={dataSetting.notifyValueSensor3}
              setDataSetting={setDataSetting}
              dataSetting={dataSetting}
              blynkToken={blynkToken}
              menuValueSensor={menuData.menuValueSensor3}
              titleName="Value Sensor 3"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <Paper className={globalStyle.paperMain}>
            <ValueSensor
              ValueSensor={dataValue.ValueSensorDX}
              ValueSensorlcd={dataValue.ValueSensorDXlcd}
              ValueSensorUnit={dataValue.ValueSensorUnitDX}
              SecondValueData={dataValue.SecondValueDX}
              SecondValueName={dataValue.SecondValueDXName}
              sensorValue={dataSetting.sensorValue4}
              sensorValueOption={dataSetting.sensorValue4Option}
              sensorValueControl={dataSetting.sensorValue4Control}
              sensorValueHighLimit={dataSetting.sensorValue4HighLimit}
              sensorValueLowLimit={dataSetting.sensorValue4LowLimit}
              sensorValueCalibrate={dataSetting.sensorValue4Calibrate}
              notifyValueSensor1={dataSetting.notifyValueSensor4}
              setDataSetting={setDataSetting}
              dataSetting={dataSetting}
              blynkToken={blynkToken}
              menuValueSensor={menuData.menuValueSensor4}
              titleName="Value Sensor 4"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <Paper className={globalStyle.paperChart}>
            <div className={globalStyle.padding1}>
              <RealtimeChart
                ValueSensorData={dataValue.ValueSensorAX}
                SecondValueData={dataValue.SecondValueAX}
                ValueSensorName={dataValue.ValueSensorAXlcd}
                ValueSensorUnit={dataValue.ValueSensorUnitAX}
                SecondValueName={dataValue.SecondValueAXName}
                sensorValueHighLimit={dataSetting.sensorValue1HighLimit}
                sensorValueLowLimit={dataSetting.sensorValue1LowLimit}
                ChartTitle="ValueSensor 1"
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <Paper className={globalStyle.paperChart}>
            <div className={globalStyle.padding1}>
              <RealtimeChart
                ValueSensorData={dataValue.ValueSensorBX}
                SecondValueData={dataValue.SecondValueBX}
                ValueSensorName={dataValue.ValueSensorBXlcd}
                ValueSensorUnit={dataValue.ValueSensorUnitBX}
                SecondValueName={dataValue.SecondValueBXName}
                sensorValueHighLimit={dataSetting.sensorValue2HighLimit}
                sensorValueLowLimit={dataSetting.sensorValue2LowLimit}
                ChartTitle="ValueSensor 2"
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <Paper className={globalStyle.paperChart}>
            <div className={globalStyle.padding1}>
              <RealtimeChart
                ValueSensorData={dataValue.ValueSensorCX}
                SecondValueData={dataValue.SecondValueCX}
                ValueSensorName={dataValue.ValueSensorCXlcd}
                ValueSensorUnit={dataValue.ValueSensorUnitCX}
                SecondValueName={dataValue.SecondValueCXName}
                sensorValueHighLimit={dataSetting.sensorValue3HighLimit}
                sensorValueLowLimit={dataSetting.sensorValue3LowLimit}
                ChartTitle="ValueSensor 3"
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <Paper className={globalStyle.paperChart}>
            <div className={globalStyle.padding1}>
              <RealtimeChart
                ValueSensorData={dataValue.ValueSensorDX}
                SecondValueData={dataValue.SecondValueDX}
                ValueSensorName={dataValue.ValueSensorDXlcd}
                ValueSensorUnit={dataValue.ValueSensorUnitDX}
                SecondValueName={dataValue.SecondValueDXName}
                sensorValueHighLimit={dataSetting.sensorValue4HighLimit}
                sensorValueLowLimit={dataSetting.sensorValue4LowLimit}
                ChartTitle="ValueSensor 4"
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={2}></Grid>
        <Grid item xs={12} md={12} lg={8}>
          <Paper className={globalStyle.paperChart}>
            <div className={globalStyle.padding1}>
              <RealtimeChartAll
                ValueSensorData={[
                  dataValue.ValueSensorAX,
                  dataValue.ValueSensorBX,
                  dataValue.ValueSensorCX,
                  dataValue.ValueSensorDX,
                ]}
                ValueSensorName={[
                  dataValue.ValueSensorAXlcd,
                  dataValue.ValueSensorBXlcd,
                  dataValue.ValueSensorCXlcd,
                  dataValue.ValueSensorDXlcd,
                ]}
                ValueSensorUnit={[
                  dataValue.ValueSensorUnitAX,
                  dataValue.ValueSensorUnitBX,
                  dataValue.ValueSensorUnitCX,
                  dataValue.ValueSensorUnitDX,
                ]}
                SecondValueData={[
                  dataValue.SecondValueAX,
                  dataValue.SecondValueBX,
                  dataValue.SecondValueCX,
                  dataValue.SecondValueDX,
                ]}
                SecondValueName={[
                  dataValue.SecondValueAXName,
                  dataValue.SecondValueBXName,
                  dataValue.SecondValueCXName,
                  dataValue.SecondValueDXName,
                ]}
                ChartTitle="All Sensor"
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={2}></Grid>
      </Grid>
    </>
  );
}

export default Dashboard;
