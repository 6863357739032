// import React, { Fragment, useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import {
//   Button,
//   Typography,
//   Grid,
//   Input,
//   ListItemText,
//   Avatar,
//   Paper,
// } from "@material-ui/core";
// import { NavLink, useParams } from "react-router-dom";

// const useStyle = makeStyles((theme) => ({
//   textfield: {
//     margin: theme.spacing(0, 0, 2),
//   },
//   p: {
//     color: "red",
//   },
//   layout: {
//     width: "auto",
//     marginLeft: theme.spacing(2),
//     marginRight: theme.spacing(2),
//     marginTop: theme.spacing(25),
//     [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
//       width: 600,
//       marginLeft: "auto",
//       marginRight: "auto",
//     },
//   },
//   paper: {
//     marginTop: theme.spacing(3),
//     marginBottom: theme.spacing(3),
//     padding: theme.spacing(2),
//     [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
//       marginTop: theme.spacing(6),
//       marginBottom: theme.spacing(6),
//       padding: theme.spacing(3),
//     },
//   },
//   modal: {
//     backgroundColor: "#59585e",
//     color: "#fff",
//     // width: "100%",
//     minWidth: "130vh",
//   },
//   modal1: {
//     // width:"50%"
//   },
//   select: {
//     color: "#fff",
//     backgroundColor: "#45444a",
//     borderRadius: "30px",
//   },
// }));
// function ConnectLine() {
//   const classes = useStyle();

//   const { id } = useParams();
//   return (
//     <Fragment>
//       <Grid spacing={1} container>
//         <Grid item xs={12} md={8} lg={12}>
//           <main className={classes.layout}>
//             <Paper className={classes.paper}>
//               <Typography
//                 variant="h1"
//                 //   className={classes.title && classes.brand}
//                 gutterBottom
//               >
//                 {/* <img src={logo} alt={brand.name} /> */}
//                 {/* {brand.name} */}
//               </Typography>
//               <Typography
//                 variant="caption"
//                 className={classes.subtitle}
//                 gutterBottom
//                 align="center"
//               >
//                 authentication
//               </Typography>
//               <Avatar align="center">{/* <InsertLink /> */}</Avatar>

//               <section className={classes.formWrap}>
//                 <form
//                 //   onSubmit={handleSubmit}
//                 >
//                   <div>
//                     <Typography
//                       variant="caption"
//                       className={classes.subtitle}
//                       gutterBottom
//                       align="center"
//                     >
//                       fsdfsd :{id}
//                     </Typography>
//                   </div>

//                   <div className={classes.btnArea}>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       size="large"
//                       type="submit"
//                       // onClick={handleClick}
//                     >
//                       connect
//                     </Button>
//                   </div>
//                 </form>
//               </section>
//             </Paper>
//           </main>
//         </Grid>
//       </Grid>
//     </Fragment>
//   );
// }

// export default ConnectLine;

import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Paper,
  TextField,
  Button,
  CssBaseline,
  Avatar,
  Typography,
  Link,
  Checkbox,
  Input,
  FormControlLabel,
  OutlinedInput,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate, useLocation, NavLink, useParams } from "react-router-dom";
import liff from "@line/liff";
import axios from "axios";
import { useAuth } from "../context/AuthContext";

const useStyle = makeStyles((theme) => ({
  root: {
    // backgroundImage: "url(" + denpicture + ")",
    // opacity: 0.75,
    // background: url()
    background: "linear-gradient(45deg, #323232 30%, #323332 90%)",
    // height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  submit: {
    background: "linear-gradient(45deg, #F29C19 30%, #fbb116 90%)",
    borderRadius: "5px",
    boxShadow: "0 3px 5px 2px rgba(195, 155, 245, .3)",
    padding: "15px",
    color: "white",
    fontSize: "18px",
    // backgroundColor: "#3f51b5",
    margin: theme.spacing(5, 0, 2),
  },
  paperStyle: {
    // padding: 20,
    maxWidth: "550px",
    margin: "25px auto",
    // width: "40%",
    // height: "70vh",
    display: "flex",
    // alignItems: "center",
    flexDirection: "column",
    // padding: "35px 10px",
    borderRadius: "10px",
    marginTop: theme.spacing(20),
    [theme.breakpoints.down("xs")]: {
      // marginTop: theme.spacing(15),
      marginTop: "30% auto",
      width: "90%",
      height: "90%",
    },
    backgroundColor: "rgba(256, 256, 256, 0.9)",
    backdropFilter: "blur(8px)",
  },
  div: {
    // marginTop: theme.spacing(5),
    // padding: "35px 10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  big: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  logo: {
    margin: "auto",
    height: "6vmin",
  },
  input: {
    border: "2px solid #E8E8E8",
    borderRadius: "4px",
    "&:focus": {
      borderColor: "#fbb116",
    },
    // paddingLeft: 8,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  showpass: {
    position: "absolute",
    right: "0",
    top: "1px",
    padding: "7px 10px",
    marginRight: "5px",
    marginTop: "12px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#2a4365",
    backgroundColor: "#EDF2F7",
    borderRadius: "5px",
    cursor: "pointer",
  },
  two: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  h1: {
    fontSize: "34px",
    color: "#F29C19",
  },
  p: {
    color: "#718096",
    fontSize: "22px",
    // marginTop: 0,
  },
  inputprops: {
    color: "#F29C19",
    "&:focus": {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: "#fbb116",
    },
  },
}));

const liffid = "1657874245-DqJPGneX";
let userId = "";
let pic = "";
let name = "";

const ConnectLine = () => {
  const { id } = useParams();
  const classes = useStyle();
  const [nameDisplay, setNameDisplay] = useState("");
  const { currentUser } = useAuth();

  const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;
  const handleSubmit = async (e) => {
    const result = await axios.post(ModelaRestURL + "/api/testLine", {
      userId: userId,
      name: name,
    });
  };

  liff.init({
    liffId: liffid,
    withLoginOnExternalBrowser: true,
  });
  liff.ready.then(() => {
    if (!liff.isLoggedIn()) {
      liff.login();
    }
    liff.getProfile().then((profile) => {
      console.log(profile);
      userId = profile.userId;
      pic = profile.pictureUrl;
      name = profile.displayName;
      setNameDisplay(profile.displayName);
    });
    console.log("userId", userId);
  });

  return (
    <Grid container component="main">
      {/* <div> */}
      <Container
        component={Paper}
        elevation={6}
        // maxWidth="20%"
        className={classes.paperStyle}
      >
        <div>
          <img
            src="https://image.makewebeasy.net/makeweb/m_1920x0/1y4PqMoFk/DefaultData/Untitled_1.png?v=202012190947"
            className={classes.logo}
          />
          <div className={classes.div}>
            <h1 className={classes.h1}>Authentication</h1>
          </div>

          <form className={classes.form} noValidate>

            <div className={classes.div}>
              {/* <h3>User : {currentUser}</h3> */}
              <Avatar src={pic} className={classes.big} align="center">
                {/* <InsertLink /> */}
              </Avatar>
              <h3 className={classes.p}>Name : {name}</h3>
            </div>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={classes.submit}
            >
              connect
            </Button>
          </form>
        </div>
      </Container>
      {/* </div> */}
    </Grid>
  );
};

export default ConnectLine;
