import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { GreenSwitchIos } from "../SwitchIos";
import { useParams } from "react-router-dom";
import useGlobalStyle from "../style/globalStyle";


const useStyle = makeStyles((theme) => ({
  textfield: {
    margin: theme.spacing(0, 0, 2),
  },
  timeBox: {
    // zIndex: "99",
    "&.rc-time-picker-panel": { zIndex: 2000 },
    "&.rc-time-picker-panel-input": {
      fontSize: "25px",
      cursor: "pointer",
      textAlign: "center",
    },
  },
  p: {
    color: "red",
  },
  modal: {
    backgroundColor: "#59585e",
    color: "#fff",
    minWidth: "80vh",
  },
  select: {
    color: "#fff",
    backgroundColor: "#45444a",
    borderRadius: "30px",
    borderColor: "none",
  },
  selectoption: {
    color: "#fff",
    // backgroundColor: "#45444a",
    // borderRadius: "30px",
    borderColor: "none",
  },
  choose: {
    color: "#fff",
  },
  finish: {
    color: "#F29C19",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 0,
  },
}));

const AddAvailableModal = ({
  closeModal,
  isModalOpen,
  statusSensor,
  title,
  statusOption,
  statusControl,
  statusValue,
  statusValueControl,
  statusValueOption,
  notify,
  setDataSetting,
  dataSetting,
  blynkToken
}) => {
  const [modalStatusValue, setModalStatusValue] = useState({});
  const [modalStatusValueControl, setModalStatusValueControl] = useState({});
  const [modalStatusValueOption, setModalStatusValueOption] = useState({});
  const classes = useStyle();
  const globalStyle = useGlobalStyle();
  const [error, setError] = useState("");
  const { token } = useParams();

  const [states, setStates] = useState();

  const handleChanges = (event) => {
    const checked = event.target.checked;
    setStates(checked);
  };

  useEffect(() => {
    setModalStatusValue(statusValue);
    setModalStatusValueControl(statusValueControl);
    setModalStatusValueOption(statusValueOption);
    setStates(notify);
  }, []);

  const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (title == "Status Sensor 1") {
      try {
        const result = await axios.post(
          ModelaRestURL + "/api/socket/SensorStatus1",
          {
            deviceName: token,
            sensorStatus: modalStatusValue.value,
            pinSensorStatus: "v6",
            actionSensor: modalStatusValueControl.value,
            pinActionSensor: "v40",
            relayOption: modalStatusValueOption.value,
            pinRelayOption: "V41",
            NotifyValueSensor: Number(states),
            pinNotifyValueSensor: "v18",
            blynkToken: blynkToken
          }
        );
        if (result.status === 200) {
          setDataSetting({
            ...dataSetting,
            statusValue1: modalStatusValue,
            statusValue1Option: modalStatusValueOption,
            statusValue1Control: modalStatusValueControl,
            notifyStatusSensor1: states,
          });
          console.log("Send API Done!");
          closeModal();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const result = await axios.post(
          ModelaRestURL + "/api/socket/SensorStatus2",
          {
            deviceName: token,
            sensorStatus: modalStatusValue.value,
            pinSensorStatus: "v7",
            actionSensor: modalStatusValueControl.value,
            pinActionSensor: "v50",
            relayOption: modalStatusValueOption.value,
            pinRelayOption: "V51",
            NotifyValueSensor: Number(states),
            pinNotifyValueSensor: "v20",
            blynkToken: blynkToken
          }
        );
        if (result.status === 200) {
          setDataSetting({
            ...dataSetting,
            statusValue2: modalStatusValue,
            statusValue2Option: modalStatusValueOption,
            statusValue2Control: modalStatusValueControl,
            notifyStatusSensor2: states,
          });
          console.log("Send API Done!");
          closeModal();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleChangesvalue = (event) => {
    setModalStatusValue(event.target.value);
  };
  const handleChangesvalueOption = (event) => {
    setModalStatusValueOption(event.target.value);
  };
  const handleChangesvalueControl = (event) => {
    setModalStatusValueControl(event.target.value);
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
      className={globalStyle.modal1}
      PaperProps={{
        className: globalStyle.modalSM,
      }}
    >
      <DialogTitle id="form-dialog-title" component="h1">
        {title}
      </DialogTitle>
      <DialogContent>
        <FormControl
          variant="outlined"
          fullWidth
        >
          {/* <InputLabel
            className={classes.select}
            id="demo-simple-select-outlined-label"
          >
            Sensor...
          </InputLabel> */}
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={modalStatusValue}
            className={classes.select}
            classes={{
              icon: classes.select,
            }}
            onChange={handleChangesvalue}
            label="Select"
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem> */}
            {statusSensor.map((patient, idx) => (
              <MenuItem value={patient}>{patient.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <GreenSwitchIos
          checked={states}
          handleChange={handleChanges}
          name="states"
        />
        <Typography>Automatic Control Option </Typography>
        <FormControl variant="standard" fullWidth className={classes.textfield}>
          {/* <InputLabel
            className={classes.choose}
            id="demo-simple-select-outlined-label"
          >
            Choose...
          </InputLabel> */}
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={modalStatusValueOption}
            className={classes.selectoption}
            onChange={handleChangesvalueOption}
            label="Select"
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem> */}
            {statusOption.map((data, idx) => (
              <MenuItem value={data}>{data.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography>Relay Switch Option </Typography>
        <FormControl variant="standard" fullWidth className={classes.textfield}>
          {/* <InputLabel
            className={classes.choose}
            id="demo-simple-select-outlined-label"
          >
            Choose...
          </InputLabel> */}
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={modalStatusValueControl}
            className={classes.selectoption}
            onChange={handleChangesvalueControl}
            label="Select"
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem> */}
            {statusControl.map((data, idx) => (
              <MenuItem value={data}>{data.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {error && <p className={classes.p}>{error}</p>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          className={classes.finish}
          color="primary"
          size="large"
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAvailableModal;
