import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  Paper,
  Button,
  Typography,
  OutlinedInput,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from "../context/AuthContext";
import { sendEmailVerification } from "firebase/auth";
import { useLocation, NavLink } from "react-router-dom";

// SweetAlert2
import Swal from 'sweetalert2';

// Import Styles
import useGlobalStyle from "../component/style/globalStyle.js";

const useStyle = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(45deg, #323232 30%, #323332 90%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  submit: {
    background: "linear-gradient(45deg, #F29C19 30%, #fbb116 90%)",
    borderRadius: "5px",
    boxShadow: "0 3px 5px 2px rgba(195, 155, 245, .3)",
    padding: "15px",
    color: "white",
    fontSize: "18px",
    margin: theme.spacing(5, 0, 2),
  },
  paperStyle: {
    maxWidth: "550px",
    margin: "25px auto",
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    marginTop: theme.spacing(8),
    [theme.breakpoints.down("xs")]: {
      marginTop: "auto",
      width: "90%",
      height: "90%",
    },
    backgroundColor: "#343434",
    backdropFilter: "blur(8px)",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    margin: "auto",
    height: "6vmin",
    display: "flex",
    flexDirection: "column",

  },
  input: {
    border: "2px solid #D2D6D9",
    borderRadius: "4px",
    "&:focus": {
      borderColor: "#fbb116",
    },
    color: "#D2D6D9",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  showpass: {
    position: "absolute",
    right: "0",
    top: "1px",
    padding: "7px 10px",
    marginRight: "5px",
    marginTop: "12px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#2a4365",
    backgroundColor: "#EDF2F7",
    borderRadius: "5px",
    cursor: "pointer",
  },
  two: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  h1: {
    fontSize: "34px",
    color: "#F29C19",
    marginBottom: 0,
  },
  p: {
    color: "#718096",
    fontSize: "15px",
    marginTop: 0,
  },
  googleButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: "#fff",
    color: "black",
    "&:hover": {
      backgroundColor: "#ffffffb3",
    },
  },
  label: {
    color: "#f1c40f",
    fontWeight: "bold",
  },
  navlink: {
    color: "#73C2FB",
    textDecoration: "none",
  },
}));

const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;

const Login = () => {
  const {login, loginWithGoogle} = useAuth();
  const [passShow, setPassShow] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };

  const classes = useStyle();
  const globalStyle = useGlobalStyle();
  const [inpval, setInpval] = useState({
    email: "",
    password: "",
  });

  const setVal = (e) => {
    const { name, value } = e.target;

    setInpval(() => {
      return {
        ...inpval,
        [name]: value,
      };
    });
  };

  const loginuser = async (e) => {
    e.preventDefault();

    const { email, password } = inpval;

    if (email === "") {
      setEmailError(true);
      toast.error("Email address is required!");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailError(true);
      toast.error("Invalid email address!");
    } else if (password === "") {
      setEmailError(false);
      setPasswordError(true);
      toast.error("Password is required!");
    } else if (password.length < 6) {
      setEmailError(false);
      setPasswordError(true);
      toast.error("Password must be 6 char!");
    }
    else {
      login(email, password)
        .then((res) => {
          const { user, emailVerified } = res;
          // ตรวจสอบสถานะ emailVerified หลังจากล็อกอินเสร็จสิ้น
          if (emailVerified) {
            setEmailError(false);
            setPasswordError(false);
            toast.success("Login Success!");
            setTimeout(() => {
              window.location.href = "/home";
            }, 500);
          } else {
            setEmailError(false);
            setPasswordError(false);
            Swal.fire({
              icon: 'error',
              title: 'Email Not Verified',
              text: 'Please verify your email address before logging in.',
              showCancelButton: true,
              confirmButtonText: 'Resend Verification Email',
              confirmButtonColor: '#f1c40f',
            }).then((result) => {
              if (result.isConfirmed) {
                // เรียกใช้ฟังก์ชันสำหรับการส่ง Verify Email อีกครั้ง
                sendVerifyEmail(user);
              }
            });
          }
        })
        .catch(error => {
          setEmailError(true);
          setPasswordError(true);
          toast.error("Incorrect username or password!");
          console.log(error.message);
        })
    }
  };
  
  const sendVerifyEmail = (user) => {
    // เรียกใช้ฟังก์ชันสำหรับการส่ง Verify Email ใหม่
    sendEmailVerification(user)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Verification Email Sent',
          text: 'Please check your email inbox for the verification email.',
          showCancelButton: false,
          confirmButtonText: 'OK',
          confirmButtonColor: '#f1c40f',
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error Sending Verification Email',
          text: error.message,
        });
      });
  };

  const useLoginWithGoogle = async () => {
    loginWithGoogle()
      .then((res) => {
        const { user, emailVerified } = res;
        // ตรวจสอบสถานะ emailVerified หลังจากล็อกอินเสร็จสิ้น
        if (emailVerified) {
          setEmailError(false);
          setPasswordError(false);

          const { displayName, email } = user;

          const nameArr = displayName.split(" ");
          const firstname = nameArr[0];
          const lastname = nameArr[1];

          if (user) {
            axios
              .post(ModelaRestURL + "/api/user/register", {
                firstname: firstname,
                lastname: lastname,
                email: email,
                providers: "google",
              })
              .then((response) => {})
              .catch((error) => {
                toast.error("Error sending user data to MongoDB!");
                console.error("Error:", error);
              });
          }

          toast.success("Login Success!");
          setTimeout(() => {
            window.location.href = "/home";
          }, 500);
        } else {
          setEmailError(false);
          setPasswordError(false);
          Swal.fire({
            icon: 'error',
            title: 'Email Not Verified',
            text: 'Please verify your email address before logging in.',
            showCancelButton: true,
            confirmButtonText: 'Resend Verification Email',
            confirmButtonColor: '#f1c40f',
          }).then((result) => {
            if (result.isConfirmed) {
              // เรียกใช้ฟังก์ชันสำหรับการส่ง Verify Email อีกครั้ง
              sendVerifyEmail(user);
            }
          });
        }
      })
      .catch(error => {
        setEmailError(true);
        setPasswordError(true);
        toast.error("Incorrect username or password!");
        console.log(error.message);
      })
  };

  return (
    <Grid container component="main">
      <Container
        component={Paper}
        elevation={6}
        className={globalStyle.paperLogin}
      >
        <div>
          <div className={classes.div}>
            <h1 className={classes.h1}>ModelaDashboard</h1>
            <p className={classes.p}>
              Hi, we are you glad you are back. Please login.
            </p>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <form className={classes.form} noValidate>
            <div>
              <label
                className={classes.label}
                htmlFor="email"
              >
                Email
              </label>
              <OutlinedInput
                value={inpval.email}
                onChange={setVal}
                type="email"
                fullWidth
                className={classes.input}
                placeholder="Enter Your Email Address"
                autoComplete="email"
                id="email"
                name="email"
                error={emailError}
                autoFocus
              />
            </div>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <label
                  className={classes.label}
                  htmlFor="password"
                >
                  Password
                </label>
                <NavLink to="/forgot-password" className={classes.p}>Forgot Password ?</NavLink>
              </div>
              <div className={classes.two}>
                <OutlinedInput
                  fullWidth
                  type={!passShow ? "password" : "text"}
                  className={classes.input}
                  onChange={setVal}
                  value={inpval.password}
                  name="password"
                  id="password"
                  placeholder="Enter Your password"
                  error={passwordError}
                />
                <div
                  className={classes.showpass}
                  onClick={() => setPassShow(!passShow)}
                >
                  {!passShow ? "Show" : "Hide"}
                </div>
              </div>
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={loginuser}
            >
              Login
            </Button>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px 0" }}>
              <hr style={{ border: "0.5px solid #ceced9", width: "45%", marginRight: "10px" }} />
              <Typography variant="body2" className={classes.label}>
                OR
              </Typography>
              <hr style={{ border: "0.5px solid #ceced9", width: "45%", marginLeft: "10px" }} />
            </div>
            <div className={classes.div}>
              <Button
                fullWidth
                variant="contained"
                className={classes.googleButton}
                onClick={useLoginWithGoogle}
                startIcon={<img src="icons/google-28x28.png" alt="google" />}
              >
                Login with Google
              </Button>
            </div>
            <div className={classes.div}>
              <p className={classes.p}>
                Don't have an Account? <NavLink to="/register" className={classes.navlink}>Sign Up</NavLink>
              </p>
            </div>
          </form>
          <img src="https://image.makewebeasy.net/makeweb/m_1920x0/1y4PqMoFk/DefaultData/Untitled_1.png?v=202012190947" className={classes.logo} />

            
        </div>
      </Container>
    </Grid>
    
  );
};

export default Login;
