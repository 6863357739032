import React, { useState } from "react";
import axios from "axios";
import { Container, Grid, Paper, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../context/AuthContext";
import { useLocation, NavLink } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(45deg, #323232 30%, #323332 90%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  submit: {
    background: "linear-gradient(45deg, #F29C19 30%, #fbb116 90%)",
    borderRadius: "5px",
    boxShadow: "0 3px 5px 2px rgba(195, 155, 245, .3)",
    padding: "15px",
    color: "white",
    fontSize: "18px",
    margin: theme.spacing(5, 0, 2),
  },
  paperStyle: {
    maxWidth: "550px",
    margin: "25px auto",
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    marginTop: theme.spacing(8),
    [theme.breakpoints.down("xs")]: {
      marginTop: "auto",
      width: "90%",
      height: "90%",
    },
    backgroundColor: "rgba(256, 256, 256, 0.9)",
    backdropFilter: "blur(8px)",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    margin: "auto",
    height: "6vmin",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    border: "2px solid #E8E8E8",
    borderRadius: "4px",
    "&:focus": {
      borderColor: "#fbb116",
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  showpass: {
    position: "absolute",
    right: "0",
    top: "1px",
    padding: "7px 10px",
    marginRight: "5px",
    marginTop: "12px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#2a4365",
    backgroundColor: "#EDF2F7",
    borderRadius: "5px",
    cursor: "pointer",
  },
  two: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  h1: {
    fontSize: "34px",
    color: "#F29C19",
    marginBottom: 0,
  },
  p: {
    color: "#718096",
    fontSize: "15px",
    marginTop: 0,
  },
  inputprops: {
    color: "#F29C19",
    "&:focus": {
      borderColor: "#fbb116",
    },
  },
}));

const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;

const Register = () => {
  const { register } = useAuth();
  const [passShow, setPassShow] = useState(false);
  const [confirmPassShow, setConfirmPassShow] = useState(false);
  const [firstnameError, setFirstnameError] = useState(false);
  const [lastnameError, setLastnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmpasswordError, setConfirmpasswordError] = useState(false);

  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };

  const classes = useStyle();

  const handleSubmit = async (event) => {
    event.preventDefault();
  };

  const [inpval, setInpval] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmpassword: "",
  });

  const setVal = (e) => {
    const { name, value } = e.target;

    setInpval(() => {
      return {
        ...inpval,
        [name]: value,
      };
    });
  };

  const [registerStatus, setRegisterStatus] = useState({
    success: false, // สถานะการลงทะเบียน (true = สำเร็จ, false = ไม่สำเร็จ)
    error: null,     // ข้อผิดพลาด (ถ้ามี)
  });  

  const registeruser = async (e) => {
    e.preventDefault();

    const { firstname, lastname, email, password, confirmpassword } = inpval;
    
    let isValid = true;
    let errorMessage = "";

    if (firstname === "") {
      setFirstnameError(true);
      isValid = false;
      errorMessage = "First name is required!";
    } else if (lastname === "") {
      setFirstnameError(false);
      setLastnameError(true);
      isValid = false;
      errorMessage = "Last name is required!";
    } else if (email === "") {
      setFirstnameError(false);
      setLastnameError(false);
      setEmailError(true);
      isValid = false;
      errorMessage = "Email address is required!";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setFirstnameError(false);
      setLastnameError(false);
      setEmailError(true);
      isValid = false;
      errorMessage = "Invalid email address!";
    } else if (password === "") {
      setFirstnameError(false);
      setLastnameError(false);
      setEmailError(false);
      setPasswordError(true);
      isValid = false;
      errorMessage = "Password is required!";
    } else if (password.length < 6) {
      setFirstnameError(false);
      setLastnameError(false);
      setEmailError(false);
      setPasswordError(true);
      isValid = false;
      errorMessage = "Password must be 6 char!";
    } else if (confirmpassword === "") {
      setFirstnameError(false);
      setLastnameError(false);
      setEmailError(false);
      setPasswordError(false);
      setConfirmpasswordError(true);
      isValid = false;
      errorMessage = "Confirm password is required!";
    } else if (confirmpassword != password) {
      setFirstnameError(false);
      setLastnameError(false);
      setEmailError(false);
      setPasswordError(false);
      setConfirmpasswordError(true);
      isValid = false;
      errorMessage = "Password and confirm password do not match!";
    }
    
    if (!isValid) {
      setRegisterStatus({ success: false, error: errorMessage });
      toast.error(errorMessage);
      return;
    } else {
      register(email, password)
        .then((res) => {
          if(res.success) {

            setRegisterStatus({ success: true, error: null });
            console.log("User registered successfully.");

            if (res.user) {
              axios
                .post(ModelaRestURL + "/api/user/register", {
                  firstname: firstname,
                  lastname: lastname,
                  email: email,
                  providers: "email",
                })
                .then((response) => {
                  // console.log("User data sent to MongoDB:", response.data);
                  toast.success("Register Success!");
                  toast.success("Please go to your email to verify your email address.");
                  setTimeout(() => {
                    window.location.href = "/Login";
                  }, 2000);
                })
                .catch((error) => {
                  toast.error("Error sending user data to MongoDB!");
                  console.error("Error:", error);
                });
            }
          }
          else{
            setRegisterStatus({ success: false, error: res.reason });
            toast.error(res.reason);
            console.error("Error register:", res.reason);
          }
        })
        .catch((error) => {
          setRegisterStatus({ success: false, error: error.message });
          toast.error("Register Failed!");
          console.log(error.message);
        });
    }
  };

  return (
    <Grid container component="main">
      <Container component={Paper} elevation={6} className={classes.paperStyle}>
        <div>
          <div className={classes.div}>
            <h1 className={classes.h1}>Create your account</h1>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <form className={classes.form} noValidate>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  type="text"
                  className={classes.input}
                  onChange={setVal}
                  value={inpval.firstname}
                  name="firstname"
                  id="firstname"
                  placeholder="Enter Your First name"
                  error={firstnameError}
                  label="First name"
                  variant="outlined"
                  autoFocus
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  type="text"
                  className={classes.input}
                  onChange={setVal}
                  value={inpval.lastname}
                  name="lastname"
                  id="lastname"
                  placeholder="Enter Your Last name"
                  error={lastnameError}
                  label="Last name"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={6} md={12}>
                <TextField
                  fullWidth
                  value={inpval.email}
                  onChange={setVal}
                  type="email"
                  className={classes.input}
                  placeholder="Enter Your Email Address"
                  autoComplete="email"
                  id="email"
                  name="email"
                  error={emailError}
                  label="Email"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={6} md={6}>
                <div className={classes.two}>
                  <TextField
                    fullWidth
                    type={!passShow ? "password" : "text"}
                    className={classes.input}
                    onChange={setVal}
                    value={inpval.password}
                    name="password"
                    id="password"
                    placeholder="Enter Your password"
                    error={passwordError}
                    label="Password"
                    variant="outlined"
                  />
                  <div
                    className={classes.showpass}
                    onClick={() => setPassShow(!passShow)}
                  >
                    {!passShow ? "Show" : "Hide"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} md={6}>
                <div className={classes.two}>
                  <TextField
                    fullWidth
                    type={!confirmPassShow ? "password" : "text"}
                    className={classes.input}
                    onChange={setVal}
                    value={inpval.confirmpassword}
                    name="confirmpassword"
                    id="confirmpassword"
                    placeholder="Confirm"
                    error={confirmpasswordError}
                    label="Confirm password"
                    variant="outlined"
                  />
                  <div
                    className={classes.showpass}
                    onClick={() => setConfirmPassShow(!confirmPassShow)}
                  >
                    {!confirmPassShow ? "Show" : "Hide"}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={registeruser}
            >
              Register
            </Button>
            <div className={classes.div}>
              <p className={classes.p}>
                Already have an account? <NavLink to="/Login">Login</NavLink>
              </p>
            </div>
          </form>
          <img
            src="https://image.makewebeasy.net/makeweb/m_1920x0/1y4PqMoFk/DefaultData/Untitled_1.png?v=202012190947"
            className={classes.logo}
          />
        </div>
      </Container>
    </Grid>
  );
};

export default Register;
