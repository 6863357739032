import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  DialogActions,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Switch,
  Typography,
  Slider,
  Grid,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { GreenSwitchIos } from "../SwitchIos";
import { useParams } from "react-router-dom";
import useGlobalStyle from "../style/globalStyle";


const useStyle = makeStyles((theme) => ({
  textfield: {
    margin: theme.spacing(0, 0, 2),
  },
  p: {
    color: "red",
  },
  modal: {
    backgroundColor: "#59585e",
    color: "#fff",
    minWidth: "80vh",
  },
  select: {
    color: "#fff",
    backgroundColor: "#45444a",
    borderRadius: "30px",
  },
  selectoption: {
    color: "#fff",
    borderColor: "none",
  },
  finish: {
    color: "#F29C19",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 0,
  },
  choose: {
    color: "#fff",
  },
  leftText: {
    textAlign: "left"
  },
  rightText: {
    textAlign: "right"
  },
  centerText: {
    textAlign: "center",
    marginLeft: -3,
  }
}));

const PrettoSlider = withStyles({
  button: {
    color: "#fbb116",
    backgroundColor: "#fbb116",
  },
  root: {
    color: "#fbb116",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  mark: {
    backgroundColor: '#F29C19',
    height: 26,
    width: 4,
    marginLeft: -2,
    marginTop: -9,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const marks = [
  {
    value: 0,
  }
];

const AddAvailableModal = ({
  closeModal,
  isModalOpen,
  title,
  valueSensor,
  valueOption,
  valueControl,
  sensorValue,
  sensorValueControl,
  sensorValueOption,
  sensorValueHighLimit,
  sensorValueLowLimit,
  sensorValueCalibrate,
  notify,
  setDataSetting,
  dataSetting,
  blynkToken
}) => {
  const [modalSensorValue, setModalSensorValue] = useState([]);
  const [modalSensorValueControl, setModalSensorValueControl] = useState([]);
  const [modalSensorValueOption, setModalSensorValueOption] = useState([]);
  const [modalSensorMinValue, setModalSensorMinValue] = useState([]);
  const [modalSensorMaxValue, setModalSensorMaxValue] = useState([]);
  const classes = useStyle();
  const globalStyle = useGlobalStyle();
  const [error, setError] = useState("");
  const [value, setValue] = useState([]);
  const [calibrate, setCalibrate] = useState();
  const { token } = useParams();


  const [states, setStates] = useState();


  const handleChanges = (event) => {
    const checked = event.target.checked;
    setStates(checked);
  };

  useEffect(() => {
    setModalSensorValue(sensorValue);
    setModalSensorMinValue(sensorValue.min);
    setModalSensorMaxValue(sensorValue.max);
    setModalSensorValueControl(sensorValueControl);
    setModalSensorValueOption(sensorValueOption);
    setValue([sensorValueLowLimit, sensorValueHighLimit]);
    setCalibrate(sensorValueCalibrate);
    setStates(notify);
  }, []);

  const handleChangesCalibrate = (event, newValue) => {
    setCalibrate(newValue);
  };

  const handleChangeSlider = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangesvalue = (event) => {
    setModalSensorValue(event.target.value);
    setModalSensorMinValue(event.target.value.min);
    setModalSensorMaxValue(event.target.value.max);
  };
  const handleChangesvalueOption = (event) => {
    setModalSensorValueOption(event.target.value);
  };
  const handleChangesvalueControl = (event) => {
    setModalSensorValueControl(event.target.value);
  };

  const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (title == "Value Sensor 1") {
      try {
        const result = await axios.post(
          ModelaRestURL + "/api/socket/ValueSensor1",
          {
            deviceName: token,
            valueSensor: modalSensorValue.value,
            pinValueSensor: "v45",
            actionSensor: modalSensorValueControl.value,
            pinActionSensor: "v67",
            relayOption: modalSensorValueOption.value,
            pinRelayOption: "v70",
            NotifyValueSensor: Number(states),
            pinNotifyValueSensor: "v77",
            calibrate: calibrate,
            pinCalibrate: "v60",
            highLimit: value[1],
            pinHighLimit: "v68",
            lowLimit: value[0],
            pinLowLimit: "v69",
            blynkToken: blynkToken
          }
        );
        if (result.status === 200) {
          setDataSetting({
            ...dataSetting,
            sensorValue1: modalSensorValue,
            sensorValue1Option: modalSensorValueOption,
            sensorValue1Control: modalSensorValueControl,
            sensorValue1HighLimit: value[1],
            sensorValue1LowLimit: value[0],
            sensorValue1Calibrate: calibrate,
            notifyValueSensor1: states
          });
          closeModal();
          console.log("Send API Done!");
        }
      } catch (error) {
        console.log(error);
      }
    }
    else if (title == "Value Sensor 2") {
      try {
        const result = await axios.post(
          ModelaRestURL + "/api/socket/ValueSensor2",
          {
            deviceName: token,
            valueSensor: modalSensorValue.value,
            pinValueSensor: "v80",
            actionSensor: modalSensorValueControl.value,
            pinActionSensor: "v82",
            relayOption: modalSensorValueOption.value,
            pinRelayOption: "v81",
            NotifyValueSensor: Number(states),
            pinNotifyValueSensor: "v78",
            calibrate: calibrate,
            pinCalibrate: "v85",
            highLimit: value[1],
            pinHighLimit: "v83",
            lowLimit: value[0],
            pinLowLimit: "v84",
            blynkToken: blynkToken
          }
        );
        if (result.status === 200) {
          setDataSetting({
            ...dataSetting,
            sensorValue2: modalSensorValue,
            sensorValue2Option: modalSensorValueOption,
            sensorValue2Control: modalSensorValueControl,
            sensorValue2HighLimit: value[1],
            sensorValue2LowLimit: value[0],
            sensorValue2Calibrate: calibrate,
            notifyValueSensor2: states
          });
          closeModal();
          console.log("Send API Done!");
        }
      } catch (error) {
        console.log(error);
      }
    }
    else if (title == "Value Sensor 3") {
      try {
        const result = await axios.post(
          ModelaRestURL + "/api/socket/ValueSensor3",
          {
            deviceName: token,
            valueSensor: modalSensorValue.value,
            pinValueSensor: "v90",
            actionSensor: modalSensorValueControl.value,
            pinActionSensor: "v92",
            relayOption: modalSensorValueOption.value,
            pinRelayOption: "v91",
            NotifyValueSensor: Number(states),
            pinNotifyValueSensor: "v88",
            calibrate: calibrate,
            pinCalibrate: "v95",
            highLimit: value[1],
            pinHighLimit: "v93",
            lowLimit: value[0],
            pinLowLimit: "v94",
            blynkToken: blynkToken
          }
        );
        if (result.status === 200) {
          setDataSetting({
            ...dataSetting,
            sensorValue3: modalSensorValue,
            sensorValue3Option: modalSensorValueOption,
            sensorValue3Control: modalSensorValueControl,
            sensorValue3HighLimit: value[1],
            sensorValue3LowLimit: value[0],
            sensorValue3Calibrate: calibrate,
            notifyValueSensor3: states
          });
          closeModal();
          console.log("Send API Done!");
        }
      } catch (error) {
        console.log(error);
      }
    }
    else if (title == "Value Sensor 4") {
      try {
        const result = await axios.post(
          ModelaRestURL + "/api/socket/ValueSensor4",
          {
            deviceName: token,
            valueSensor: modalSensorValue.value,
            pinValueSensor: "v98",
            actionSensor: modalSensorValueControl.value,
            pinActionSensor: "v100",
            relayOption: modalSensorValueOption.value,
            pinRelayOption: "v99",
            NotifyValueSensor: Number(states),
            pinNotifyValueSensor: "v96",
            calibrate: calibrate,
            pinCalibrate: "v103",
            highLimit: value[1],
            pinHighLimit: "v101",
            lowLimit: value[0],
            pinLowLimit: "v102",
            blynkToken: blynkToken
          }
        );
        if (result.status === 200) {
          setDataSetting({
            ...dataSetting,
            sensorValue4: modalSensorValue,
            sensorValue4Option: modalSensorValueOption,
            sensorValue4Control: modalSensorValueControl,
            sensorValue4HighLimit: value[1],
            sensorValue4LowLimit: value[0],
            sensorValue4Calibrate: calibrate,
            notifyValueSensor4: states
          });
          closeModal();
          console.log("Send API Done!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
      className={globalStyle.modal1}
      PaperProps={{
        className: globalStyle.modalSM,
      }}
    >
      <DialogTitle id="form-dialog-title" component="h1">
        {title}
      </DialogTitle>
      <DialogContent>
        <FormControl
          variant="outlined"
          classes={{ root: classes.select }}
          fullWidth
          className={classes.textfield}
        >
          {/* <InputLabel
            className={classes.select}
            id="demo-simple-select-outlined-label"
          >
            Sensor...
          </InputLabel> */}
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={modalSensorValue}
            classes={{
              root: classes.select,
              icon: classes.select,
            }}
            className={classes.select}
            onChange={handleChangesvalue}
            label="Select"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {valueSensor.map((data, idx) => (
              <MenuItem value={data}>{data.name}</MenuItem>
            ))}
            
          </Select>
        </FormControl>

        <GreenSwitchIos
          checked={states}
          handleChange={handleChanges}
          name="states"
        />

        <Typography>Automatic Control Option </Typography>
        <FormControl variant="standard" fullWidth className={classes.textfield}>
          {/* <InputLabel
            className={classes.choose}
            id="demo-simple-select-outlined-label"
          >
            Choose...
          </InputLabel> */}
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={modalSensorValueOption}
            onChange={handleChangesvalueOption}
            label="Select"
            className={classes.selectoption}
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem> */}
            {valueOption.map((data, idx) => (
              <MenuItem value={data}>{data.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography>Relay Switch Option </Typography>
        <FormControl variant="standard" fullWidth className={classes.textfield}>
          {/* <InputLabel
            className={classes.choose}
            id="demo-simple-select-outlined-label"
          >
            Choose...
          </InputLabel> */}
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={modalSensorValueControl}
            onChange={handleChangesvalueControl}
            label="Select"
            className={classes.selectoption}
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem> */}
            {valueControl.map((data, idx) => (
              <MenuItem value={data}>{data.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography id="range-slider" gutterBottom>
          High Low Range
        </Typography>
        <PrettoSlider
          valueLabelDisplay="on"
          value={value}
          step={0.25}
          min={modalSensorMinValue}
          max={modalSensorMaxValue}
          onChange={handleChangeSlider}
          aria-label="pretto slider"
          style={{ width: "99%" }}
        />
        <Typography id="range-slider" gutterBottom>
          calibrate
        </Typography>
        <Grid container>
        <Grid className={classes.leftText} item lg={5}><Typography>-</Typography></Grid>
        <Grid className={classes.centerText} item lg={2}><Typography>0</Typography></Grid>
        <Grid className={classes.rightText} item lg={5}><Typography>+</Typography></Grid>
        </Grid>
        <PrettoSlider
          value={calibrate}
          valueLabelDisplay="on"
          min={-30}
          step={0.5}
          max={30}
          marks={marks}
          onChange={handleChangesCalibrate}
          aria-labelledby="continuous-slider"
          style={{ width: "99%" }}
        />

        {error && <p className={classes.p}>{error}</p>}
      </DialogContent>
      <DialogActions>
        <Button className={classes.finish} onClick={handleSubmit} size="large">
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAvailableModal;
