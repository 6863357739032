import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

function PrivateRoute({ element, adminRequired, isAdminPage }) {
  const { currentUser, isAdmin } = useAuth();
  
  // const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  // const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

  // console.log("currentUser", currentUser);
  // console.log("isAdmin", isAdmin);

  if (adminRequired && currentUser && isAdmin) {
    //  หน้านี้ต้องการสิทธิ์ Admin และ currentUser ไม่ว่าง
    return element;
  } else if (adminRequired && currentUser && !isAdmin) {
    //  หน้านี้ต้องการสิทธิ์ Admin แต่ currentUser ไม่มีสิทธิ์ Admin
    return <Navigate to="/Home" />;
  } else if (!adminRequired && currentUser) {
    //  หน้านี้ไม่ต้องการสิทธิ์ Admin และ currentUser ไม่ว่าง
    return element;
  } else {
    //  currentUser ว่าง
    return <Navigate to="/login" />;
  }
}

export default PrivateRoute;