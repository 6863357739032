import React from "react";
import { makeStyles, TextField, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {

  multilineColor: {
    color: "#fff",
    borderRadius: "10px",
  },
  multilineColorTextfield: {
    color: "#fff",
    height: "40px",
    borderRadius: "10px",
  },
  paper: {
      backgroundColor: "#737373",
      borderRadius: "10px"
  },
  terminal: {
    margin: theme.spacing(1),
    backgroundColor: "#45444a",
    borderRadius: "10px"

    // color: "inherit"

    // height: "30vh"
  },
  textfield: {
    margin: theme.spacing(1),
    backgroundColor: "#45444a",
    borderRadius: "10px",
    // height: "40px"
  }
};
  
});


function Terminal() {
  const classes = useStyles()
  return (
    <div>
      {/* <Grid>
        <Grid item sm={3}></Grid>
      </Grid> */}
      <TextField
        id="standard-read-only-input"
        // label="Read Only"
        defaultValue="I'm Modela Terminal"
        // color="inherit"
        fontColor="white"
        fullWidth
        multiline
        rows={6}
        variant="outlined"
        InputProps={{
          readOnly: true,
          className: classes.multilineColor
        }}
        className={classes.terminal}
      ></TextField>
      <TextField InputProps={{
          className: classes.multilineColorTextfield
        }} className={classes.textfield} fullWidth variant="outlined"></TextField>
    </div>
  );
}

export default Terminal;
