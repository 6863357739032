import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import { SwitchIosSmallGreen } from "./SwitchIos";
import StatusSensorModal from "./Modal/StatusSensorModal";
import StatusOption from "../api/ui/StatusOption";
import StatusControl from "../api/ui/StatusControl";
import useGlobalStyle from "./style/globalStyle";

const useStyles = makeStyles((theme) => {
  return {
    textfieldStatus: {
      backgroundColor: "#45444a",
      borderRadius: "30px",
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(3),
      width: "90%",
    },
    multilineColor: {
      color: "#fff",
      borderRadius: "30px",
      height: "35px",
      borderColor: "#45444a",
    },
    topicpaper: {
      fontSize: "1.3rem",
      fontWeight: "500",
      textAlign: "center",
    },
    imglight: {
      margin: theme.spacing(1),
    },
    textfieldInput: {
      color: '#FFF',
      borderRadius: "2rem",
      height: "45px",
      borderColor: '#1A1E27',
    },
  };
});

function StatusSensor({
  StatusSensor,
  statusValue,
  statusValueOption,
  statusValueControl,
  notifyStatusSensor,
  setDataSetting,
  dataSetting,
  blynkToken,
  menuStatusSensor,
  titleName,
}) {
  const globalStyle = useGlobalStyle();
  const classes = useStyles();
  const [isModalStatusValueOpen, setIsStatusValueOpen] = useState(false);
  const statusControlText = (!statusValueOption || statusValueOption.length === 0) ? 'Control' : statusValueOption.name;
  const statusSwitchText = (!statusValueControl || statusValueControl.length === 0) ? 'Switch' : statusValueControl.name;

  const statusControl = [
    { label: "Control", value: statusControlText },
    { label: "Switch", value: statusSwitchText },
  ];

  const statusValueData = statusValue ? statusValue : { name: "Sensor", value: 999, min: 0, max: 100 };

  const openModalStatusValue = () => {
    setIsStatusValueOpen(true);
  };

  const closeModalStatusValue = () => {
    setIsStatusValueOpen(false);
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={2}></Grid>
        <Grid item lg={8}>
          <Typography className={globalStyle.topicPaper}>
          {titleName}
          </Typography>
        </Grid>
        <Grid item lg={2}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => {
                openModalStatusValue();
            }}
            color="inherit"
          >
          <SettingsIcon />
          </IconButton>
        </Grid>
        <Grid item lg={12} className={globalStyle.flexCenter}>
          <TextField
            id="standard-read-only-input"
            defaultValue="Sensor"
            variant="outlined"
            className={`${globalStyle.textfield} ${classes.margin1}`}
            InputProps={{
              readOnly: true,
              className: classes.textfieldInput,
            }}
            value={statusValueData.name}
          />
        </Grid>
        <Grid item lg={4} className={`${globalStyle.flexStart} ${globalStyle.paddingL2}`}>
          <Typography>Notify</Typography>
        </Grid>
        <Grid item lg={3} className={globalStyle.flexStart}>
          <SwitchIosSmallGreen
            checked={notifyStatusSensor}
            name="notifyStatusSensor"
            handleChange={() => {openModalStatusValue()}}
          />
        </Grid>
        <Grid item lg={5} className={globalStyle.flexCenter}>
          <img
            src={StatusSensor === 1 ? "/images/StatusOn.png" : "/images/StatusOff.png"}
            style={{ width: "80px", height: "80px" }}
            className={classes.imglight}
          ></img>
        </Grid>

        {statusControl.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item lg={3} className={`${globalStyle.flexStart} ${globalStyle.paddingL2}`}>
              <Typography>{item.label}</Typography>
            </Grid>
            <Grid item lg={1}></Grid>
            <Grid item lg={8} className={globalStyle.flexStart}>
              <Typography className={globalStyle.borderSensor}>{item.value}</Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      {isModalStatusValueOpen && (
        <StatusSensorModal
          isModalOpen={isModalStatusValueOpen}
          closeModal={closeModalStatusValue}
          statusSensor={menuStatusSensor}
          title={titleName}
          statusOption={StatusOption}
          statusControl={StatusControl}
          statusValue={statusValueData}
          statusValueControl={statusValueControl}
          statusValueOption={statusValueOption}
          notify={notifyStatusSensor}
          setDataSetting={setDataSetting}
          dataSetting={dataSetting}
          blynkToken={blynkToken}
        />
      )}
    </div>
  );
}

export default StatusSensor;
