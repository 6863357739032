import {
    makeStyles,
    Drawer,
    Fab,
    Typography,
    AppBar,
    Toolbar,
    Menu,
    MenuItem,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction,
    IconButton,
    Collapse,
  } from "@material-ui/core";
  import MenuIcon from "@material-ui/icons/Menu";
  import React, { useEffect, useState } from "react";
  import { useNavigate, useLocation, useParams } from "react-router-dom";
  import DashboardIcon from "@material-ui/icons/Dashboard";
  import HomeIcon from "@material-ui/icons/Home";
  import EmailIcon from "@material-ui/icons/Email";
  import { NavLink } from "react-router-dom";
  import AccountCircleIcon from "@material-ui/icons/AccountCircle";
  import clsx from "clsx"; //ใช้ classname ได้มากกว่า 1 class
  import { useAuth } from "../../context/AuthContext";
  import AddBoxIcon from "@material-ui/icons/AddBox";
  import axios from "axios";
  import DeleteIcon from "@material-ui/icons/Delete";
//   import DeleteDevice from "./Modal/DeleteDevice";
//   import CreateDevice from "./Modal/CreateDevice";
  import StorageIcon from "@material-ui/icons/Storage";
  import Brightness1Icon from "@material-ui/icons/Brightness1";
  import TimelineIcon from '@material-ui/icons/Timeline';

  import PeopleIcon from '@material-ui/icons/People';
  import SettingsIcon from '@material-ui/icons/Settings';
  import DonutLargeIcon from '@material-ui/icons/DonutLarge';
  import ArrowBackIcon from '@material-ui/icons/ArrowBack';
  import ExitToAppIcon from '@material-ui/icons/ExitToApp';
  
  const ModelaRestURL = process.env.REACT_APP_MODELA_APP_REST_URL;
  
  const drawerWidth = 240;
  const useStyles = makeStyles((theme) => {
    return {
      page: {
        width: "100vw",
        minHeight: "100vh",
      },
      root: {
        display: "flex",
      },
      drawer: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
        [theme.breakpoints.up("lg")]: {
          width: theme.spacing(9),
        },
      },
      drawerPaper: {
        width: drawerWidth,
        backgroundColor: "rgba(256, 256, 256 , 0.3)",
        backdropFilter: "blur(10px)",
        color: "#fff",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerPaperClose: {
        width: "100px",
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
        "&:hover": {
          width: drawerWidth,
          boxShadow: theme.shadows[6],
          "& nav": {
            display: "block",
          },
        },
      },
      toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
      },
      delete: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1),
      },
      addBox: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1.5),
        [theme.breakpoints.down("xl")]: {
          marginLeft: theme.spacing(10),
        },
      },
      subactive: {
        color: "#fbb116",
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        margin: `${theme.spacing(0.5)}px 0`,
        [theme.breakpoints.down("xl")]: {
          paddingLeft: theme.spacing(10),
        },
      },
      subnonactive: {
        color: "#fff",
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        margin: `${theme.spacing(0.5)}px 0`,
        [theme.breakpoints.down("xl")]: {
          paddingLeft: theme.spacing(10),
        },
      },
      active: {
        color: "#fbb116",
      },
      nonActive: {
        color: "#fff",
      },
      title: {
        padding: theme.spacing(2),
      },
      icon: {
        minWidth: theme.spacing(5),
      },
      titleimg: {
        margin: "auto",
        height: "8vmin",
      },
      menuButton: {
        [theme.breakpoints.up("lg")]: {
          marginLeft: -45,
        },
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      menuButtonHidden: {
        [theme.breakpoints.up("lg")]: {
          marginLeft: 10,
        },
      },
      appBar: {
        minHeight: "64px",
        backgroundColor: "#fbb116",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      textitem: {
        marginLeft: 0,
      },
      appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
      },
      toolbar: theme.mixins.toolbar,
    };
  });
  
  export default function ({ children }) {
    const classes = useStyles();
    const history = useNavigate();
    const location = useLocation();
    const [open, setOpen] = React.useState(true);
    const [openMenuDevice, setOpenMenuDevice] = React.useState(false);
    const { logout, currentUser } = useAuth();
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const [selectDeleteDevice, setSelectDeleteDevice] = useState(0);
    // const [isModalCreateDeviceOpen, setIsModalCreateDeviceOpen] = useState(false);
    // const [timer, setTimer] = useState(0);
    const menuItems = [
      // {
      //   text: "Dashboard",
      //   icon: <DonutLargeIcon color="warning" />,
      //   path: "./dashboard",
      // },
      {
        text: "Manage User",
        icon: <PeopleIcon color="warning" />,
        path: "/admin/manageUser",
      },
      // {
      //   text: "Setting",
      //   icon: <SettingsIcon color="warning" />,
      //   path: "./setting",
      // },
    ];
  
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [dataSet, setDataSet] = useState([]);
    const { uid } = useParams();

    const getDevice = async () => {
      try {
        axios
          .post(ModelaRestURL + "/api/socket/getDevice", {
            user: uid,
          })
          .then((data) => {
            setDataSet(data.data);
          });
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(async () => {
      getDevice();
      setOpenMenuDevice(true);
    }, [uid]);
  
    const handleClickopen = () => {
      setOpenMenuDevice(!openMenuDevice);
    };
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
    const handleDrawerClose = () => {
      setOpen(!open);
    };
  
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
  
    const handleGoBack = () => {
      setAnchorElUser(null);
      history("/home");
    };
  
    const handleAdminPanelMenu = () => {
      setAnchorElUser(null);
      history("./admin");
    };
  
    const handleSettingUserMenu = () => {
      setAnchorElUser(null);
      history("./setting");
    };
  
    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!open} className={classes.toolbar}>
            <Fab
              className={clsx(
                classes.menuButton,
                !open && classes.menuButtonHidden
              )}
              size="small"
              onClick={handleDrawerClose}
              aria-label="Menu"
            >
              <MenuIcon />
            </Fab>
            <NavLink to="/home" className={classes.titleimg}>
              <img src="/images/Icon-60.png" className={classes.titleimg}></img>
            </NavLink>
            {/* <Typography>Online {countOnlineDevice} device</Typography> */}
            {
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenUserMenu}
                  color="inherit"
                >
                  <AccountCircleIcon style={{ fontSize: "35px" }} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                    <ListItem button onClick={handleGoBack}>
                        <ListItemIcon><ArrowBackIcon color="warning" /></ListItemIcon>
                        <ListItemText primary="Go Back" />
                    </ListItem>
                    <ListItem button onClick={logout}>
                        <ListItemIcon><ExitToAppIcon color="warning" /></ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </Menu>
              </div>
            }
          </Toolbar>
        </AppBar>
        {/* side drawer */}
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
          className={clsx(classes.drawer, !open && classes.drawerClose)}
        >
          <div className={classes.toolbarIcon}></div>
          <List>
            {menuItems.map((item) => (
              <ListItem
                button
                key={item.text}
                onClick={() => history(item.path)}
                className={
                  location.pathname === item.path
                    ? classes.active
                    : classes.nonActive
                }
              >
                <ListItemIcon
                  className={clsx(
                    classes.icon && location.pathname === item.path
                      ? classes.active
                      : classes.nonActive
                  )}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText className={classes.textitem} primary={item.text} />
              </ListItem>
            ))}
            
          <ListItem
            button
            onClick={handleClickopen}
            className={openMenuDevice ? classes.active : classes.nonActive}
          >
            <ListItemIcon
              className={openMenuDevice ? classes.active : classes.nonActive}
            >
              <DashboardIcon color="warning" />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <Collapse in={openMenuDevice} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {dataSet.map((item, idx) => (
                <ListItem
                  key={item.text}
                  onClick={() => history(`/admin/dashboard/${uid}/${item.key}`)}
                  button
                  className={
                    location.pathname === `/admin/dashboard/${uid}/${item.key}`
                      ? classes.subactive
                      : classes.subnonactive
                  }
                >
                  <ListItemText className={classes.textitem}>
                    {item.status == true && (
                      <>
                        <Brightness1Icon
                          style={{ color: "#00E52A", paddingRight: "0.3rem" }}
                        ></Brightness1Icon>
                      </>
                    )}
                    {item.status != true && (
                      <>
                        <Brightness1Icon
                          style={{ color: "#9D9D9D", paddingRight: "0.3rem" }}
                        ></Brightness1Icon>
                      </>
                    )}
                    {item.text}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Collapse>
          </List>
        </Drawer>
        <div className={classes.page}>
            <div className={classes.toolbar}></div>
            {children}
        </div>
      </div>
    );
  }